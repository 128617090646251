import { ModalService } from 'src/app/services/modal.service';
import { SiteAttendanceService } from 'src/app/services/site-attendance.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SiteService } from './../../services/site.service';
import { Component, OnInit } from '@angular/core';
import { SiteActiveStatus } from 'src/app/enums/site-active-status.enum';
import { SiteTemplateStatus } from 'src/app/enums/site-template-status.enum';
import { SiteViewModel } from 'src/app/models/site-viewmodel';
import { finalize } from 'rxjs/operators';
import { UserPermission } from 'src/app/enums/user-permission.enum';
import { SendPushNotificationModel } from 'src/app/models/send-push-notification-model';
import { LayoutService } from "../../services/layout.service";

@Component({
  selector: 'obc-host-push-notification',
  templateUrl: './host-push-notification.component.html',
  styleUrls: ['./host-push-notification.component.scss']
})
export class HostPushNotificationComponent implements OnInit {
  inProgress: boolean;
  isSmsMode: boolean = false;
  sites: SiteViewModel[] = [];
  UserPermission = UserPermission;
  formGroup: FormGroup = new FormGroup({
    siteId: new FormControl(null),
    subject: new FormControl(null, [Validators.required]),
    message: new FormControl(null, [Validators.required]),
  })
  constructor(private siteService: SiteService,
    private modalService: ModalService,
    private layoutService: LayoutService,
    private SiteAttendanceService: SiteAttendanceService) { }

  ngOnInit() {
    this.layoutService.header = "Send Push Notification";
    this.inProgress = true;
    this.siteService.getAllPermissionedSites(UserPermission.SendPushNotification, SiteActiveStatus.Active, SiteTemplateStatus.Normal)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(res => {
        this.sites = res;
        this.reset();
      });
  }

  onChangeMode(value) {
    this.isSmsMode = value;
  }

  private reset() {
    this.formGroup.reset();

  }

  get selectedSiteName(): string {
    if (this.formGroup.controls.siteId.value == null)
      return "All Sites";
    if (this.sites && this.sites.length)
      return this.sites.find(s => s.id == this.formGroup.controls.siteId.value).name;
  }

  onSendPushNotification() {
    this.SiteAttendanceService.sendHostPushNotification({
      subject: this.formGroup.controls.subject.value,
      message: this.formGroup.controls.message.value,
    } as SendPushNotificationModel, this.formGroup.controls.siteId.value).subscribe(res => {
      this.modalService.success(this.formGroup.controls.siteId.value ? "Push Notification Sent to site attendees successfully" : "Push Notification Sent to all site attendees successfully");
      this.reset();
    }, err => {
      console.log(err);
      this.modalService.error("Unable to Send Push Notification at This Time");
    })
  }

}
