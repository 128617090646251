import { Component, Input, OnInit } from '@angular/core';
import { GlobalItemType } from 'src/app/enums/global-item-type.enum';
import { ToolDynamicFieldUsage } from 'src/app/enums/tool-dynamic-field-usage.enum';
import { GlobalToolDynamicFieldViewModel } from 'src/app/models/inventory/global-tool-dynamic-field-view-model';

@Component({
  selector: 'obc-question-asset-field',
  templateUrl: './question-asset-field.component.html',
  styleUrls: ['./question-asset-field.component.scss']
})
export class QuestionAssetFieldComponent implements OnInit {

  static readonly globalToolDynamicFieldIdKeynewLocal = 'globalToolDynamicFieldId';
  GlobalItemType = GlobalItemType;
  selectedGlobalToolDynamicFieldId: number;
  ToolDynamicFieldUsage = ToolDynamicFieldUsage;

  questionParam: any;
  @Input() set questionParamInput(_questionParamInput: any) {
    if (!_questionParamInput) { return; }
    else {
      this.questionParam = _questionParamInput;
      this.selectedGlobalToolDynamicFieldId = this.questionParam[QuestionAssetFieldComponent.globalToolDynamicFieldIdKeynewLocal];
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

  onSelectGlobalField(field: GlobalToolDynamicFieldViewModel) {
    this.selectedGlobalToolDynamicFieldId = field?.id;
    this.questionParam[QuestionAssetFieldComponent.globalToolDynamicFieldIdKeynewLocal] = this.selectedGlobalToolDynamicFieldId;
  }
}
