import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from "@angular/forms";
import { BehaviorSubject, Observable, of } from "rxjs";
import { FormViewModel } from "../../../models/form/form-view-model";
import { FormService } from "../../../services/form.service";
import { ModalService } from "../../../services/modal.service";
import { NgSelectComponent } from "@ng-select/ng-select";
import { FormScope } from 'src/app/enums/form-scope';
import { GetFormsQueryModel } from 'src/app/models/form/get-forms-query-model';
import { FormType } from "../../../enums/form-type";
import { SupplierPermitsService } from "../../../services/supplier-permits.service";
import { FormApplyFor } from "../../../enums/form-apply-for";
import { FormLoadMode } from 'src/app/enums/form-load-mode.enum';

@Component({
  selector: 'obc-form-selector',
  templateUrl: './form-selector.component.html',
})
export class FormSelectorComponent implements OnInit {
  @Input() queryScope: FormScope;
  @Input() formType: FormType;
  @Input() selectFirstItemOfOneItemArray: boolean;
  @Input() hashLink: string;
  @Input() siteSupplierId: number;
  @Input() applyForList: FormApplyFor[];
  @Input() excludeFormIds: number[];
  @Input() placeholder: string;
  @Input() mode: FormLoadMode = FormLoadMode.Normal;
  @Input() companyId: number;
  @Input() documentKindId: number;
  @Input() workflowForms: FormViewModel[];

  _required = false;
  _formId: number = null;
  @Input() set formId(value: number,) {
    if (value === undefined || value === null)
      return;
    this._formId = value;
  };
  @Input() set required(value: boolean) {
    if (value) {
      this._required = true;
      this.formControl?.setValidators(Validators.required);
    }
  };

  @ViewChild('selector') selector: NgSelectComponent;

  constructor(private formService: FormService,
    private supplierPermitService: SupplierPermitsService,
    private modalService: ModalService,) {
  }


  forms$: Observable<FormViewModel[]>;
  term$ = new BehaviorSubject<string>(null)
  formControl = new FormControl(null);
  @Output() selectedFormId = new EventEmitter<number>();
  @Output() selectedForm = new EventEmitter<FormViewModel>();
  forms: FormViewModel[] = [];
  inProgress = false;

  ngOnInit() {
    this.getForms();

    this.formControl.valueChanges.subscribe(res => {
      if (this.forms) {
        if (res == null || res == 0) {
          this.selectedFormId.emit(null);
          this.selectedForm.emit(null);
        } else {
          let form = this.forms.find(x => x.id == res);
          if (form == null) {
          } else {
            this.selectedForm.emit(form);
            this.selectedFormId.emit(form.id);
          }
        }
      }
    });
  }

  private getForms() {
    if (this.workflowForms) {
      this.forms = this.workflowForms;
      this.setFormControls(this.forms);
    }
    else {
      this.inProgress = true;
      const model = {
        formScope: this.queryScope,
        formType: this.formType,
        formApplyFor: this.applyForList,
        documentKindIdList: this.documentKindId ? [this.documentKindId] : null,
      } as GetFormsQueryModel;

      (
        this.mode == FormLoadMode.ExternalCompany ?
          this.formService.getExternalCompanyForms({ ...{ companyId: this.companyId }, ...model }) :
          (this.formType == FormType.Permit && ((this.hashLink != null && this.hashLink.length > 0) || this.siteSupplierId != null) ?
            this.supplierPermitService.getPermitForms(this.hashLink, this.siteSupplierId) :
            this.formService.getForms(model)))
        .subscribe(
          res => {
            if (res.success) {
              this.setFormControls(res?.data);
            } else {
              this.modalService.error(res.message);
            }
          }, _ => {
            this.modalService.error("Request Failed");
          }, () => {
            this.inProgress = false;
          }
        )
    }
  }

  setFormControls(formList: FormViewModel[]) {
    this.forms = this.filterForms(formList)?.map(form => {
      form.name = form.buttonTitle && form.buttonTitle.length > 0 ? form.buttonTitle : form.name;
      return form;
    });

    this.forms$ = of(this.filterForms(formList));
    if (this._formId !== undefined && this._formId !== null)
      this.formControl.setValue(this._formId);
    else if (this.selectFirstItemOfOneItemArray == true && this.forms?.length == 1) {
      this.formControl.setValue(this.forms[0].id);
    }
  }

  reachToEnd = false;
  reachToEndTerm = "";

  onScrollToEnd() {
    this.reachToEnd = true;
    this.reachToEndTerm = this.term$.value;
    this.term$.next(this.term$.value)
  }

  public clearInput() {
    this.selector.handleClearClick();
  }

  filterForms(formList: FormViewModel[]) {
    return formList?.filter((form) => {
      if (!this.excludeFormIds?.length || this.excludeFormIds.indexOf(form.id) == -1) {
        return form;
      }
    }).sort((a, b) => a.name.localeCompare(b.name));
  }
}
