import { UserInfoModalComponent } from './../user-info-modal/user-info-modal.component';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserEditMobileModel } from 'src/app/models/user-edit-mobile-model';
import { ModalService } from 'src/app/services/modal.service';
import { UserService } from 'src/app/services/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserEditMobileViewModel } from 'src/app/models/user-edit-mobile-viewmodel';

@Component({
  selector: 'obc-user-edit-phone',
  templateUrl: './user-edit-phone.component.html',
  styleUrls: ['./user-edit-phone.component.scss']
})
export class UserEditPhoneComponent implements OnInit {
  modalRef: BsModalRef;
  mobileInProccess: boolean = false;
  mobileFormGroup: FormGroup;
  selectedUser: UserEditMobileViewModel;

  constructor(private userService: UserService, private modalService: ModalService, private bsModalService: BsModalService) { }

  ngOnInit() {
    this.mobileFormGroup = new FormGroup({
      currentMobile: new FormControl('', [Validators.required]),
      newMobile: new FormControl('', [Validators.required])
    })
  }

  validateEditMobile() {
    var model = {
      currentMobileNumber: this.mobileFormGroup.get("currentMobile").value,
      newMobileNumber: this.mobileFormGroup.get("newMobile").value,
    } as UserEditMobileModel;

    this.mobileInProccess = true;
    this.userService.validateEditUserMobile(model).subscribe(res => {
      this.selectedUser = res;

      this.modalRef = this.bsModalService.show(UserInfoModalComponent, {
        class: "modal-lg",
        initialState: {
          selectedUser: this.selectedUser,
          mobileModel: model
        }
      });
      this.modalRef.content.onClose.subscribe(result => {
        if (result == true) {
          this.modalService.info('Mobile changed successfully');
          this.mobileFormGroup.get("currentMobile").setValue('');
          this.mobileFormGroup.get("newMobile").setValue('');
        }
        else
          this.modalService.error('Error in change mobile number', 'error');
      })


      this.mobileInProccess = false;


    }, err => {
      this.modalService.error(err.error, "Change Mobile Error").subscribe(res => {
        this.mobileInProccess = false;
      });
    });
  }
}
