import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { ApproveEntityType } from 'src/app/enums/approve-entity-type';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';
import { ApproveRejectQueryModel } from 'src/app/models/approve/approve-reject-query-model';
import { ApproveService } from 'src/app/services/approve-service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'obc-approve-reject',
  templateUrl: './approve-reject.component.html',
  styleUrls: ['./approve-reject.component.scss']
})
export class ApproveRejectComponent implements OnInit {
  @Output() onRefresh = new EventEmitter();
  @Input() entityType: ApproveEntityType;
  @Input() entityId: number;
  @Input() showEndDate: boolean;
  @Input('endDate') inputEndDate: Date;
  inProgress: boolean;
  description: string;
  endDate: Date;
  datetimePickerMode = DatetimePickerMode;

  constructor(private approveService: ApproveService,
    private modalService: ModalService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    if (this.inputEndDate != null)
      this.endDate = this.inputEndDate;
  }
  approveReject(isApprove: boolean) {
    this.inProgress = true;
    var model = {
      approveEntityType: this.entityType,
      entityId: this.entityId,
      isApprove: isApprove,
      description: this.description,
      newEndDate: this.showEndDate == true ? this.endDate : null,
    } as ApproveRejectQueryModel;
    this.approveService.approveRejectEntity(model)
      .pipe(finalize(() => {
        this.inProgress = false
      }))
      .subscribe(res => {
        if (res == true) {
          if (isApprove == true)
            this.toastr.success('Approved Successfully.');
          else
            this.toastr.success('Rejected Successfully.');
          this.onRefresh.emit(res);
        } else
          this.modalService.error(`${isApprove == true ? 'Approve' : 'Reject'} Failed.`);
      }, err => {
        this.modalService.error(err)
      })
  }

  closeModal() {
    this.modalService.hide();
  }
}
