<!-- <section class="card">
    <header class="card-header">
        <h2 class="card-title">Send Site Alert</h2>
    </header>-->
<div class="card-body1">
    <form name="messageForm" (ngSubmit)="messageForm.form.valid && sendMessage()" #messageForm="ngForm">
        <div class="form-row">
            <div class="form-group col-12">
                <label for="selectedSite">Select a site</label>
                <app-site-selector [permission]="UserPermission.SendEmergencyAlarm" (siteSelect)="changeSite($event)">
                </app-site-selector>
            </div>
        </div>
        <div class="alert alert-success" *ngIf="totalCount || totalCount == 0 && selectedSite != null">
            <strong class="text-primary">{{totalCount}}</strong> people are checked in {{selectedSite?.name}} now.
        </div>
        <div class="form-group pt-2" *ngIf="totalCount || selectedSite == null">
            <label for="inputAddress">Message</label>
            <input type="text" class="form-control" id="message" name="message" [(ngModel)]="message"
                placeholder="Enter your message to broadcast" required>
            <span class="text-primary small">{{resultMessage}}</span>
        </div>
    </form>
</div>
<footer class="card-footer1">
    <div class="row">
        <div class="col-md-12 text-right">
            <button class="btn btn-primary modal-confirm" (click)="sendMessage()"
                *ngIf="totalCount || (selectedSite == null && message)">Send Message</button>
            <button class="btn btn-default modal-dismiss" (click)="closeModal()">Cancel</button>
        </div>
    </div>
</footer>
<!-- </section> -->