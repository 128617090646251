<div class="row">
  <div class="col-md-12">
    <div class="card">
      <obc-loader [show]="inProgress"></obc-loader>
      <header class="card-header">Site Briefing Report</header>
      <div class="card-body">
        <app-base-grid [columns]="gridColumns" [configs]="gridConfig" #grid>

          <ng-template obcGridTemplateName="status" let-item="data">
            {{item.status | SiteBriefingStatusPipe}}
          </ng-template>

          <ng-template obcGridTemplateName="actions" let-viewDetails let-item="data">
            <div *ngIf="item.status != SiteBriefingStatus.Draft">
              <button class="btn btn-success" (click)="openModal(formDetails,item.id)">
                <i class="fa fa-eye"></i>
                View Details
              </button>

              <div class="btn-group m-1" dropdown *ngIf="item.status != SiteBriefingStatus.Draft">
                <button id="button-basic" dropdownToggle type="button" class="btn btn-info dropdown-toggle"
                  aria-controls="dropdown-basic">
                  More Actions
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                  aria-labelledby="button-basic">
                  <li role="menuitem">
                    <button id="button-basic" type="button"
                      class="dropdown-item cursor-pointer d-flex align-items-center" (click)="exportPdf(item)">
                      <i class="fa fa-file-pdf mr-2"></i> Download PDF
                    </button>
                  </li>
                  <li role="menuitem" *ngIf="item.status == SiteBriefingStatus.Active">
                    <button class="dropdown-item cursor-pointer d-flex align-items-center"
                      (click)="deactiveClicked(item.id)">
                      <i class="fa fa-ban mr-2"></i> Deactivated Document
                    </button>
                  </li>
                </ul>
              </div>

            </div>
          </ng-template>

          <ng-template #formDetails>
            <header class="card-header">
              <h5>View Form</h5>
              <div class="card-actions">
                <a (click)="closeModal(null);" class="card-action card-action-dismiss" data-card-dismiss=""></a>
              </div>
            </header>
            <div class="card-body">
              <tabset>
                <tab heading="Details">
                  <obc-form-details [formDataId]="selectedFormDataId" (onRefesh)="closeModal(null);"></obc-form-details>
                </tab>
                <tab heading="Signatures">
                  <app-base-grid [columns]="signaturesGridColumns" [configs]="signaturesGridConfig" #gridSignature>

                    <ng-template obcGridTemplateName="sigTemplate" let-item="data">
                      <obc-file-viewer *ngIf="item.signatureUrl" [files]="[item]" [btnTitle]="'View Signature'"
                        [btnClass]="'m-1 btn-sm btn btn-success'" [imgClass]="'signature-bg'"
                        [prefix]="item.fullName + '-' + item.mobile">
                      </obc-file-viewer>
                    </ng-template>

                    <ng-template obcGridTemplateName="signedBy" let-item="data">
                      {{item.signedBy?.fullName}}
                    </ng-template>
                  </app-base-grid>
                </tab>
              </tabset>
            </div>
          </ng-template>

        </app-base-grid>
      </div>
    </div>
  </div>
</div>
