<div class="row">
  <div class="col-md-12">
    <div class="card">
      <obc-loader [show]="inProgress"></obc-loader>
      <header class="card-header d-flex justify-content-between">
        <h5>Approvals</h5>
      </header>
      <obc-descriptor [text]="'You can approve or reject items.'" [closePanelName]="'approvals'">
      </obc-descriptor>
      <div class="card-body">
        <form class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <span class="d-inline-block mt-2 mr-1">
                Include Inactive Items:
              </span>
              <obc-switch [trueLabel]="' Yes '" [falseLabel]="' No '" [value]="includeInactive"
                (valueChanged)="onActiveChanged($event)">
              </obc-switch>
            </div>
          </div>
        </form>
        <app-base-grid [columns]="gridColumns" [configs]="gridConfig" #grid>
          <ng-template obcGridTemplateName="pendingStatus" let-item="data">
            <span *ngIf="isShowTemplateColumns('status','status')">
              <i *ngIf="item?.status == PendingToApproveStatus.Rejected" tooltip="Rejected"
                class="fa fa-times text-danger"></i>
              <i *ngIf="item?.status == PendingToApproveStatus.Approved" tooltip="Approved"
                class="fa fa-check text-success"></i>
              <i *ngIf="item?.status == PendingToApproveStatus.Pending" tooltip="Pending Approval"
                class="fa fa-history text-info"></i>
              <span [ngSwitch]="item?.status">
                <span *ngSwitchCase="PendingToApproveStatus.Approved">
                  <span>
                    Approved
                  </span>
                </span>
                <span *ngSwitchCase="PendingToApproveStatus.Pending">
                  <span>
                    Pending Approval
                  </span>
                </span>
                <span *ngSwitchCase="PendingToApproveStatus.Rejected">
                  <span>
                    Rejected </span>
                </span>
              </span>
            </span>
          </ng-template>

          <ng-template obcGridTemplateName="entityType" let-item="data">
            <span *ngIf="isShowTemplateColumns('approveEntityType','approveEntityType')">
              <i [ngClass]="item?.approveEntityType == ApproveEntityType.SiteSupplierDocument ? 'fas fa-industry text-success' :
                            item?.approveEntityType == ApproveEntityType.Attendance ? 'fas fa-arrow-right text-warning':
                            item?.approveEntityType == ApproveEntityType.Induction ?'fas fa-id-badge text-info':
                            item?.approveEntityType == ApproveEntityType.SiteAsset ?'fas fa-tools text-info':
                            'fas fa-file-alt text-info'">
              </i>
              {{ item?.approveEntityType | approveEntityType }}
            </span>
            <span class="text-muted" *ngIf="item?.approveEntityType == ApproveEntityType.SiteSupplierDocument">
              ({{ item?.documentKindName }})
            </span>
          </ng-template>

          <ng-template obcGridTemplateName="title" let-item="data">
            <span *ngIf="isShowTemplateColumns('title','title')">
              {{ item?.title }}
            </span>
          </ng-template>

          <ng-template obcGridTemplateName="approvalDateTime" let-item="data">
            <span
              *ngIf="isShowTemplateColumns('approvalDateTime','approvalStatusUserFullName') && item?.approvalStatusUserFullName"
              style="display:block;">
              {{item?.approvalStatusUserFullName}}
            </span>
            <span *ngIf="isShowTemplateColumns('approvalDateTime','approvalDateTime') && item?.approvalDateTime"
              style="display:block ;">
              ({{item?.approvalDateTime | datetime}})
            </span>
          </ng-template>

          <ng-template obcGridTemplateName="dateTime" let-item="data">
            <span>
              <span *ngIf="getStartDate(item).hasDate">
                {{getStartDate(item).showValue}}
              </span>
              <span *ngIf="getStartDate(item).hasDate && getEndDate(item).hasDate"> - </span>
              <span *ngIf="getEndDate(item).hasDate">
                {{getEndDate(item).showValue}}
              </span>
            </span>
          </ng-template>

          <ng-template obcGridTemplateName="description" let-item="data">
            <span *ngIf="isShowTemplateColumns('description','description') &&  item?.description">
              {{item?.description}}
              <br />
            </span>
          </ng-template>

          <ng-template obcGridTemplateName="actions" let-item="data">
            <div class="d-flex align-items-start">
              <ng-container *ngIf="item?.status == PendingToApproveStatus.Pending">
                <i *ngIf="!item.workflowInstanceId && item?.approveEntityType == ApproveEntityType.SiteSupplierDocument && !serverResult?.hasSupplierReviewForm && serverResult?.supplierDocumentReviewMode == SupplierDocumentReviewModeEnum.Mandatory"
                  tooltip="There are no supplier document review forms." class="fas fa-exclamation-triangle mr-3"></i>
                <div *ngIf="isSimpleApproveReject(item)">
                  <button class="btn btn-success btn-sm" (click)="approveReject(item, true)">Approve
                  </button>
                  <button class="btn btn-danger btn-sm ml-2" (click)="approveReject(item, false)">Reject
                  </button>
                </div>
                <!--*ngIf="item?.approveEntityType == ApproveEntityType.SiteSupplierDocument && item.inWorkflowStep == ShowSupplierDocumentReviewButton.WorkflowInstanceHasAccess" -->
                <a *ngIf="hasWorkflow(item)" (click)="openWorkflowEngine(item?.workflowInstanceId, workflowEngine)"
                  class="btn btn-info btn-sm text-white">
                  Review
                </a>
                <ng-container *ngIf="isLagacyReview(item)">
                  <button class="btn btn-info btn-sm" (click)="onOpenFormReview(formReview, item)">Review
                  </button>
                </ng-container>
                <!-- Induction Approve/Reject with comment -->
                <button class="btn btn-info btn-sm" *ngIf="item?.approveEntityType == ApproveEntityType.Induction"
                  (click)="onOpenApproveRejectTemplate(approveRejectTemplate, item)">Review
                </button>
              </ng-container>
              <button class="btn btn-sm btn-{{ item?.status == PendingToApproveStatus.Pending ? 'info' : 'warning' }}"
                *ngIf="item?.approveEntityType == ApproveEntityType.PermitForms || item?.approveEntityType == ApproveEntityType.SupplierForms"
                (click)="onOpenFormDetail(formDetails, item)">
                {{ item?.status == PendingToApproveStatus.Pending ? 'Review' : 'View Details' }}
              </button>

              <button class="btn btn-sm btn-{{ item?.status == PendingToApproveStatus.Pending ? 'info' : 'warning' }}"
                *ngIf="item?.approveEntityType == ApproveEntityType.SiteAsset || (item?.approveEntityType == ApproveEntityType.SiteAssetOperator && item?.relatedFormDataId)"
                (click)="onOpenFormDetail(formDetails, item)">
                {{ item?.status == PendingToApproveStatus.Pending ? 'Review' : 'View Details' }}
              </button>

              <button *ngIf="item?.approveEntityType == ApproveEntityType.SiteSupplierDocument && item?.status == PendingToApproveStatus.Approved &&
                        item?.relatedFormDataId" class="btn btn-warning btn-sm"
                (click)="onOpenFormDataDetail(formDataDetails, item?.relatedFormDataId)">View Details
              </button>

              <button class="btn btn-warning btn-sm ml-2"
                *ngIf="item?.approveEntityType == ApproveEntityType.Attendance && userService.hasUserPermissionForCompany(userPermissions.ViewAttendanceAnswers,item?.siteId)"
                (click)="onOpenDetail(questionAnswer, item)">View Details
              </button>
              <button class="btn btn-warning btn-sm ml-2" *ngIf="item?.approveEntityType == ApproveEntityType.Induction"
                (click)="onOpenFormDetail(inductionInfo, item)">View Details
              </button>
              <obc-supplier-document-description-viewer
                *ngIf="item?.approveEntityType == ApproveEntityType.SiteSupplierDocument" [documentTitle]="item?.title"
                [description]="item?.description" [classes]="'btn btn-tertiary btn-sm ml-2'">
              </obc-supplier-document-description-viewer>
              <obc-site-supplier-document-version-comments
                *ngIf="item?.hasPermissionToAddSupplierDocumentComment || item?.supplierDocumentVersionComments?.length > 0"
                [siteSupplierDocumentVersionId]="item?.entityId"
                [hasPermissionToAddSupplierDocumentComment]="item?.hasPermissionToAddSupplierDocumentComment"
                [supplierDocCommentAcknowledgementText]="item?.supplierDocCommentAcknowledgementText"
                [comments]="item?.supplierDocumentVersionComments" [classes]="'ml-2 btn-sm'" [noIcon]="true">
              </obc-site-supplier-document-version-comments>
              <obc-file-viewer
                *ngIf="item?.approveEntityType == ApproveEntityType.SiteSupplierDocument && item?.files?.length"
                [prefix]="item?.title+(item?.description ? '-'+item?.description : '')" [files]="item?.files || []"
                [btnTitle]="'View Files'" [btnClass]="'btn btn-primary btn-sm ml-2 pull-right'"></obc-file-viewer>

              <ng-container *ngIf="showResetStatusButton(item)">
                <button class="btn btn-danger btn-sm ml-2" (click)="onResetStatus(item)"><i tooltip="Reset Status"
                    class="fa fa-history"></i> Reset Status
                </button>
              </ng-container>
            </div>
          </ng-template>
        </app-base-grid>
      </div>
    </div>
  </div>
</div>

<ng-template #workflowEngine>
  <obc-workflow-engine [workflowInstanceId]="selectedInstanceId" (workflowEningeModalHide)="closeModal()">
  </obc-workflow-engine>
</ng-template>

<ng-template #formReview>
  <obc-supplier-document-review [siteId]="selectedSite?.id"
    [selectedSiteSupplierDocument]="selectedSiteSupplierDocument"
    [supplierDocumentReviewMode]="serverResult?.supplierDocumentReviewMode" (resultOfApproveReject)="fetchPendingList()"
    (hide)="closeModal()">
  </obc-supplier-document-review>
</ng-template>

<ng-template #questionAnswer>
  <obc-question-answer [questionAnswerInput]="questionAnswerInputModel" (resultOfApproveReject)="fetchPendingList()"
    (result)="closeModal()">
  </obc-question-answer>
</ng-template>

<ng-template #formDetails>
  <obc-form-details [entityId]="selectedEntityId" [formDataId]="selectedFormDataId"
    (onRefesh)="closeModal(); fetchPendingList()"></obc-form-details>
</ng-template>

<ng-template #formDataDetails>
  <obc-form-data-details [formDataId]="selectedEntityId"></obc-form-data-details>

  <!--  <obc-form-details [entityId]="selectedEntityId" [formDataId]="selectedFormDataId"
                    (onRefesh)="closeModal(); fetchPendingList()"></obc-form-details>-->
</ng-template>


<ng-template #approveRejectTemplate>
  <obc-approve-reject [entityId]="selectedEntityId" [endDate]="selectedEntityEndDate"
    [entityType]="selectedEntityTypeForReviewModal" [showEndDate]="selectedItemIsEnableFormExpiryDate"
    (onRefresh)="closeModal(); fetchPendingList();">
  </obc-approve-reject>
</ng-template>

<ng-template #inductionInfo>
  <obc-site-induction-detail [siteInductionId]="selectedFormDataId" (result)="closeModal()">
  </obc-site-induction-detail>
</ng-template>