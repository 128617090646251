<div class="row" [formGroup]="formGroup">
  <obc-loader [show]="inProgress"></obc-loader>
  <button type="button" class="btn btn-sm btn-info additional-info" *ngIf="showAssetAdditionalInfo"
    (click)="onViewAssetAdditionalInfo(additionalInfo)">
    View Additional Info
  </button>
  <div class="col-md-6 mt-3" *ngIf="!isTempAsset">
    <div class="form-group">
      <label>Category *</label>
      <select class="custom-select form-control" formControlName="categoryId">
        <option disabled value="{{ null }}" [ngValue]="null">Please select a category</option>
        <option *ngFor="let category of (categories | async)" [ngValue]="category.id">{{category.title}}</option>
      </select>
    </div>
  </div>
  <div class="col-md-6 mt-3">
    <div class="form-group">
      <label>Asset Name *</label>
      <input class="form-control" formControlName="name">
    </div>
  </div>
  <div class="col-md-6 mt-3" *ngIf="!editMode && !isTempAsset">
    <div class="form-group">
      <label>Status *</label>
      <select class="custom-select form-control" formControlName="statusId">
        <option disabled value="{{ null }}" [ngValue]="null">Please select a status</option>
        <option *ngFor="let status of (statuses | async)" [ngValue]="status.id">{{status.title}}</option>
      </select>
    </div>
  </div>
  <div class="col-md-6 mt-3" *ngIf="!isTempAsset" [style]="showRegionSelectorComponent ? '' : 'display:none'">
    <div class="form-group">
      <label>Region</label>
      <obc-region-selector [regionId]="_asset?.regionId" (onRegionLoaded)="onRegionLoaded($event)"
        (regionIdSelect)="onRegionSelect($event)"></obc-region-selector>
    </div>
  </div>
  <div class="col-md-12 mt-3" *ngIf="!isTempAsset">
    <div *ngIf="getCurrentCategory()?.toolDynamicFields?.length" class="d-flex flex-row">
      <div>
        <label>Field(s)</label>
      </div>
      <div class="flex-fill">
        <hr>
      </div>
    </div>
    <div class="ml-2">
      <obc-tool-field-renderer #toolFieldRenderer [values]="_asset?.dynamicFieldValues"
        [fields]="getCurrentCategory()?.toolDynamicFields">
      </obc-tool-field-renderer>
    </div>
    <div *ngIf="getCurrentCategory()?.toolDynamicFields" class="d-flex flex-row">
      <div class="flex-fill">
        <hr>
      </div>
    </div>
  </div>

  <div class="col-md-12 mt-3">
    <div class="form-group">
      <label>Main Image <span
          [hidden]="(isTempAsset && !assetConfiguration.value?.isRequiredShortTermAssetImage) || (!isTempAsset && !assetConfiguration.value?.isRequiredAssetImage) ">*</span></label>
      <div class="row mb-2" *ngIf="imageSource || _asset?.mainImageUrl">
        <div class="col-md-12">
          <div class="d-flex flex-row justify-content-center">
            <img class="image" [attr.src]="imageSource ? imageSource : _asset.mainImageUrl">
          </div>
        </div>
      </div>
      <obc-file-upload [showClearButton]="true" [accept]="FileUploadAcceptType.Image"
        (clear)="formGroup.controls.image.setValue(null); updateMainImageSource()" formControlName="image">
      </obc-file-upload>
    </div>
  </div>
  <ng-container *ngIf="!editMode && !isTempAsset">
    <div class="col-md-12 mt-3">
      <div class="form-group d-flex flex-row">
        <label class="mr-3">Assign To</label>
        <div class="form-check mr-3">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="assignType" [value]="AssignToolType.User">
            User
          </label>
        </div>
        <div class="form-check mr-3">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="assignType" [value]="AssignToolType.Location">
            {{parentSelectedSite && parentSelectedSite.id > 0 ? userService.currentCompany.title :''}} locations
          </label>
        </div>
        <div class="form-check mr-3" *ngIf="parentSelectedSite && parentSelectedSite.id > 0">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="assignType"
              [value]="AssignToolType.SelectedSiteLocation">
            {{parentSelectedSite.name}} locations
          </label>
        </div>
      </div>
    </div>
    <div class="col-md-12 mt-3 mb-2">
      <ng-container *ngIf="formGroup.controls.assignType.value == AssignToolType.User">
        <app-user-selector (userChange)="onUserChange($event)" [filterByCurrentUserRegion]="true"></app-user-selector>
      </ng-container>
      <ng-container *ngIf="formGroup.controls.assignType.value == AssignToolType.Location">
        <obc-inventory-location-selector (onSelectLocation)="onLocationChange($event)"
          (onAddNewLocation)="onAddNewLocation($event)"></obc-inventory-location-selector>
      </ng-container>
      <ng-container *ngIf="formGroup.controls.assignType.value == AssignToolType.SelectedSiteLocation">
        <obc-inventory-location-selector [parentSelectedSite]="parentSelectedSite"
          [externalSiteSupplierId]="externalSiteSupplierId" (onSelectLocation)="onLocationChange($event)"
          (onAddNewLocation)="onAddNewLocation($event)">
        </obc-inventory-location-selector>
      </ng-container>
    </div>
  </ng-container>
  <div class="col-md-12 text-right mt-3" *ngIf="showSubmitButton">
    <button *ngIf="!editMode" [disabled]="!isAddFormValid()" (click)="onAddAsset()" class="btn btn-primary">
      Submit Changes
    </button>
    <button *ngIf="editMode" (click)="onEditAsset()" class="btn btn-primary">
      Edit Asset
    </button>
  </div>
</div>

<ng-template #additionalInfo>
  <div class="card">
    <div class="card-header">
      <h5 class="mb-0">Additional Info</h5>
    </div>
    <div class="card-body">
      <div>
        <div class="row" *ngFor="let key of _asset.additionalData">
          <div class="col-md-12">
            {{key.fieldName}}: <strong>{{key.value}}</strong>
          </div>

        </div>
      </div>
    </div>
  </div>
</ng-template>