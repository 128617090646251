import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LayoutService } from 'src/app/services/layout.service';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { Table } from 'src/app/enums/table.enum';
import { ModalService } from 'src/app/services/modal.service';
import { AsyncResult } from 'src/app/models/helper-models';
import { UserService } from 'src/app/services/user.service';
import { SiteInductionFullInfoViewModel } from 'src/app/models/induction/site-induction-full-info-view-model';
import { InductionService } from 'src/app/services/induction-service';
import { SiteInductionStatus } from 'src/app/enums/site-induction-status';
import { UserPermission } from 'src/app/enums/user-permission.enum';
import { ResetEntityStatusQueryModel } from 'src/app/models/approve/reset-entity-status-query-model';
import { ApproveEntityType } from 'src/app/enums/approve-entity-type';
import { QuestionAnswerInputModel } from "../../../models/question-answer-inputmodel";
import { GridColumn, GridConfig, GridTemplate, InitialFilter, InitialFilterUsage } from "../../../models/grid/grid.config";
import { AttendanceDocumentReportViewModel } from "../../../models/attendance-document-report-viewmodel";

@Component({
  selector: 'obc-induction-report',
  templateUrl: './induction-report.component.html',
  styleUrls: ['./induction-report.component.scss'],
})
export class InductionReportComponent implements OnInit {
  @ViewChild('grid') grid: any;

  inProgress: boolean = false;
  searchQueryForm: FormGroup = null;
  questionAnswerInputModel: QuestionAnswerInputModel = new QuestionAnswerInputModel();
  initialFilters: InitialFilter[] = [
    {
      key: 'status', value: [
        { label: 'Approved', key: SiteInductionStatus.ApprovedByHost },
        { label: 'Auto Approved', key: SiteInductionStatus.AutoApproved },
        { label: 'Pending Approval', key: SiteInductionStatus.PendingToApprove },
      ],
      displayInFilterRenderer: true,
    },
  ];
  UserPermission = UserPermission;
  SiteInductionStatus = SiteInductionStatus;

  constructor(
    private layoutService: LayoutService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    public userService: UserService,
    private inductionService: InductionService,
  ) {
  }

  ngOnInit() {
    this.layoutService.header = 'Induction Report';

    this.searchQueryForm = this.formBuilder.group({
      includeExpiredInductions: new FormControl(false),
    });


    this.searchQueryForm?.controls.includeExpiredInductions?.valueChanges?.subscribe((res) => {
      let filter = this.initialFilters?.find((item) => item.key == 'includeExpiredInductions');
      filter.value = !!res;

      this.initialFilters = [
        ...this.initialFilters?.filter((item) => item.key != 'includeExpiredInductions'),
        filter,
      ];
    })

    this.prepareGridConfig();
  }

  gridColumns: GridColumn<AttendanceDocumentReportViewModel>[] = [];
  gridConfig: GridConfig = {} as GridConfig;

  prepareGridConfig() {
    let currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 1);

    this.initialFilters.push({
      key: 'includeExpiredInductions',
      value: false,
      displayInFilterRenderer: false,
    }, {
      key: 'includeQuestionAnswers',
      value: true,
      displayInFilterRenderer: false,
      usage: InitialFilterUsage.CSV,
    });
    this.gridConfig = new GridConfig({
      apiUrl: '/api/Induction/site-induction-report',
      tableType: Table.InductionReport,
      displayQuestionsFilter: true,
      displayGeneralSearch: true,
      generalSearchPlaceholder: "Filter result by Induction Title, Induction Number, User, Supplier and Site fields",
      generalSearchMinimumCharsToSearch: 1,
      initialFilters: this.initialFilters,
      csvReportUrl: '/api/Induction/site-induction-report-csv-as-job',
      apiResultCallback: (res) => {
        return {
          data: res?.data?.data,
          totalCount: res?.data?.totalCount,
        }
      }
    });
    this.gridColumns = [
      {
        name: "Induction Title",
        key: "inductionTitle",
        type: FilterType.InductionId,
        gridTemplate: new GridTemplate().ComponentTemplateName('inductionTitle')
          .CsvFields([
            'inductionTitle',
            'isExpired',
          ]),
        propertyNameInQuery: 'inductionIds',
      },
      {
        name: "Induction Number",
        key: "inductionKey",
        enableFilter: false,
        enableSort: false,
      },
      {
        name: "Full Name",
        key: "user",
        type: FilterType.User,
        propertyNameInQuery: 'userIds',
        gridTemplate: new GridTemplate().FullNameMobile({
          fullName: 'user',
          mobile: 'userMobile'
        }).CsvFields([
          'user',
          'userMobile',
        ]),
      },
      {
        name: "Supplier",
        key: "supplierName",
        sortBy: "supplier",
        type: FilterType.Supplier,
        propertyNameInQuery: 'suppliers',
      },
      {
        name: "Date",
        key: "checkinDate",
        type: FilterType.DateTimeRange,
        propertyNameInQuery: 'checkinDateRange',
        isArray: false,
      },
      {
        name: "Expiration Date",
        key: "expiredDate",
        type: FilterType.DateTimeRange,
        propertyNameInQuery: 'expiredDateRange',
        isArray: false,
        visible: false,
      },
      {
        name: "Status",
        key: "status",
        sortBy: "currentStatus",
        type: FilterType.Induction,
        gridTemplate: new GridTemplate().ComponentTemplateName('currentStatus'),
        propertyNameInQuery: 'currentStatusIds',
      },
      {
        name: "Site",
        key: "siteName",
        sortBy: "site",
        type: FilterType.Site,
        propertyNameInQuery: 'siteIds',
        gridTemplate: new GridTemplate().SiteNameReference({
          siteName: 'site',
          siteReference: 'siteReference',
        }).CsvFields([
          'site',
          'siteReference',
        ]),
      },
      {
        name: "Details",
        key: 'viewDetails',
        enableSort: false,
        enableFilter: false,
        gridTemplate: new GridTemplate().ComponentTemplateName('viewDetails'),
        visible: true,
      }
    ];
  }

  selectedSiteInductionId: number;

  openModal(template, siteInductionId: number) {
    this.selectedSiteInductionId = siteInductionId;
    this.modalService.show(template, 'modal-lg');
  }

  closeModal(_: AsyncResult) {
    this.modalService.hide();
  }

  hasResetPermission(siteId: number): boolean {
    return this.userService.hasAdminPermissionOnSite(siteId);
  }

  resetInductionStatus(induction: SiteInductionFullInfoViewModel) {
    this.modalService
      .confirm(
        'Are you sure you want to reset this induction to pending status?'
      )
      .subscribe((confirm) => {
        if (confirm) {
          this.inProgress = true;
          this.inductionService
            .resetInductionStatus({
              entityId: induction.siteInductionId,
              approveEntityType: ApproveEntityType.Induction
            } as ResetEntityStatusQueryModel)
            .subscribe({
              next: (res) => {
                this.inProgress = false;
                if (res) this.grid.loadData(0);
              },
              error: (err) => {
                this.inProgress = false;
                this.modalService.error(err?.error ?? 'Operation failed!');
              }
            }
            );
        }
      });
  }

  isShowTemplateColumns(mainColumn, detailColumn) {
    return this.grid.isShowTemplateColumns(mainColumn, detailColumn);
  }
}
