<div class="mfp-bg mfp-ready visible" *ngIf="visible"></div>
<div tabindex="-1" class="mfp-wrap mfp-auto-cursor mfp-ready" *ngIf="visible"
  style="overflow: auto; overflow-x: hidden; overflow-y: auto;">
  <div class="mfp-container mfp-inline-holder">
    <div class="mfp-content">
      <div class="modal-block modal-header-color" [ngClass]="mode">
        <section class="card">
          <header class="card-header" [ngClass]="isDangerState?'bg-danger':''">
            <div>
              <a (click)="cancel()" style="color:white; float:right" class="card-action card-action-dismiss"></a>
            </div>
            <h2 class="card-title">{{header}}</h2>
          </header>
          <div class="card-body pb-0">
            <div class="d-flex mb-2">
              <!-- modal-wrapper -->
              <div class="modal-icon mr-4 pt-1">
                <i class="fas {{isDangerState?'text-danger':''}}" [ngClass]="icon"></i>
              </div>
              <div class="d-flex flex-column justify-content-center modal-text">
                <h4>{{message}}</h4>
              </div>
            </div>
          </div>
          <footer class="card-footer">
            <div class="row">
              <div class="col-md-12 text-right">
                <button id="ok" style="min-width: 80px;" class="btn modal-confirm" [ngClass]="trueButtonClass"
                  (click)="ok()">{{trueLabel}}</button>
                <button style="min-width: 80px;" *ngIf="isConfirm" id="cancel"
                  class="btn modal-dismiss cancel m-l-10 ml-1" [ngClass]="falseButtonClass"
                  (click)="cancel()">{{falseLabel}}</button>
              </div>
            </div>
          </footer>
        </section>
      </div>
    </div>
  </div>
</div>