import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CompanySupplierModel, CompanySupplierQuery, CompanySupplierViewModel } from 'src/app/models/supplier-models';
import { CompanySupplierService } from 'src/app/services/company-supplier.service';
import { LayoutService } from 'src/app/services/layout.service';
import { ModalService, State } from 'src/app/services/modal.service';
import { OnInit, Component } from '@angular/core';
import { e168ValidatorNullable } from 'src/app/helpers/mobile-format-validator';
import { LinkToCompanyStatus } from "../../enums/link_to_company_status";
import { LinkToCompanyRequestModel, UnlinkToCompanyRequestModel } from "../../models/link_to_company_request_model";
import { convertObjectToUrlParameters } from 'src/app/helpers/extract-params-from-model';

@Component({
  selector: 'obc-company-supplier',
  templateUrl: './company-supplier.component.html',
  styleUrls: ['./company-supplier.component.scss']
})
export class CompanySupplierComponent implements OnInit {

  LinkToCompanyStatus = LinkToCompanyStatus;
  selectedSupplier: CompanySupplierViewModel;
  onlyActiveSites: boolean = true;

  formGroup = new FormGroup({
    id: new FormControl(null, []),
    name: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.email]),
    mobile: new FormControl(null, [e168ValidatorNullable]),
    contactName: new FormControl(null),
  });
  inProgress: boolean = false;
  isInUpdating: boolean = false;
  suppliers = new BehaviorSubject<CompanySupplierViewModel[]>([]);
  supplierFilter: string = '';


  constructor(private companySupplierService: CompanySupplierService,
    private layoutService: LayoutService,
    private modalService: ModalService, private bsModalService: BsModalService) {
  }

  ngOnInit() {
    this.getSuppliers();
    this.layoutService.header = "Manage Suppliers";
  }

  onActiveChanged(value) {
    if (this.onlyActiveSites == value) // don't call api if value has not changed
      return;

      if (this.isInUpdating)
      return;

    this.isInUpdating = true;
    this.onlyActiveSites = value;
    this.getSuppliers();
  }

  get filteredSuppliers() {
    let supplierFilter = (this.supplierFilter ?? '').toLowerCase();
    return this.suppliers.value?.filter(s =>
      (supplierFilter ?? '').length == 0 ||
      s.name.toLowerCase().indexOf(supplierFilter.toLowerCase()) >= 0 ||
      ((s.companySupplierBusinesses ?? []).some(b =>
        (b.abn ?? '').indexOf(supplierFilter) >= 0 ||
        (b.acn ?? '').indexOf(supplierFilter) >= 0 ||
        (b.name ?? '').toLowerCase().indexOf(supplierFilter) >= 0)));
  }

  getSuppliers() {
    this.inProgress = true;
    this.companySupplierService.getSuppliers({ showArchivedSuppliers: !this.onlyActiveSites } as CompanySupplierQuery, null)
      .pipe((finalize(() => { this.inProgress = false; this.isInUpdating = false; })))
      .subscribe(res => {
        this.suppliers.next(res.data);
      });
  }


  addSupplier() {
    this.inProgress = true;
    this.companySupplierService.addSupplier(
      this.getSupplierModel()
    )
      .pipe((finalize(() => this.inProgress = false)))
      .subscribe(res => {
        this.getSuppliers();
        this.bsModalHandle.hide();
      }, err => {
        this.modalService.error(err?.error ?? "Unknown Error")
      });
  }

  updateSupplier() {
    this.inProgress = true;
    var id = +this.formGroup.controls.id.value;
    this.companySupplierService.updateSupplier(
      id,
      this.getSupplierModel(),
    )
      .pipe((finalize(() => this.inProgress = false)))
      .subscribe(res => {
        this.getSuppliers();
        this.formGroup.reset();
        this.bsModalHandle.hide();
      }, err => {
        this.modalService.error(err?.error ?? "Unknown Error")
      });
  }

  onEditSupplier(template: any, supplier: CompanySupplierViewModel) {
    this.formGroup.controls.id.setValue(supplier.id);
    this.formGroup.controls.name.setValue(supplier.name);
    this.formGroup.controls.email.setValue(supplier.email);
    this.formGroup.controls.mobile.setValue(supplier.mobile);
    this.formGroup.controls.contactName.setValue(supplier.contactName);
    this.openUpsertModal(template);
  }

  deleteSupplier(supplier: CompanySupplierViewModel) {
    this.modalService.confirm(`Are you sure you want to deactivate ${supplier.name}? This will remove the supplier and their documents from all sites and make them inactive.`, "Deactivate Supplier Confirmation", 'Deactivate', 'Cancel', null, null, true).subscribe(confirm => {
      if (confirm) {
        this.inProgress = true;
        this.companySupplierService.deleteSupplier(
          supplier.id
        )
          .pipe((finalize(() => this.inProgress = false)))
          .subscribe(res => {
            this.getSuppliers();
          }, err => {
            this.modalService.error(err?.error ?? "Unknown Error")
          });
      }
    })
  }

  activateSupplier(supplier: CompanySupplierViewModel) {
    this.modalService.confirm(`Are you sure you want to activate ${supplier.name}?`, "Activate Supplier Confirmation", 'Activate', 'Cancel', 'btn-warning', 'btn-default').subscribe(confirm => {
      if (confirm) {
        this.inProgress = true;
        this.companySupplierService.activateSupplier(supplier.id)
          .pipe((finalize(() => this.inProgress = false)))
          .subscribe(_ => {
            this.getSuppliers();
          });
      }
    });
  }

  private getSupplierModel(): CompanySupplierModel {
    return {
      name: this.formGroup.controls.name.value,
      email: this.formGroup.controls.email.value,
      mobile: this.formGroup.controls.mobile.value,
      contactName: this.formGroup.controls.contactName.value,
    } as CompanySupplierModel;
  }

  onAddSupplier(template: any) {
    this.formGroup.reset();
    this.openUpsertModal(template);
  }

  bsModalHandle: any;

  openUpsertModal(template) {
    this.bsModalHandle = this.bsModalService.show(template);
  }

  openInvitationModal(template, supplier: CompanySupplierViewModel) {
    this.selectedSupplier = supplier;
    this.invitationEmail.setValue(supplier.email);
    this.invitationMobile.setValue(supplier.mobile);
    this.bsModalHandle = this.bsModalService.show(template);
  }

  unlinkSupplierFromCompany(supplier: CompanySupplierViewModel) {
    this.modalService.confirm("Do You Want to Unlink supplier?", "Unlink Supplier").subscribe(confirm => {
      if (confirm) {
        this.inProgress = true;
        this.companySupplierService.unlinkSupplierFromCompany(
          { companySupplierId: supplier.id } as UnlinkToCompanyRequestModel
        )
          .pipe((finalize(() => this.inProgress = false)))
          .subscribe(res => {
            this.getSuppliers();
            this.bsModalHandle.hide();
          }, err => {
            this.modalService.error(err?.error ?? "Unknown Error")
          });
      }
    })
  }

  invitationMobile: FormControl = new FormControl(null, [
    Validators.required,
    Validators.maxLength(15),
    Validators.minLength(10)
  ]);
  invitationEmail: FormControl = new FormControl(null, [
    Validators.required,
    Validators.email
  ]);

  sendInvitationToCompany() {
    this.inProgress = true;
    this.companySupplierService.linkSupplierToCompany(
      {
        companySupplierId: this.selectedSupplier?.id,
        email: this.invitationEmail.value,
        mobile: this.invitationMobile.value,
      } as LinkToCompanyRequestModel,
    )
      .pipe((finalize(() => this.inProgress = false)))
      .subscribe(res => {
        this.getSuppliers();
        this.bsModalHandle.hide();
      }, err => {
        this.modalService.error(err?.error ?? "Unknown Error")
      });
  }

  validateInvitationFields() {
    return this.invitationMobile.valid && this.invitationEmail.valid;
  }

  cancelSupplierLinking(supplier: CompanySupplierViewModel) {
    this.modalService.confirm('Are you sure you want to cancel linking process?')
      .subscribe(res => {
        if (res) {
          this.inProgress = true;
          this.companySupplierService.cancelSupplierLinking(supplier.id)
            .subscribe(_ => {
              this.inProgress = false;
              this.getSuppliers();
            },
              err => {
                this.inProgress = false;
                this.modalService.error(err?.error ?? "Unknown Error")
              });
        }
      });
  }

  resendSupplierEmail(supplier: CompanySupplierViewModel) {
    this.modalService.confirm('Are you sure you want to resend invitation?')
      .subscribe(res => {
        if (res) {
          this.inProgress = true;
          this.companySupplierService.resendSupplierLinkingInvitation(supplier.id)
            .subscribe(_ => {
              this.inProgress = false;
              this.getSuppliers();
            },
              () => {
                this.inProgress = false;
              });
        }
      });
  }

  exportCSVReport() {
    var model = {
      pagingInfo: null,
      query: {
        term: this.supplierFilter,
        showArchivedSuppliers: !this.onlyActiveSites
      } as CompanySupplierQuery,
    };

    const params = convertObjectToUrlParameters(model);
    return window.open('/api/CompanySupplier/report-csv/?' + params);
  }

}
