<div class="emergency-contact d-flex flex-column">
  <div *ngIf="dataObject?.name && dataObject?.name != ''" class="d-flex align-items-center mt-1">
    <div class="title mr-2">Name:</div>
    <div>{{ dataObject?.name }}</div>
  </div>
  <div *ngIf="dataObject?.mobile && dataObject?.mobile != ''" class="d-flex align-items-center mt-1">
    <div class="title mr-2">Mobile:</div>
    <div>{{ dataObject?.mobile | mobile }}</div>
  </div>
  <div *ngIf="dataObject?.relationship && dataObject?.relationship != ''" class="d-flex align-items-center mt-1">
    <div class="title mr-2">Relationship:</div>
    <div>{{ dataObject?.relationship }}</div>
  </div>
</div>
