<accordion>
    <accordion-group [isOpen]="expandInduction" #accGroup>
        <div accordion-heading>
            <i class="fa mr-2"
                [ngClass]="{'fa-chevron-down': accGroup?.isOpen, 'fa-chevron-up': !accGroup?.isOpen}"></i>
            <obc-induction-status (onApproveOrReject)="callFatherOnApproveOrReject($event)"
                [siteInduction]="siteInductionFilledInfo" [readOnly]="false">
            </obc-induction-status>
        </div>
        <div class="clearfix" *ngIf="siteInductionFilledInfo.siteAttendanceId && showCheckInDetailsButton">
            <button type="button" class="btn btn-primary ntn-sm pull-right"
                (click)="openCheckInetailsPopup(questionAnswer)">Check-in
                Details</button>
        </div>
        <div class="card mb-2" *ngIf="siteInductionFilledInfo.description">
            <div class="card-header">
                Review
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <span class="pr-2">Comment:</span>
                        <label class="text-primary text-wrap">{{siteInductionFilledInfo.description}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!answerResultOfInduction()?.length && !documentResultOfInduction()?.length">
            There is no question answer or document.
        </div>
        <div *ngIf="answerResultOfInduction()?.length || documentResultOfInduction()?.length">
            <div class="card" *ngIf="answerResultOfInduction()?.length">
                <div class="card-header">
                    Question Answers
                </div>
                <div class="card-body">
                    <obc-question-list-answer-renderer [answers]="answerResultOfInduction()">
                    </obc-question-list-answer-renderer>
                </div>
            </div>
            <div class="card" *ngIf="documentResultOfInduction()?.length">
                <div class="card-header">
                    Document Answers
                </div>
                <div class="card-body">
                    <ul class="simple-post-list">
                        <li class="d-flex flex-column justify-content-around"
                            *ngFor="let document of documentResultOfInduction()">
                            <div class="post-image">
                                <div class="img-thumbnail">
                                    <i class="fas fa-address-card"></i>
                                </div>
                                <span class="text-primary ml-2" *ngIf="document.documentTypeTitle">
                                    <i class="fas fa-industry"
                                        *ngIf="document.provider == DocumentProvider.Supplier"></i>
                                    {{ document.documentTypeTitle }} <sub *ngIf="document.siteInductionId"><i
                                            class="fas fa-id-badge"></i></sub>
                                </span>
                            </div>
                            <div class="post-info flex-fill">
                                <h5>
                                    {{ document.documentTitle }}
                                    <small class="text-danger small" *ngIf="document.expireDate">
                                        Expire In :
                                        {{ document.expireDate | datetime }}
                                        <span class="ml-2 badge badge-danger"
                                            *ngIf="isExpiredDocument(document)">EXPIRED</span>
                                    </small>
                                </h5>
                            </div>
                            <div class="align-self-end">
                                <obc-file-viewer
                                    [urls]="[document.fileUrl].concat(document.additionalFileUrls ? document.additionalFileUrls : [])"
                                    [btnTitle]="'View Documents'" [btnClass]="'btn btn-sm btn-success'">
                                </obc-file-viewer>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </accordion-group>
</accordion>

<ng-template #questionAnswer>
    <obc-question-answer [questionAnswerInput]="questionAnswerInputModel"
        (resultOfApproveReject)="callFatherOnApproveOrReject(siteInductionFilledInfo)">
    </obc-question-answer>
</ng-template>