import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BehaviorSubject } from 'rxjs';
import { InductionViewModel } from 'src/app/models/induction-viewmodel';
import { InductionService } from 'src/app/services/induction-service';
import { ReplaySubjectsService } from "../../services/replay-subjects";

@Component({
  selector: 'obc-company-induction-selector',
  templateUrl: './company-induction-selector.component.html',
  styleUrls: ['./company-induction-selector.component.scss']
})

export class CompanyInductionSelectorComponent implements OnInit {
  @ViewChild('selector') selector: NgSelectComponent;

  allSelectedItems: number[] = [];
  @Input() selectedInductions: InductionViewModel[];

  _required = false;
  @Input() siteId;
  @Input() set control(value: FormControl) {
    this.formControl = value;
    this.formControl.valueChanges.subscribe(res => {
      this.allSelectedItems.push(res);
      this.onSelectInduction.emit(this.inductions?.find(x => x.id == res));
      this.filteredInductions = this.inductions?.filter((item) => !this.allSelectedItems.includes(item.id))
    })
  };
  formControl: FormControl;
  loading: boolean = false;
  filteredInductions: InductionViewModel[];
  inductions: InductionViewModel[];
  term$ = new BehaviorSubject<string>(null);
  @Input() sessionId = null;
  @Output() onSelectInduction = new EventEmitter();
  @Input() set required(value: boolean) {
    if (value) {
      this._required = true;
      this.formControl.setValidators(Validators.required);
    }
  };

  clearSelector() {
    this.selector.handleClearClick();
  }

  constructor(private inductionService: InductionService,
    private replaySubjectsService: ReplaySubjectsService) { }

  ngOnInit() {
    this.replaySubjectsService.inductionFilterItemsObs.asObservable().subscribe((res) => {
      this.allSelectedItems = res.map((item) => item.id);
      this.filteredInductions = this.inductions?.filter((item) => !this.allSelectedItems.includes(item.id))
    });

    this.inductionService.getCachedInductions()
      .subscribe(res => {
        this.inductions = res;
        this.term$.subscribe(term => {
          if (term) {
            this.filteredInductions = this.inductions.filter((x) => {
              return x.inductionKey?.toLocaleLowerCase()?.indexOf(term.toLocaleLowerCase()) > -1 ||
                x.title?.toLocaleLowerCase()?.indexOf(term.toLocaleLowerCase()) > -1;
            });
          } else {
            this.filteredInductions = [...this.inductions];
          }
        });

        this.allSelectedItems = this.selectedInductions.map((item) => item.id);
        this.filteredInductions = [...this.inductions?.filter((item) => !this.allSelectedItems.includes(item.id)) ?? []]

      })

  }

}
