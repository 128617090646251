import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import { IndustryTypeViewModel } from '../models/industry-type/industry-type-view-model';
import { VisitorType } from '../models/site-visitortype-accompanier-viewmodel';
import { IndustryTypeService } from './industry-type-service';
import { VisitorTypeService } from './visitor-type.service';
import {ItemRendererDirtyControlsModel} from "../models/item-renderer-dirty-controls-model";

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  public formControlDirtySubject: Subject<ItemRendererDirtyControlsModel> = new Subject<ItemRendererDirtyControlsModel>();
  private _visitorTypes = new BehaviorSubject<VisitorType[]>(null);
  get visitorTypes(): BehaviorSubject<VisitorType[]> {
    if (this._visitorTypes.value == null) {
      this.visitorTypeService.getAllVisitorTypes().subscribe(res => {
        this._visitorTypes.next(res);
      })
    }
    return this._visitorTypes;
  }

  private _industryTypes = new BehaviorSubject<IndustryTypeViewModel[]>(null);
  get industryTypes(): BehaviorSubject<IndustryTypeViewModel[]> {
    if (this._industryTypes.value == null) {
      this.industryTypeService.getActiveIndustryTypes().subscribe(res => {
        this._industryTypes.next(res);
      })
    }
    return this._industryTypes;
  }

  constructor(private visitorTypeService: VisitorTypeService,
    private industryTypeService: IndustryTypeService) { }
}
