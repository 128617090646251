<div class="card">
  <div class="card-header">
    Upload {{workflowTitle}} Documents
    <div class="card-actions">
      <a (click)="hide.emit()" class="card-action card-action-dismiss" data-card-dismiss=""></a>
    </div>
  </div>
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="row mb-2">
      <div class="col-md-12">
        <button type="button" class="btn {{isCheckAnyItem(2)?'btn-warning':'btn-default'}}"
          [disabled]="!isCheckAnyItem(2)" (click)="groupCheckedItems()">Group</button>
        <button type="button" class="btn {{isCheckAnyItem(1)?'btn-warning':'btn-default'}} ml-1"
          [disabled]="!isCheckAnyItem(1)" (click)="unGroupCheckedItems()">Ungroup</button>
        <button type="button" class="btn {{isCheckAnyItem(1)?'btn-danger':'btn-default'}} ml-1"
          [disabled]="!isCheckAnyItem(1)" (click)="removeCheckedItems()">Remove</button>
      </div>
    </div>
    <ng-container *ngFor="let data of model,let i = index">
      <div class="card">
        <div class="card-body" [formGroup]="data.formGroup">
          <div class="row mb-3">
            <div class="col-md-12 mt-1">
              <label for="chkSelectedDocumentUploadFor{{i}}">
                <input type="checkbox" formControlName="checked" id="chkSelectedDocumentUploadFor{{i}}">
                <strong class="ml-1">Upload File For:</strong>
              </label>
              <label class="ml-3">
                <input type="radio" [value]="0" formControlName="documentTypeMode"
                  (change)="updateDocumentTypeMode(data,true)">
                New Document
              </label>
              <label class="ml-3" *ngIf="siteSupplierDocumentTypes?.length">
                <input type="radio" [value]="1" formControlName="documentTypeMode"
                  (change)="updateDocumentTypeMode(data,false)">
                Existing Document
              </label>
            </div>
            <div class="col-md-6 mt-1">
              <label>
                {{data.formGroup.controls['documentTypeMode'].value == 0?'Document':'Title'}}
              </label>
              <input *ngIf="data.formGroup.controls['documentTypeMode'].value == 0"
                formControlName="siteSupplierDocumentTypeTitle" class="form-control" type="text">
              <select
                *ngIf="data.formGroup.controls['documentTypeMode'].value != 0 && siteSupplierDocumentTypes?.length"
                class="form-control" formControlName="siteSupplierDocumentTypeId">
                <option *ngFor="let doc of siteSupplierDocumentTypes" [value]="doc.siteSupplierDocumentTypeId">
                  {{doc.supplierDocumentTypeName}}
                </option>
              </select>
            </div>
            <div class="col-md-6 mt-1">
              <label>Version Date</label>
              <obc-input-date-time-picker required formControlName="versionDate" [mode]="DateTimePickerMode.Calender">
              </obc-input-date-time-picker>

              <!-- <input [owlDateTime]="dt1" class="form-control" formControlName="versionDate"
                  [owlDateTimeTrigger]="dt1" [(ngModel)]="currentDate">
              <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time> -->
            </div>
          </div>
          <ng-container *ngIf="variableList?.length > 0">
            <div class="row mb-3" [formGroup]="data.variableFormGroups">
              <div class="col-md-6 mt-1" *ngFor="let variable of variableList">
                <label> {{variable.title}} </label>
                <ng-container *ngIf="variable.type==VariableDefinitionType.Date">
                  <obc-input-date-time-picker [formControlName]="variable.key" [mode]="DateTimePickerMode.Calender">
                  </obc-input-date-time-picker>
                </ng-container>
                <ng-container *ngIf="variable.type==VariableDefinitionType.Unknown">
                  <input [formControlName]="variable.key" class="form-control" type="text">
                </ng-container>
              </div>
            </div>
          </ng-container>

          <div class="col-md-12 mt-1" *ngIf="data.selectedFiles?.length">
            <div class="col-md-12 d-flex flex-row mt-1" *ngFor="let item of data.selectedFiles">
              <div class="flex-fill">
                <i class="fa fa-file mr-1 text-primary"></i>
                {{item.filename}} [{{item.length}} bytes]
              </div>
              <div>
                <div class="text-right">
                  <button (click)="onRemoveFile(data, item)" class="btn btn-danger m-1">Remove</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="row mt-3">
      <div class="col-md-12">
        <ngx-file-drop [dropZoneClassName]="'alert alert-info text-center'"
          [browseBtnClassName]="'btn btn-block btn-success'" (onFileDrop)="dropped($event)"
          [accept]="'.png, .jpg, .jpeg, application/pdf'">
          <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div>
              <p class="hint"> You can drag and drop your files here or click Select Files button.</p>
              <button type="button" class="btn btn-block btn-success" (click)="openFileSelector()">
                Select Files
              </button>

              <p class="hint small-text" style="font-size: 12px;margin-top: 41px;text-align: center;"> Supported file
                types include PDF and images.</p>
            </div>
          </ng-template>
        </ngx-file-drop>
        <!-- <button type="button" class="btn btn-block btn-success mb-1" (click)="addNewUploadModel()">
            <i class="fa fa-plus"></i>
            Add New Document
        </button> -->
        <!-- <div class="alert alert-info text-center" style="height: 100px;padding-top:30px;" #dragContainer
            (click)="triggerFileSelect()">
            <input #fileSelector multiple accept="image/*,application/pdf"
                style="height: 43px; border-bottom-right-radius: 0; border-top-right-radius: 0;"
                class="hide hidden" type="file">
            <i>You can drag and drop your files here.</i>
        </div> -->
      </div>
    </div>
    <div class="col-md-12 mt-1">
      <div class="d-flex flex-row flex-row-reverse">
        <button class="btn btn-primary btn-block m-1" [disabled]="!formIsValid()" (click)="upload()">Submit</button>
      </div>
    </div>
  </div>
</div>
<!-- <ng-template #fileTemplate let-item="item" let-index="index">
    <div class="col-md-12 d-flex flex-row mt-1">
        <div class="flex-fill">{{item.initData.filename}} [{{item.initData.length}} bytes]</div>
        <div>
            <div class="text-right">
                <button (click)="onRemoveFile(item.initData)" class="btn btn-danger m-1">Remove</button>
            </div>
        </div>
    </div>
</ng-template> -->
