<div #popDivTemplate>
  <i #pop="bs-popover" class="pointer fas fa-filter ml-2" [ngClass]="{'font-danger':items?.length}" [outsideClick]="outsideClick" [popover]="template"
    [popoverTitle]="popoverTitle" [placement]="placement">
  </i>

   <ng-template #template> 
    <app-popover-filter-simple
      [inventoryLocationSelectorCanAddNewLocation]="inventoryLocationSelectorCanAddNewLocation"
      [addButtonLabel]="addButtonLabel" (self)="popoverFilter = $event" [items]="items" [type]="filterType" [formType]="formType"
      [fromDate]="fromDate" [toDate]="toDate" (update)="onUpdate($event)" [loadForExternalCompany]="loadForExternalCompany" ></app-popover-filter-simple>
  </ng-template>
</div>
