import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentProvider } from 'src/app/enums/document-provider.enum';
import { QuestionType } from 'src/app/enums/question-type';
import { SiteInductionFilledInfoViewModel } from 'src/app/models/induction/site-induction-filled-info-view-model';
import { QuestionAnswerInputModel } from 'src/app/models/question-answer-inputmodel';
import { SiteAttendanceDocumentViewModel } from 'src/app/models/site-attendance-document-viewmodel';
import { SiteInductionViewModel } from 'src/app/models/site-induction-view-model';
import { SiteQuestionAnswerViewModel } from 'src/app/models/site_question_answer_view_model';
import { UserContactInformation } from 'src/app/models/user-profile-viewmodel';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'obc-induction-filled-info',
  templateUrl: './induction-filled-info.component.html',
  styleUrls: ['./induction-filled-info.component.scss']
})
export class InductionFilledInfoComponent implements OnInit {
  @Input() siteInductionFilledInfo: SiteInductionFilledInfoViewModel;
  @Input() expandInduction: boolean = false;
  @Input() showCheckInDetailsButton: boolean = false;
  @Output() onApproveOrReject: EventEmitter<any> = new EventEmitter();

  questionAnswerInputModel: QuestionAnswerInputModel;
  checkInAnswerResult: SiteQuestionAnswerViewModel[];
  siteAttendanceDocuments: SiteAttendanceDocumentViewModel[];
  userInformation: UserContactInformation;
  questionType = QuestionType;
  DocumentProvider = DocumentProvider;

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
  }

  callFatherOnApproveOrReject($event: SiteInductionViewModel) {
    this.onApproveOrReject.emit($event);
  }

  answerResultOfInduction() {
    return this.siteInductionFilledInfo?.siteInductionQuestionAnswers;
  }
  documentResultOfInduction() {
    return this.siteInductionFilledInfo?.siteInductionDocuments;
  }
  isExpiredDocument(doc: SiteAttendanceDocumentViewModel): boolean {
    return doc.expireDate != null && new Date(doc.expireDate) < new Date();
  }
  openCheckInetailsPopup(template) {
    this.questionAnswerInputModel = {
      siteAttendanceId: this.siteInductionFilledInfo.siteAttendanceId,
      isCheckInAnswer: true,
    } as QuestionAnswerInputModel;
    this.modalService.show(template, "modal-lg");
  }
}
