<div class="obc-pagination-wrapper">
  <obc-loader [show]="inProgress"></obc-loader>
  <div class="row {{ reorderingClass }}">
    <div class="col-12 mb-2">
      <div class="d-flex justify-content-end">
        <obc-question-answer-filter *ngIf="gridConfig?.displayQuestionsFilter" [filters]="mixedFilterQuestions"
          (onClose)="onUpdateFilterQuestions($event);"></obc-question-answer-filter>
        <obc-announcement-filter class="ml-1" *ngIf="gridConfig?.displayAnnouncementsFilter"
          [filters]="filterAnnouncements" (onClose)="onUpdateFilterAnnouncements($event);"></obc-announcement-filter>
        <obc-dynamic-fields-filter class="ml-1" *ngIf="gridConfig?.displayDynamicAssignFieldsFilter"
          [isAssignMode]="true" [filters]="filterAssignDynamicFields"
          (filters)="updateDynamicFieldsFilters($event, true)" (refreshGrid)="loadData(0)"
          (onClose)="onUpdateFilterAssignDynamicFields($event);">
        </obc-dynamic-fields-filter>
        <obc-dynamic-fields-filter class="ml-1" *ngIf="gridConfig?.displayDynamicFieldsFilter" [isAssignMode]="false"
          [filters]="filterDynamicFields" (filters)="updateDynamicFieldsFilters($event, false)"
          (refreshGrid)="loadData(0)" (onClose)="onUpdateFilterDynamicFields($event);">
        </obc-dynamic-fields-filter>
      </div>
    </div>
    <div class="form-group col-12 mb-3" *ngIf="dynamicFieldsFilters?.length">
      <obc-dynamic-fields-filter-renderer [filters]="dynamicFieldsFilters"
        (removeDynamicField)="removeDynamicField($event)"></obc-dynamic-fields-filter-renderer>
    </div>
    <div class="form-group col-12 mb-3"
      *ngIf="mixedFilterQuestions?.displayFilters?.length > 0 || filterAnnouncements?.length">
      <label>Filter Info</label>
      <obc-question-answer-filter-renderer [filterQuestions]="mixedFilterQuestions?.displayFilters"
        (onRefreshFilterQuestions)="onUpdateFilterQuestionsFromRenderer($event)"></obc-question-answer-filter-renderer>
      <div class="row">
        <div class="col-12" *ngFor="let _announcement of filterAnnouncements">
          <app-filtered-announcement [model]="_announcement" (stateChange)="loadData(0)"
            (remove)="onRemoveAnnouncementFromFilter($event)"></app-filtered-announcement>
        </div>
      </div>
    </div>
    <div class="form-group col-12">
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="mb-0 mr-1"></h5>
        <div class="d-flex justify-content-end">
          <button type="button" *ngIf="
                            gridConfig?.scheduleReport?.enabled &&
                      userService.hasCompanyFeature(
                        ProductPlanFeature.SchaduleReport
                      )
                    " class="btn btn-secondary mr-1" [disabled]="!records || records?.length == 0" (click)="
                      openCreateScheduleReportModal(saveReport, 'schedule')
                    ">
            <i class="fas fa-file-excel-o"></i>
            Save As Scheduled Report
          </button>
          <button type="button" *ngIf="
                                gridConfig?.displayEnableMagicLink &&
                                userService.isGodUser()" class="btn btn-outline-danger mr-1" (click)="
                      openCreateScheduleReportModal(saveReport, 'magiclink')
                    ">
            <i class="fas fa-file-excel-o"></i>
            Get Magic Link
          </button>
          <button class="btn btn-primary" style="min-width: 140px" *ngIf="gridConfig.displayRefreshButton"
            [disabled]="this.gridConfig?.refreshButtonValidity != undefined && !this.gridConfig?.refreshButtonValidity"
            (click)="onFillSearchFilterBeforeSearch()">
            Refresh
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row {{ reorderingClass }}">
    <div class="col-md-12">
      <hr />
      <ng-container *ngIf="filterHasAnyData()">
        <app-filter-list-renderer [filters]="allFilters" (remove)="onRemoveFromFilter($event)">
        </app-filter-list-renderer>
        <hr />
      </ng-container>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-2 {{ reorderingClass }}">
      <h5 class="pull-left">
        <span>
          {{ pagingInfo.totalCount }} Record(s)
          <span class="ml-1" *ngIf="totalDuration">
            - Total Duration: {{ totalDuration }}
          </span>
        </span>
      </h5>
      <div class="pull-right d-flex justify-content-end">
        <ng-template [ngTemplateOutlet]="csvreport"></ng-template>
        <button tooltip="Table Columns Setting" type="button" class="btn btn-dark ml-1"
          (click)="onOpenTableSettingModal(tableSettingModal)">
          <i class="fas fa-cog"></i>
        </button>
      </div>
    </div>
    <div class="col-md-12 position-relative">
      <div class="table-responsive mt-3">
        <table class="table table-bordered table-striped">
          <thead class="ws-nowrap {{ reorderingClass }}" *ngIf="records && pagingInfo.totalCount > 0">
            <tr class="grid-pagination-row">
              <td [attr.colspan]="30">
                <obc-pagination [totalCount]="pagingInfo.totalCount" [pageSize]="pagingInfo.pageSize"
                  [pageNumber]="pagingInfo.pageNumber" (pageChanged)="onPageChange($event)"></obc-pagination>
              </td>
            </tr>
          </thead>
          <thead class="ws-nowrap">
            <tr *ngIf="isReordering" class="reordering-row">
              <td colspan="1000" class="close-reorder-container">
                <div class="w-100 d-flex align-items-center justify-content-between">
                  <div class="drag-hint mr-auto">
                    You can drag and drop headers to reorder the columns
                  </div>
                  <div class="close-reorder-button-holder">
                    <button *ngIf="isReordering" type="button" class="btn btn-info ml-1" (click)="saveOrder()">
                      <i class="fa fa-check mr-2"></i>
                      Close
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="{{ isReordering ? 'reordering-highlight' : ''}}" id="sortableItems_{{gridConfig?.tableType}}">
              <ng-container [ngTemplateOutlet]="tableData"></ng-container>
            </tr>
            <tr class="{{ reorderingClass }}" *ngIf="gridConfig.displayGeneralSearch">
              <td [attr.colspan]="30">
                <input type="text" class="form-control" [formControl]="generalSearchControl"
                  placeholder="{{gridConfig.generalSearchPlaceholder}}" />
              </td>
            </tr>
          </thead>
          <tbody class="{{ reorderingClass }}" *ngIf="records">
            <ng-container *ngFor="let record of records">
              <tr>
                <td *ngFor="let col of tableColumns">
                  <div *ngIf="col?.gridTemplate && !col?.gridTemplate?.isComponentTemplateName">
                    <ng-container *ngTemplateOutlet="{
                      'SiteNameReference': siteNameReference,
                      'Inductions': inductions,
                      'SupplierDocuments': supplierDocuments,
                      'FullNameAccompanierCount': fullNameAccompanierCount,
                      'FullNameMobile': fullNameMobile
                    }[col?.gridTemplate?.templateName]; context: { data: record, col: col }"></ng-container>
                  </div>
                  <div *ngIf="col?.gridTemplate && col.gridTemplate?.isComponentTemplateName">
                    <ng-container *ngFor="let child of columnList">
                      <ng-container *ngIf="child.templateName == col.gridTemplate?.templateName">
                        <ng-container
                          *ngTemplateOutlet="child.template; context: { data: record, settings: reportTableSettings }">
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </div>
                  <div *ngIf="!col.gridTemplate" [ngSwitch]="col?.type">
                    <span *ngSwitchCase="FilterType.Mobile">
                      {{getRecordData(record, col) | mobile}}
                    </span>
                    <span *ngSwitchCase="FilterType.DateTimeRange">
                      {{getRecordData(record, col) | datetime}}
                    </span>
                    <span *ngSwitchCase="FilterType.DateRange">
                      {{getRecordData(record, col) | date}}
                    </span>
                    <span *ngSwitchCase="FilterType.Gender">
                      {{getRecordData(record, col) | gender }}
                    </span>
                    <span *ngSwitchCase="FilterType.File">
                      <obc-file-viewer [urls]="record[col.key]" [btnTitle]="getRecordData(record, col)"
                        [btnClass]="'btn btn-sm btn-success'"> </obc-file-viewer>
                    </span>
                    <span *ngSwitchCase="FilterType.Image">
                      <a [href]="getRecordData(record, col)" target="_blank" *ngIf="getRecordData(record, col)">
                        <img #imageEl (error)="onImageRenderError(imageEl)" class="base-grid-image"
                          [src]="getRecordData(record, col)" alt="image-preview">
                      </a>
                    </span>
                    <span class="d-flex" *ngSwitchCase="FilterType.SupplierDocuments">

                    </span>
                    <span class="d-flex" *ngSwitchCase="FilterType.FormType">
                      {{getRecordData(record, col) | formType }}
                    </span>
                    <span class="d-flex" *ngSwitchCase="FilterType.SiteSupplierDocumentVersionStatus">
                      {{getRecordData(record, col) | siteSupplierDocumentVersionStatus }}
                    </span>
                    <!-- <span class="d-flex" *ngSwitchCase="FilterType.SiteSupplierDocumentWorkflow">
                      {{getRecordData(record, col) | siteSupplierDocumentKindTitle | async}}
                    </span> -->
                    <span class="d-flex" *ngSwitchCase="FilterType.SupplierDocumentKind">
                      {{getRecordData(record, col)}}
                    </span>
                    <span class="d-flex" *ngSwitchCase="FilterType.DocumentExpiryStatus">
                      {{getRecordData(record, col) | documentExpiryStatus }}
                    </span>
                    <span class="d-flex" *ngSwitchCase="FilterType.PermitForm">
                      {{getRecordData(record, col)}}
                      <!--                    <ng-container *ngTemplateOutlet="permitForm; context: {item: record}">-->
                      <!--                                            </ng-container>-->
                    </span>
                    <span class="d-flex" *ngSwitchCase="FilterType.Text">
                      {{ getRecordData(record, col) }}
                    </span>
                    <span *ngSwitchDefault>
                      {{ getRecordData(record, col) }}
                    </span>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
          <tbody class="{{ reorderingClass }}" *ngIf="records && !pagingInfo.totalCount">
            <tr>
              <td [attr.colspan]="30">
                <div class="alert alert-warning">No records found.</div>
              </td>
            </tr>
          </tbody>
          <tfoot class="{{ reorderingClass }}" *ngIf="records && pagingInfo.totalCount > 0">
            <tr>
              <td [attr.colspan]="30">
                <obc-pagination [totalCount]="pagingInfo.totalCount" [pageSize]="pagingInfo.pageSize"
                  [pageNumber]="pagingInfo.pageNumber" (pageChanged)="onPageChange($event)"></obc-pagination>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="col-12 mt-2 {{ reorderingClass }}" style="text-align: end">
      <ng-template [ngTemplateOutlet]="csvreport" [ngTemplateOutletContext]="{isDropup:true}"></ng-template>
    </div>
  </div>
</div>

<ng-template #tableData>
  <ng-template ngFor let-col [ngForOf]="tableColumns" let-i="index">
    <th [attr.data-key]="col.key">
      <div class="d-flex flex-row justify-content-between">

        <ng-container *ngIf="isReordering">
          <div class="reordering-handler d-flex justify-content-between align-items-center w-100">
            <div>
              {{ col.name }}
            </div>
            <i class="fa fa-arrows-alt position-relative ml-2" style="top: 1px"></i>
          </div>
        </ng-container>
        <ng-container *ngIf="!isReordering">
          <span *ngIf="!col.enableSort">
            {{col.name}}
          </span>
          <app-sortable-title-header *ngIf="col.enableSort" [title]="col.name" [headerKey]="getColumnSortByKeyName(col)"
            [sortedHeaderKey]="this.pagingInfo?.sortBy" [isDesc]="this.pagingInfo?.sortOrder == SortOrder.DESC"
            (sort)="sortBy(getColumnSortByKeyName(col))">
          </app-sortable-title-header>
          <obc-filter-header *ngIf="col.enableFilter && col.type !== FilterType.DateTimeRange"
            [items]="filter[col.propertyNameInQuery] ?? []"
            [filterType]="getFilterType(col)"
            [formType]="getFormType(col)"
            popoverTitle="Filter By {{col.name}}" (update)="onUpdateFilter(col ?? [], $event)">
          </obc-filter-header>
          <obc-filter-header *ngIf="col.enableFilter && col.type === FilterType.DateTimeRange"
            [items]="filter[col.propertyNameInQuery]" [filterType]="col.type" [addButtonLabel]="filter[col.propertyNameInQuery]
                                  ? 'Update'
                                  : 'Add To Filter(s)'
                                  " popoverTitle="Filter By {{col.name}}" (update)="onUpdateFilter(col, $event)">
          </obc-filter-header>
        </ng-container>
      </div>
    </th>
  </ng-template>
</ng-template>

<ng-template #csvreport let-isDropup="isDropup">
  <ng-container *ngIf="userService.hasCompanyFeature(ProductPlanFeature.Export) && gridConfig?.csvReportUrl">
    <ng-container *ngIf="isExportingAsJobTask()">
      <obc-export-csv-popup (onExport)="exportCsv($event)" [userInfo]="userInfo" [isDropUp]="isDropup"
        [title]="getCsvTitle()" [disabled]="!records || records?.length == 0"></obc-export-csv-popup>
    </ng-container>
    <ng-container *ngIf="!isExportingAsJobTask()">
      <div class="btn-group" dropdown [dropup]="isDropup" placement="bottom right">
        <button [disabled]="!records || records?.length == 0" id="button-basic" dropdownToggle type="button"
          class="btn btn-success dropdown-toggle" aria-controls="dropdown-basic">
          Export as CSV <span class="caret"></span>
        </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
          aria-labelledby="button-basic">
          <li role="menuitem">
            <button class="dropdown-item cursor-pointer d-flex align-items-center" (click)="exportAll()">
              <i class="fas fa-check mr-2"></i>
              Export All Columns
            </button>
          </li>
          <li role="menuitem">
            <button class="dropdown-item cursor-pointer d-flex align-items-center" (click)="exportCurrentView()">
              <i class="fas fa-eye mr-2"></i>
              Export Current View
            </button>
          </li>
        </ul>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template let-item="item" #permitForm>
  <div *ngFor="let permitForm of item.permitForms">
    <div class="p-3">
      <div class="row px-1">
        <div class="col-md-12">
          Name:
          <strong class="text-dark">{{permitForm.name}}</strong>
        </div>
      </div>
      <div class="row px-1">
        <div class="col-md-12">
          <i class="fas fa-calendar text-success pr-1"></i>Start Date:
          <span class="text-primary">{{permitForm.startDate | date}}</span>
        </div>
      </div>
      <div class="row px-1">
        <div class="col-md-12">
          <i class="fas fa-calendar text-success pr-1"></i>End Date:
          <span class="text-primary">{{permitForm.endDate | date}}</span>
        </div>
      </div>
      <div class="row px-1">
        <div class="col-md-12">
          <i class="fas fa-save text-warning pr-1"></i>For:
          <span class="text-primary">{{permitForm.registerFor | formRegisterFor}}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #saveReport>
  <div class="card" [formGroup]="reportScheduledReport">
    <div class="card-header">
      <span *ngIf="saveReportMode == 'magiclink'">Create Magic Link</span>
      <span *ngIf="saveReportMode == 'schedule'">Create Scheduled Report</span>
    </div>
    <div class="card-body">
      <div class="col-md-12" *ngFor="let reportColumn of gridConfig?.scheduleReport?.reportColumns">
        <div class="form-group mt-2">
          <label>Selected {{ getRelatedCaption(reportColumn) }}: </label>
          <input class="form-control" [value]="getReportSelectItemsCaption(reportColumn)" disabled="true" />
        </div>
      </div>
      <div *ngIf="saveReportMode == 'schedule'" class="col-md-12 mt-2">
        <obc-email-template [showSendEmailToSiteSupervisor]="searchQuery.siteIds?.length == 1" formControlName="email">
        </obc-email-template>
      </div>
      <div class="col-md-12">
        <div class="form-group mt-2">
          <label>Report Period</label>
          <select class="custom-select form-control" formControlName="period">
            <option *ngFor="let type of GetReportTypes()" [ngValue]="ReportTypes[type]">
              {{ type }}
            </option>
          </select>
        </div>
        <div *ngIf="saveReportMode == 'schedule'" class="form-group mt-2">
          <label>Report Time</label>
          <input type="time" class="form-control" formControlName="timeSpan" />
        </div>
        <div class="form-group mt-2" *ngIf="
              periodType == ReportTypes.Monthly && saveReportMode == 'schedule'
            ">
          <ng-select class="pb-0" [items]="daysOfMonth" bindLabel="label" bindValue="value" [multiple]="true"
            placeholder="Select Days of Month" clearAllText="Clear" formControlName="daysOfMonth"></ng-select>
          <div *ngIf="!reportScheduledReport.controls.daysOfMonth.valid" style="border-bottom: 2px solid red"></div>
        </div>
        <div class="form-group mt-2" *ngIf="
              periodType == ReportTypes.Weekly && saveReportMode == 'schedule'
            ">
          <ng-select class="pb-0" [items]="daysOfWeek" bindLabel="label" bindValue="value" [multiple]="true"
            placeholder="Select Days of Week" clearAllText="Clear" formControlName="daysOfWeek"></ng-select>
          <div *ngIf="!reportScheduledReport.controls.daysOfWeek.valid" style="border-bottom: 2px solid red"></div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button class="pull-right btn btn-info" [disabled]="!isReportScheduledReportValid"
        (click)="SaveScheduledReport(saveReportMode)">
        <ng-container *ngIf="saveReportMode == 'schedule'">
          Save Report Schedule
        </ng-container>
        <ng-container *ngIf="saveReportMode == 'magiclink'">
          Get Magic Link
        </ng-container>
      </button>
    </div>
  </div>
</ng-template>
<ng-template #tableSettingModal>
  <obc-table-setting [modalRef]="this.tableSettingModalRef" [tableType]="gridConfig?.tableType"
    [allColumns]="gridColumns" [visibleColumns]="visibilityCheckedColumns" [tableSetting]="reportTableSettings"
    (saveChanges)="onTableSettingSaveChanges()" (isReorderingMode)="enableReorder()"></obc-table-setting>
</ng-template>


<ng-template let-data="data" let-col="col" #supplierDocuments>
  <span *ngIf="isShowTemplateColumns(col?.key,col?.gridTemplate?.data?.supplierDocuments)">
    <obc-supplier-document-sign-off-viewer [list]="data[col?.gridTemplate?.data?.supplierDocuments]"
      [canApprove]="false" [compactMode]="true"></obc-supplier-document-sign-off-viewer>
  </span>
</ng-template>

<ng-template let-data="data" let-col="col" #inductions>
  <span *ngIf="isShowTemplateColumns(col?.key,col?.gridTemplate?.data?.inductions)">
    <div *ngFor="let siteInduction of data[col?.gridTemplate?.data?.inductions]">
      <obc-induction-status [siteInduction]="siteInduction" [readOnly]="true"></obc-induction-status>
    </div>
  </span>
</ng-template>

<ng-template let-data="data" let-col="col" #siteNameReference>
  <span *ngIf="isShowTemplateColumns(col?.key,col?.gridTemplate?.data?.siteName)">
    {{ data[col?.gridTemplate?.data?.siteName] }}
  </span>
  <small class="text-info d-block"
    *ngIf="(isShowTemplateColumns(col?.key,col?.gridTemplate?.data?.siteReference)) && data[col?.gridTemplate?.data?.siteReference]">
    Ref: {{ data[col?.gridTemplate?.data?.siteReference] }}
  </small>
</ng-template>

<ng-template let-data="data" let-col="col" #fullNameAccompanierCount>
  <span *ngIf="isShowTemplateColumns(col?.key,col?.gridTemplate?.data?.firstName)">
    {{ data[col?.gridTemplate?.data?.firstName] }}
  </span>
  <span *ngIf="isShowTemplateColumns(col?.key,col?.gridTemplate?.data?.lastName)">
    {{ data[col?.gridTemplate?.data?.lastName] }}
  </span>
  <span class="text-success" [tooltip]="accompaniersTooltipTmpl"
    on-mouseover="setAccompaniersTooltipData(data[col?.gridTemplate?.data?.accompaniersText ])"
    *ngIf="data[col?.gridTemplate?.data?.accompanierCount] && isShowTemplateColumns(col?.key,col?.gridTemplate?.data?.accompanierCount)">(+{{
    data[col?.gridTemplate?.data?.accompanierCount] }})
  </span>
</ng-template>

<ng-template let-data="data" #fullNameMobile let-col="col">
  <span *ngIf="isShowTemplateColumns(col?.key,col?.gridTemplate?.data?.fullName)">
    {{ data[col?.gridTemplate?.data?.fullName] ?? ((data[col?.gridTemplate?.data?.firstName] ?? '') + ' ' +
    (data[col?.gridTemplate?.data?.lastName] ?? ''))
    }}
  </span>
  <div *ngIf="isShowTemplateColumns(col?.key,col?.gridTemplate?.data?.mobile) && data[col?.gridTemplate?.data?.mobile]"
    class="text-info" style="font-size: 14px;">
    ({{ data[col?.gridTemplate?.data?.mobile] | mobile }})
  </div>
</ng-template>

<ng-template #accompaniersTooltipTmpl>
  <div *ngFor="let item of accompaniersViewData">
    {{item.firstName}} {{item.lastName}} {{item.mobile != null? "(" + item.mobile + ")" : ""}}
  </div>
</ng-template>