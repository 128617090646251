import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { deepCloneJson } from 'src/app/helpers/general-functions';
import { CheckedVisitorType, VisitorType } from 'src/app/models/visitor-type';
import { VisitorTypeService } from 'src/app/services/visitor-type.service';

@Component({
  selector: 'app-visitor-type-selector',
  templateUrl: './visitor-type-selector.component.html',
  styleUrls: ['./visitor-type-selector.component.scss']
})
export class VisitorTypeSelectorComponent implements OnInit {
  visitorTypes: CheckedVisitorType[];
  limited: boolean = false;

  _selectedVisitorIds: number[] = [];

  @Input()
  set selectedVisitorIds(value: number[]) {
    this._selectedVisitorIds = deepCloneJson(value);
    if ((value?.length ?? 0) > 0)
      this.limited = true;
  }

  @Output() selectedVisitorIdsChange = new EventEmitter();
  constructor(private visitorTypeService: VisitorTypeService) { }


  emitChanges() {
    var value = this.visitorTypes.filter(x => x.checked).map(x => x.id);
    this.selectedVisitorIdsChange.emit(value);
  }

  ngOnInit() {
    this.visitorTypeService.cacheGetAllVisitorTypes().subscribe(res => {
      this.visitorTypes = res.map(x => {
        return {
          id: x.id,
          title: x.title,
          checked: this._selectedVisitorIds?.some(y => x.id == y)
        } as CheckedVisitorType;
      });
    })
  }


  onSwitchStateChange($event) {
    this.limited = $event;
    if ($event == false) {
      for (let index = 0; index < this.visitorTypes.length; index++) {
        const element = this.visitorTypes[index];
        element.checked = false;
      }
    }

    this.emitChanges();
  }

}
