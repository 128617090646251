<form [formGroup]="form" class="clearfix">
  <div class="d-flex flex-column">
    <div class="d-flex align-items-center mt-1">
      <div class="title mr-2">Name:</div>
      <input type="text" class="form-control" formControlName="name">
    </div>
    <div class="d-flex align-items-center mt-1">
      <div class="title mr-2">Mobile:</div>
      <input type="text" class="form-control" formControlName="mobile">
    </div>
    <div class="d-flex align-items-center mt-1">
      <div class="title mr-2">Relationship:</div>
      <input type="text" class="form-control" formControlName="relationship">
    </div>
  </div>
</form>

