<div class="row mt-2">
  <div class="col-md-12">
    <div class="d-flex flex-column">
      <form [formGroup]="rdc" class="mb-1 w-100">
        <div class="d-flex flex-column">
          <div>
            <div class="d-flex align-items-center">
              <i *ngIf="isDocumentComponentValid" style="color: green;" class="fas fa-check-circle  mr-2"></i>
              <i *ngIf="!isDocumentComponentValid" style="color: red;" class="fas fa-times-circle  mr-2"></i>

              <ng-container *ngIf="rdc.item.question">
                <obc-question-title-renderer [question]="rdc.item.question">
                </obc-question-title-renderer>
              </ng-container>

              <span *ngIf="!rdc.item.question">{{rdc.item?.documentType?.title}}</span>

              <span *ngIf="type.multiImageMode == documentMultiImageModes.FrontBack"> Front/Back</span>
              <span *ngIf="type.multiImageMode == documentMultiImageModes.MultiImage"> Multi</span>
              <button *ngIf="rdc.item.additionalDocument" type="button" class="ml-auto mr-4 btn btn-danger btn-sm mb-1"
                (click)="onRemoveDocumentType(rdc.item?.documentType)">
                <i class="fa fa-times"></i>
                Remove Document
              </button>
            </div>

            <div class="row mt-2 ml-3"
              *ngIf="rdc.item.question && rdc.item?.documentTypes != null && rdc.item?.documentTypes.length > 1">
              <div class="col-12 col-md-6">
                <span class="small">Document Types:</span>
                <select [formControl]="documentTypesSelector" class="form-control"
                  (change)="onSelectedDocumentType($event)">
                  <option value="{{ documentType.id }}" *ngFor="let documentType of rdc.item?.documentTypes">{{
                    documentType.title}}
                  </option>
                </select>
              </div>
            </div>

            <div class="row mt-3 align-items-center"
              *ngIf="rdc != null && (rdc?.item?.initialAnswer != null || answer.filaAsBase64)">
              <ng-container *ngIf="displayInitialAnswerSrc()">
                <div class="col-12 col-md-6">
                  <div class="d-flex">
                    <div class="d-flex align-items-center justify-content-center image-block-container">
                      <a *ngIf="rdc?.item?.initialAnswer?.fileUrl; else noHrefImage"
                        href="{{ rdc?.item?.initialAnswer?.fileUrl }}" target="_blank">
                        <img [src]="displayInitialAnswerSrc()" alt="primitive image">
                      </a>
                      <ng-template #noHrefImage>
                        <img [src]="displayInitialAnswerSrc()" alt="primitive image">
                      </ng-template>
                    </div>
                    <div class="d-flex flex-column align-items-start">
                      <div class="position-relative mt-3">
                        <ng-container
                          *ngTemplateOutlet="addFileTemplate; context: {isAdditionalFile: false, title: 'Change File'}"></ng-container>
                      </div>
                      <ng-container *ngIf="!answer?.additionalFiles?.length && !isRequiredDocument()">
                        <div class="btn btn-sm btn-danger mt-2" *ngIf="answer?.filaAsBase64"
                          (click)="clearWithConfirm(null)">
                          <i class="fas fa-times"></i>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="col-12 col-md-6" *ngIf="answer?.title || answer?.expireDate">
                <div class="d-flex align-items-center" *ngIf="answer?.title">
                  <span>Title: </span>
                  <strong class="ml-1">{{ answer?.title ? answer?.title : '' }}</strong>
                </div>
                <div class="d-flex" *ngIf="answer?.expireDate">
                  <span>Expire Date: </span>
                  <strong class="ml-1">{{ answer?.expireDate }}</strong>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12" *ngIf="answer?.additionalFileUrls?.length > 0">
                <div class="row">
                  <div class="col-auto" *ngFor="let additionalFile of answer?.additionalFileUrls">
                    <div class="d-flex align-items-center justify-content-center image-block-container">
                      <a href="{{ additionalFile }}" target="_blank">
                        <img src="{{ additionalFile }}" alt="primitive additional image">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3 mx-1">
              <div class="col" *ngIf="answer?.additionalFiles?.length > 0">
                <div class="row justify-content-start">
                  <ng-container *ngFor="let additionalFile of answer?.additionalFiles">
                    <ng-container *ngIf="additionalFile.fileAsBase64">
                      <div class="additional-col">
                        <div class="d-flex align-items-start">
                          <div class="d-flex align-items-center justify-content-center image-block-container">
                            <img [src]="displayAdditionalFile(additionalFile)" alt="primitive additional image">
                          </div>
                          <button *ngIf="additionalFile.fileAsBase64" type="button" class="btn btn-danger btn-sm mt-3"
                            (click)="clearWithConfirm(additionalFile)">
                            <i class="fa fa-times"></i>
                          </button>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div class="alert alert-danger mt-2 p-2" *ngIf="displayMimeTypeError">
            The type of the selected file is invalid
          </div>
          <div class="form-group d-flex justify-content-end mb-0">
            <div class="d-flex flex-row  justify-content-end">
              <ng-container
                *ngIf="!answer?.additionalFiles?.length && !answer.filaAsBase64 && !rdc?.item?.initialAnswer">
                <input style="max-width: 350px;" (change)="onSelectRequiredDocumentFile($event, rdc, false)" type="file"
                  accept="{{ validImageExtentions.join(',') }}" class="form-control-file">
              </ng-container>
            </div>
          </div>
        </div>
        <ng-container *ngIf="!displayMimeTypeError && this.rdc.item.isDirty">
          <div class="d-flex justify-content-end">

            <ng-container
              *ngIf="type.multiImageMode == documentMultiImageModes.FrontBack && answer?.additionalFiles?.length == 0 && answer.filaAsBase64">
              <ng-container
                *ngTemplateOutlet="addFileTemplate; context: {isAdditionalFile: true, title: 'Add Back File', icon: 'fa-plus'}"></ng-container>
            </ng-container>

            <ng-container *ngIf="type.multiImageMode == documentMultiImageModes.MultiImage && answer.filaAsBase64">
              <ng-container
                *ngTemplateOutlet="addFileTemplate; context: {isAdditionalFile: true, title: 'Add Additional File', icon: 'fa-plus'}"></ng-container>
            </ng-container>

          </div>
          <div class="row align-items-center mt-3" *ngIf="type.enableTitle && answer?.filaAsBase64">
            <div class="col-12 col-md-3">Title</div>
            <div class="col-12 col-md-9">
              <input type="text" placeholder="Enter document title" [value]="answer?.title ? answer?.title : ''"
                (change)="updateAnswerTitle($event)" class="form-control">
            </div>
          </div>
          <div class="row align-items-center mt-2" *ngIf="type.enableExpireDate && answer?.filaAsBase64">
            <div class="col-12 col-md-3">Expire Date</div>
            <div class="col-12 col-md-9 col-lg-7 col-xl-6">
              <obc-input-date-time-picker [placeholder]="'Select Expire Date'" [date]="answer?.expireDate"
                [minDate]="rdc?.item?.documentType?.minExpiryDate" (valueChange)="updateAnswerExpireDate($event)"
                [mode]="DatetimePickerMode.Calender">
              </obc-input-date-time-picker>
            </div>
          </div>
        </ng-container>
      </form>

    </div>
  </div>
</div>

<ng-template #addFileTemplate let-isAdditionalFile="isAdditionalFile" let-title="title" let-icon="icon">
  <div class="position-relative mt-3">
    <button style="height: 40px; max-width: 200px;" class="btn btn-sm btn-success">
      <input style="opacity: 0; position: absolute;left: 0;right: 0;top: 0;bottom: 0; z-index: 1"
        (change)="onSelectRequiredDocumentFile($event, rdc, isAdditionalFile)" type="file"
        accept="{{ validImageExtentions.join(',') }}">
      {{ title }}
      <i *ngIf="icon" class="fas {{ icon }}"></i>
    </button>
  </div>
</ng-template>