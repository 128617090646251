import { Component, OnInit } from '@angular/core';
import { SiteActiveStatus } from 'src/app/enums/site-active-status.enum';
import {
  EvacuationReportQueryModel,
  EvacuationRepotViewModel,
  EvacuationViewModel
} from 'src/app/models/evacuation/evacuation-viewmodels';
import { EvacuationService } from 'src/app/services/evacuation.service';
import { ModalService } from 'src/app/services/modal.service';
import { LayoutService } from "../../services/layout.service";
import {UserInfoViewModel} from "../../models/user-info-viewmodel";
import {UserService} from "../../services/user.service";
import {ExportCsvOutputModel} from "../../models/export-csv-output-model";
import {CsvReportRequestModel} from "../../models/csv-report-request-model";
import {catchError} from "rxjs/operators";
import {SignalRService} from "../../services/signal-r.service";
import {ToastrService} from "ngx-toastr";
import {ErrorService} from "../../services/error.service";

@Component({
  selector: 'obc-evacuation-report',
  templateUrl: './evacuation-report.component.html',
  styleUrls: ['./evacuation-report.component.scss'],
})
export class EvacuationReportComponent implements OnInit {
  SiteActiveStatus = SiteActiveStatus;

  inProgress: boolean;
  siteId: number;
  result: EvacuationRepotViewModel;
  evacuationId: number;
  userInfo: UserInfoViewModel;
  constructor(
    private layoutService: LayoutService,
    private modalService: ModalService, private evacuationService: EvacuationService,
    private userService: UserService,
    private signalRService: SignalRService,
    private toastr: ToastrService,
    private _errorService: ErrorService,) { }

  ngOnInit(): void {
    this.layoutService.header = "Evacuation Report";
    this.loadEvacuations();
    this.userService.info.subscribe(res => {
      this.userInfo = res;
    });
  }

  private loadEvacuations() {
    if (this.siteId) {
      this.evacuationService.getEvacuations({ siteId: this.siteId } as EvacuationReportQueryModel)
        .subscribe(response => {
          this.result = response;
        });
    }
  }

  onChangeSelectedSite(siteId: number) {
    this.siteId = siteId;
    this.loadEvacuations();
  }

  openEvcuationModal(evacuation: EvacuationViewModel, evacuationDetailsTemplate: any) {
    this.evacuationId = evacuation.evacuationId;
    this.modalService.show(evacuationDetailsTemplate, 'modal-lg');
  }

  exportCsv(exportInfo: ExportCsvOutputModel) {
    this.evacuationService.exportEvacuations({
      csvEmail: exportInfo.email,
      csvTitle: exportInfo.title,
      requestParams: {siteId: this.siteId} as EvacuationReportQueryModel,
    } as CsvReportRequestModel)
      .pipe(catchError(this._errorService.handleError))
      .subscribe({
        next: (res) => {
          if (res && res.success) {
            this.toastr.info(res.message);
          }
        },
      })
  }
}
