<div class="card">
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="row">
      <div class="col-sm-12 mb-2">
        <label>Select Alert Type</label>
        <label class="ml-3">
          <input type="radio" [(ngModel)]="isSmsMode" [value]="true" name="isSmsMode"
            (ngModelChange)="onChangeMode($event)">
          <strong>Sms</strong>
        </label>
        <label class="ml-2">
          <input type="radio" [(ngModel)]="isSmsMode" [value]="false" name="isSmsMode"
            (ngModelChange)="onChangeMode($event)">
          <strong>Push Notification</strong>
        </label>
      </div>
    </div>
    <div class="row" *ngIf="isSmsMode">
      <div class="col-md-12">
        <obc-emergency-alarm></obc-emergency-alarm>
      </div>
    </div>
    <div class="row" *ngIf="!isSmsMode" [formGroup]="formGroup">
      <div class="col-sm-12 mb-2">
        <label>Select a site:</label>
        <app-site-selector #sl [required]="false" [permission]="UserPermission.SendPushNotification"
          (siteIdSelect)="formGroup.controls.siteId.setValue($event)">
        </app-site-selector>

      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label>Subject</label>
          <input class="form-control" formControlName="subject" />
        </div>
        <div class="form-group">
          <label>Message</label>
          <textarea class="form-control" rows="3" formControlName="message"></textarea>
        </div>
      </div>
      <div class="col-md-12 text-right mt-2">
        <button class="btn btn-primary" [disabled]="!formGroup.valid" (click)="onSendPushNotification()">Send Push
          Notification</button>
      </div>
    </div>
  </div>