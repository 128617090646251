import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { LayoutService } from 'src/app/services/layout.service';
import { UserService } from 'src/app/services/user.service';
import { CompanyViewModel } from 'src/app/models/company-viewmodel';
import { UserPermission, UserPermissionList } from 'src/app/enums/user-permission.enum';
import { ProductPlanFeature } from 'src/app/enums/product-plan-feature.enum';
import { StorageService } from 'src/app/services/storage.service';
import { LocalStorageKey } from 'src/app/enums/local-storage-key.enum';
import { SidebarItem } from 'src/app/models/sidebar-item-mode';
import { GodUserPermission } from 'src/app/enums/god-user-permission.enum';
import { CompanyStatus } from 'src/app/enums/company-status.enum';

@Component({
  selector: 'obc-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  ProductPlanFeature = ProductPlanFeature;
  isGod: boolean = false;
  isAdminMode: boolean = false;
  hasCompany: boolean = false;
  isCompanyOwner: boolean = false;
  viewAcademyLink: boolean = false;
  companies: CompanyViewModel[] = null;
  filterCompanyListTerm: string;
  userPermissions = UserPermission;
  userPermissionList = UserPermissionList;
  isActiveAdvancedMenu: boolean = false;
  canViewActivityReport: boolean = false;
  isSupplierCompany: boolean;
  supplierManageFormsHashLink: string;

  sidebarItems: SidebarItem[] = []

  constructor(public layoutService: LayoutService,
    public userService: UserService,
    private router: Router,
    private storageService: StorageService) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
      }

      if (event instanceof NavigationEnd) {
        layoutService.closeSideBarInMobileMode();
      }

      if (event instanceof NavigationError) {
        console.log(event.error);
      }
    });

  }

  ngOnInit() {

    this.isActiveAdvancedMenu = this.storageService.get(LocalStorageKey.IsActiveAdvancedMenu) == 'true';
    this.userService.info.subscribe(res => {
      this.companies = res?.companies;
      this.isGod = res?.userPermissions?.isGodUser;
      this.hasCompany = res?.currentCompanyId != 0;
      if (!this.hasCompany) // active admin mode
        this.isAdminMode = true;

      this.isSupplierCompany = res?.isSupplierCompany ?? false;
      this.supplierManageFormsHashLink = res?.supplierManageFormsHashLink;

      this.isCompanyOwner = res?.userPermissions?.isCompanyAdmin

    });
    this.userService.userPermissions.subscribe(res => {
      if (!res) return;
      this.setMenuItems();

    })
    this.userService.currentCompany$.subscribe(res => {
      this._currentCompany = res;
      this.canViewActivityReport = this._currentCompany?.maxSites == null || this._currentCompany?.maxSites > 0;
    });

    if (this.layoutService.leftMenuIsOpen === false)
      this.layoutService.closeSideBar();

    // this.academyService.getCount(UserSystem.desktop).subscribe(res => this.viewAcademyLink = res > 0);
  }

  private setMenuItems() {
    this.sidebarItems = [];
    this.sidebarItems = [
      new SidebarItem('Dashboard', '/dashboard', 'fas fa-chart-line', () => this.hasCompany && !this.isAdminMode && !this.isTemplateCompany && this.userService.hasUserAnyOfPermissions(this.userPermissionList)),
      new SidebarItem('Sites', '/sites', 'fas fa-archway', () => this.hasCompany && !this.isAdminMode && !this.isTemplateCompany && this.userService.hasUserAnyOfPermissions([UserPermission.AdjustSiteLocation, UserPermission.ManageAutomations, UserPermission.ManageSiteQuestion, UserPermission.ModifySite, UserPermission.ManageSiteAnnouncement])),
      new SidebarItem('Approvals', '/reports/pending-approval', 'fas fa-check-square', () => this.hasCompany && !this.isAdminMode && !this.isTemplateCompany && this.userService.hasUserAnyOfPermissions([UserPermission.ApproveRejectSiteInduction])),
      new SidebarItem('Supplier Forms', '/manage-site-supplier-forms/', 'fas fa-file-alt', () => !this.isAdminMode && this.isSupplierCompany),
      new SidebarItem('Suppliers', '/supplier', 'fas fa-industry', () => this.hasCompany && !this.isAdminMode && !this.isTemplateCompany && this.userService.hasUserPermissionForCompany(UserPermission.SupplierManagement)),
      new SidebarItem('Editor', '', 'fas fa-cog', () => this.hasCompany && !this.isAdminMode && (this.userService.isCompanyAdminUser() || this.userService.hasUserAnyOfPermissions([UserPermission.ManageSiteAnnouncement])), [
        new SidebarItem('Questions', '/question', 'fas fa-question', () => this.hasCompany && !this.isAdminMode && this.userService.isCompanyAdminUser()),
        new SidebarItem('Announcements', '/manage-announcements', 'fas fa-bell', () => this.hasCompany && !this.isAdminMode && (this.userService.isCompanyAdminUser() || this.userService.hasUserAnyOfPermissions([UserPermission.ManageSiteAnnouncement]) && this.userService.hasCompanyFeature(ProductPlanFeature.Announcements))),
        new SidebarItem('Forms', '/forms', 'fas fa-file-alt', () => this.hasCompany && !this.isAdminMode && this.userService.isCompanyAdminUser() && (this.userService.hasCompanyFeature(ProductPlanFeature.Form) || this.isTemplateCompany)),
        new SidebarItem('Inductions', '/inductions', 'fas fa-id-badge', () => this.hasCompany && !this.isAdminMode && !this.isTemplateCompany && this.userService.isCompanyAdminUser()),
        // new SidebarItem('Scannables', '/scannables', 'fa fa-object-group', () => this.isActiveAdvancedMenu && this.hasCompany && !this.isAdminMode && !this.isTemplateCompany && this.userService.isCompanyAdminUser() && this.userService.hasCompanyFeature(ProductPlanFeature.Scannables)),
        new SidebarItem('Asset Manager', '/asset-manager', 'fa fa-tools', () => this.isTemplateCompany && this.hasCompany && !this.isAdminMode && this.userService.isCompanyAdminUser()),
        new SidebarItem('Roles', '/company-roles', 'fa fa-user-shield', () => this.isTemplateCompany && this.hasCompany && !this.isAdminMode && this.userService.isCompanyAdminUser()),
        new SidebarItem('Visitor Types', '/visitor-type-manager', 'fa fa-user', () => this.isTemplateCompany && this.hasCompany && !this.isAdminMode && this.userService.isCompanyAdminUser())
      ]),
      // new SidebarItem('Permissions', '', 'fas fa-users', () => this.hasCompany && !this.isAdminMode && !this.isTemplateCompany && (this.userService.isCompanyAdminUser() || this.userService.hasUserAnyOfPemissions([UserPermission.ManageViewTaggedUser, UserPermission.SiteHost, UserPermission.CompanyHost])), [
      //   new SidebarItem('Company Hosts', '/manage-hosts', '', () => this.userService.hasUserPermissionForCompany(UserPermission.CompanyHost)),
      //   new SidebarItem('Site Hosts', '/site-users', '', () => this.userService.hasUserPermissionForCompany(UserPermission.SiteHost) && this.userService.hasCompanyFeature(ProductPlanFeature.SiteHosts)),
      //   new SidebarItem('Frequent Users', '/user-permissions', '', () => this.userService.isCompanyAdminUser()),
      //   new SidebarItem('Company Employees', '/employees', '', () => this.userService.isCompanyAdminUser()),
      //   new SidebarItem('Temporary Invites', '/user-permissions/temporary', '', () => this.isActiveAdvancedMenu && this.userService.isCompanyAdminUser()),
      //   new SidebarItem('Blocked Users', '/blocked-users', '', () => this.isActiveAdvancedMenu && this.userService.isCompanyAdminUser()),
      //   new SidebarItem('Tagged Users', '/flagged-users', '', () => this.isActiveAdvancedMenu && this.userService.hasUserPermissionForCompany(UserPermission.ManageViewTaggedUser) && this.userService.hasCompanyFeature(ProductPlanFeature.Automation))],
      // ),
      new SidebarItem('Reports', '', 'fas fa-align-left', () => this.hasCompany && !this.isAdminMode && !this.isTemplateCompany, [
        new SidebarItem('Live Attendance', '/reports/site-attendance', '', () => this.canViewActivityReport && this.userService.hasUserPermissionForCompany(UserPermission.ViewReports)),
        new SidebarItem('Activity Report', '/reports/site-activity/answers', '', () => this.canViewActivityReport && this.userService.hasUserPermissionForCompany(UserPermission.ViewReports)),
        new SidebarItem('Asset Report', '/reports/asset-report', '', () => this.hasCompany && !this.isAdminMode && !this.isTemplateCompany && this.userService.hasCompanyFeature(ProductPlanFeature.Inventory) && (this.userService.hasUserPermissionForCompany(UserPermission.ViewReports) || this.userService.hasPermissionOnCompany(UserPermission.AssetTrackerAdmin))),
        new SidebarItem('Card Report', '/reports/card', '', () => this.userService.hasUserPermissionForCompany(UserPermission.ViewReports)),
        new SidebarItem('Induction Report', '/reports/induction-report', '', () => this.hasCompany && !this.isAdminMode && !this.isTemplateCompany && this.userService.hasUserPermissionForCompany(UserPermission.ViewReports)),
        new SidebarItem('Form Report', '/reports/form-report', '', () => this.hasCompany && !this.isAdminMode && !this.isTemplateCompany && this.userService.hasUserPermissionForCompany(UserPermission.ViewReports) && this.userService.hasCompanyFeature(ProductPlanFeature.Form)),
        new SidebarItem('My Scheduled Reports', '/reports/my-scheduled-reports', '', () => this.userService.hasCompanyFeature(ProductPlanFeature.SchaduleReport) && this.userService.hasUserPermissionForCompany(UserPermission.ViewReports)),
        new SidebarItem('Contact Tracing', '/reports/user-activity/tracing', '', () => this.isActiveAdvancedMenu && this.userService.hasUserPermissionForCompany(UserPermission.ViewReports)),
        new SidebarItem('Permits', '/reports/permit-report', '', () => this.hasCompany && !this.isAdminMode && !this.isTemplateCompany && (this.userService.hasUserPermissionForCompany(UserPermission.ViewReports) || this.userService.hasUserAnyOfPermissions([UserPermission.ApproveRejectSiteInduction])) && this.userService.hasCompanyFeature(ProductPlanFeature.Form)),
        new SidebarItem('Evacuations', '/reports/evacuations', '', () => this.hasCompany && !this.isAdminMode && !this.isTemplateCompany && this.userService.hasUserPermissionForCompany(UserPermission.ViewReports)),
        new SidebarItem('Supplier Document Report' + ' Report', '/reports/supplier-document', '', () => this.hasCompany && !this.isAdminMode && !this.isTemplateCompany && this.userService.hasUserPermissionForCompany(UserPermission.ViewReports) && this.userService.hasUserPermissionForCompany(UserPermission.SupplierManagement)),
        new SidebarItem('Site Briefings', '/reports/site-briefing-report', '', () => this.hasCompany && !this.isAdminMode && !this.isTemplateCompany && this.userService.hasUserPermissionForCompany(UserPermission.ViewReports) && this.userService.hasCompanyFeature(ProductPlanFeature.SiteBriefing)),
      ]),
      new SidebarItem('Host', '', 'fas fa-user-cog', () => this.hasCompany && !this.isAdminMode && !this.isTemplateCompany, [
        new SidebarItem('Check-In/Out', '/manual-check', 'fas fa-check', () => this.hasCompany && !this.isAdminMode && !this.isTemplateCompany && this.userService.hasUserPermissionForCompany(UserPermission.ManualCheck)),
        new SidebarItem('Send Push Notification', '/host-push-notification', 'far fa-comment', () => !this.isTemplateCompany && this.userService.hasUserPermissionForCompany(UserPermission.SendPushNotification)),
        new SidebarItem('Access Control Unit', '/site-access-control-unit', 'fa fa-door-open', () => this.userService.hasAdminPermissionOnAnySite() && this.userService.hasCompanyFeature(ProductPlanFeature.AccessControl))
      ]),
      new SidebarItem('Workflows', '/workflow-cartable', 'fas fa-table', () => this.hasCompany && this.isActiveAdvancedMenu && !this.isAdminMode && !this.isTemplateCompany && this.userService.getCompanyStatusValue(CompanyStatus.HasAnySupplierDocumentWorkflow) == true),
      new SidebarItem('Audit Logs', '/audit-log-report', 'fas fa-user-secret', () => this.isGod && this.isActiveAdvancedMenu && this.hasCompany && !this.isAdminMode && !this.isTemplateCompany && this.userService.isCompanyAdminUser()),
      new SidebarItem('Card Designer', '/card-designer', 'fas fa-id-card', () => false && this.hasCompany && !this.isAdminMode && !this.isTemplateCompany),
      new SidebarItem('Support', 'https://support.1breadcrumb.com', 'fas fa-question', () => !this.isTemplateCompany, null, false, 'support'),
      new SidebarItem('Administration', '', 'fas fa-crown', () => this.isGod && this.isAdminMode, [
        new SidebarItem('Company Management', '/manage-companies', '', () => this.isGod && this.isAdminMode && this.userService.hasGodUserPermissionForCompany(GodUserPermission.ManageCompanies)),
        new SidebarItem('Plan Management', '/manage-plans', '', () => this.isGod && this.isAdminMode && this.userService.hasGodUserPermissionForCompany(GodUserPermission.ManagePlans)),
        new SidebarItem('Document Types', '/user-documents', '', () => this.isGod && this.isAdminMode && this.userService.hasGodUserPermissionForCompany(GodUserPermission.ManageDocumentTypes)),
        new SidebarItem('Videos', '/manage-videos', '', () => this.isGod && this.isAdminMode && this.userService.hasGodUserPermissionForCompany(GodUserPermission.ManageVideos)),
        new SidebarItem('Industry Types', '/manage-industry-types', '', () => this.isGod && this.isAdminMode && this.userService.hasGodUserPermissionForCompany(GodUserPermission.ManageIndustryTypes)),
        new SidebarItem('Global Asset Fields', '/asset-global-dynamic-fields', '', () => this.isGod && this.isAdminMode && this.userService.hasGodUserPermissionForCompany(GodUserPermission.ManageGlobalAssetFields)),
        new SidebarItem('Change Mobile Number', '/user-edit-phone', '', () => this.isGod && this.isAdminMode && this.userService.hasGodUserPermissionForCompany(GodUserPermission.EditMobileNumber)),
        new SidebarItem('Audit Logs', '/audit-log-report/system', '', () => this.isGod && this.isAdminMode && this.userService.hasGodUserPermissionForCompany(GodUserPermission.AuditLog)),
        new SidebarItem('Procore Sync Monitoring', '/procore-sync-monitoring', '', () => this.isGod && this.isAdminMode && this.userService.hasGodUserPermissionForCompany(GodUserPermission.ProcoreSyncMonitoring)),
        new SidebarItem('Gate Management', '/manage-gates', '', () => this.isGod && this.isAdminMode && this.userService.hasGodUserPermissionForCompany(GodUserPermission.ManageGates)),
        new SidebarItem('Procore Force ReSync', '/force-procore-sync', '', () => this.isGod && this.isAdminMode && this.userService.hasGodUserPermissionForCompany(GodUserPermission.ForceProcoreSync))
      ], true)
    ];
  }

  handleSidebarItemClick($event, sidebarItem: SidebarItem) {
    if (sidebarItem.route) {
      //this.router.navigate([sidebarItem.route]);
      return;
    } else {
      let el = document.getElementById(sidebarItem.id);
      this.layoutService.toggleSubMenu($event, el);
    }
  }

  onAdvancedMenuChanged(isActive) {
    this.isActiveAdvancedMenu = isActive;
    this.storageService.set(LocalStorageKey.IsActiveAdvancedMenu, this.isActiveAdvancedMenu.toString());
  }

  onChangeCompany(id: number) {
    this.userService.changeCompany(id).subscribe(res => {
      window.location.replace('/');
    }, err => {
      console.log(err);
    })
  }

  _currentCompany: CompanyViewModel = null;
  get currentCompany(): CompanyViewModel {
    return this._currentCompany;
  }

  get isTemplateCompany(): boolean {
    if (this._currentCompany == null)
      return false;
    else
      return this._currentCompany.isTemplate;
  }

  get currentCompanyId(): number {
    if (this._currentCompany == null)
      return null;
    else
      return this._currentCompany.id;
  }

  get companyName(): string {
    if (this._currentCompany == null)
      return null;
    else
      return this._currentCompany.title;
  }

  preventEventPropagation(event: MouseEvent) {
    event.stopPropagation()
  }
}
