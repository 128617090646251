import { SendOtpModel } from './../models/send-otp-model';
import { CompanyStatus } from 'src/app/enums/company-status.enum';
import { KeyValue } from './../models/helper-models';
import { AnonymousUserProfileWithStatusViewmodel } from './../models/anonymous-user-profile-with-status-viewmodel';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserInfoViewModel } from '../models/user-info-viewmodel';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserBasicInfoViewModel } from '../models/user-basic-info-viewmodel';
import { CompanyViewModel } from '../models/company-viewmodel';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestUserProfileUpdateViewmodel, UserProfileViewmodel } from '../models/user-profile-viewmodel';
import { ChangePasswordViewmodel } from '../models/change-password-viewmodel';
import { ServerResult, ServerResultData } from '../models/server-result-data';
import { UserPermissionsModel } from '../models/user-permissions-model';
import { UserPermission } from '../enums/user-permission.enum';
import { ProductPlanFeature } from '../enums/product-plan-feature.enum';
import { IntDictionary } from '../models/dictionary';
import { UserSearchModel as UserSearchModel } from '../models/user-search-model';
import { UserEditMobileModel } from '../models/user-edit-mobile-model';
import { UserEditMobileViewModel } from '../models/user-edit-mobile-viewmodel';
import { GodUserPermission } from '../enums/god-user-permission.enum';
import { SiteDetailsViewModel } from '../models/site-viewmodel';
import { AggregatedExternalSitePermission } from '../enums/external-site-permission';
import { PreLoginStatusViewModel } from '../models/pre-login-status-view-model';
import { TotpModel } from '../models/totp-model';
import { TotpVerifyViewModel } from '../models/totp-verify-view-model';
import { PortalDisplayMode } from '../enums/portal-display-mode.enum';
import { DefaultWorkflowDisplayMode } from '../enums/constants';
import { ScheduledReportLogViewModel } from "../models/scheduled-report-log-view-model";
import { StorageService } from './storage.service';
import { CompanyAuthService } from './company-auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  apiUrl: string = '/api/user';
  info: BehaviorSubject<UserInfoViewModel> = new BehaviorSubject<UserInfoViewModel>(null);

  companyStatuses: BehaviorSubject<KeyValue<CompanyStatus, any>[]> = new BehaviorSubject([]);
  currentCompany$: BehaviorSubject<CompanyViewModel> = new BehaviorSubject<CompanyViewModel>(null);
  _currentCompany: CompanyViewModel = null;
  companyFeatures = new BehaviorSubject<IntDictionary<ProductPlanFeature>>(null)
  userPermissions: BehaviorSubject<UserPermissionsModel> = new BehaviorSubject<UserPermissionsModel>(null);

  editorMaxLength: BehaviorSubject<number> = new BehaviorSubject<number>(100000);
  defaultServerTimeZone: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  defaultCompanyType: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  currentXsrfToken = '';

  setXsrfToken(xsrfToken: string) {
    this.currentXsrfToken = xsrfToken;
    this.storageService.set('XSRF-TOKEN', xsrfToken);
  }

  getXsrfToken(): string {
    if (!this.currentXsrfToken?.length)
      this.currentXsrfToken = this.storageService.get('XSRF-TOKEN');
    return this.currentXsrfToken ?? '';
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private companyAuthService: CompanyAuthService) {
  }

  get backUrl(): string {
    return this.route.snapshot.queryParams['returnPath'];
  }

  initUserInfo(): void {
    this.currentCompany$.subscribe((res) => this._currentCompany = res);

    this.http
      .get<UserInfoViewModel>(`${this.apiUrl}/user-info`)
      .subscribe((res) => {
        if (res.reloadPage) {
          window.location.reload();
          return;
        }
        this.info.next(res);
        this.companyStatuses.next(res.companyStatuses);
        this.userPermissions.next(res.userPermissions);
        this.companyFeatures.next(res.companyFeatures);
        this.editorMaxLength.next(res.editorMaxLength);
        this.defaultServerTimeZone.next(res.defaultServerTimeZone);
        this.defaultCompanyType.next(res.defaultCompanyType);

        this.companyAuthService.setCurrentCompanyId(res.currentCompanyId);

        if (res.companies && res.companies.length > 0) {
          var cc = res.companies.find((c) => c.id == res.currentCompanyId);
          if (cc != null) this.currentCompany$.next(cc);
        }
        setTimeout(() => {
          if (res.redirectUrl) {
            this.router.navigateByUrl(res.redirectUrl);
            return;
          }
          if (res.firstName == null || res.lastName == null) {
            this.router.navigateByUrl('/user/profile');
          } else if (res.currentCompanyId != 0) {
            if (this.backUrl) this.router.navigateByUrl(this.backUrl);
            else if (this.router.url == '/not-found') {
              if (this.isTemplateCompany == true)
                this.router.navigateByUrl('/question');
              else this.router.navigateByUrl('/sites');
            }
          }
        }, 100);
      });
  }

  get currentCompany(): CompanyViewModel {
    return this._currentCompany;
  }

  get currentCompanyId(): number {
    if (this._currentCompany == null) return null;
    else return this._currentCompany.id;
  }

  get companyName(): string {
    if (this._currentCompany == null) return null;
    else return this._currentCompany.title;
  }

  get isTemplateCompany(): boolean {
    if (this._currentCompany == null) return false;
    else return this._currentCompany.isTemplate;
  }

  hasCompanyFeature(feature: ProductPlanFeature) {
    var companyFeatures = this.companyFeatures.value;
    if (!companyFeatures)
      return false;
    if (companyFeatures[feature])
      return true;
    else
      return false;
  }

  hasAdminPermissionOnSite(siteId: number): boolean {
    var userPermissions = this.userPermissions.value;
    if (!userPermissions)
      return false;
    return this.isCompanyAdminUser() || (userPermissions.siteIdsWithAdminPrivilage?.includes(siteId) ?? false);
  }

  hasAdminPermissionOnAnySite(): boolean {
    var userPermissions = this.userPermissions.value;
    if (!userPermissions)
      return false;
    return this.isCompanyAdminUser() || ((userPermissions.siteIdsWithAdminPrivilage?.length ?? 0) > 0);
  }

  hasPermissionOnCompany(permission: UserPermission): boolean {
    var userPermissions = this.userPermissions.value;
    if (!userPermissions)
      return false;
    if (userPermissions?.isCompanyAdmin || userPermissions?.isGodUser)
      return true;
    else if ((userPermissions?.companyLevelPermissions & permission) > 0)
      return true;

    return false;
  }


  hasUserPermissionForCompany(permission: UserPermission, siteId?: number): boolean {
    var userPermissions = this.userPermissions.value;
    if (!userPermissions)
      return false;
    if (userPermissions?.isCompanyAdmin || userPermissions?.isGodUser)
      return true;
    else if ((userPermissions?.companyLevelPermissions & permission) > 0)
      return true;
    var sites = Object.keys(userPermissions?.siteLevelPermissions);

    if (siteId == null) {
      var found = sites.filter(site => {
        var sitePermissions = userPermissions.siteLevelPermissions[site];
        if (sitePermissions != null && ((sitePermissions & permission) > 0))
          return true
        else return false;
      }).length > 0;
      return found;
    } else {
      if (this.hasAdminPermissionOnSite(siteId))
        return true;

      var sitePermissions = userPermissions.siteLevelPermissions[siteId];
      if (sitePermissions != null && ((sitePermissions & permission) > 0))
        return true
      else return false;
    }

  }

  hasUserAnyOfPermissions(permissions: UserPermission[]): boolean {
    var ups = this.userPermissions.value;
    if (!ups || !(permissions?.length > 0))
      return false;
    if (ups?.isCompanyAdmin || ups?.isGodUser)
      return true;

    var combined = UserPermission.None;
    for (let index = 0; index < permissions.length; index++) {
      const element = permissions[index];
      combined |= element;
    }

    if ((ups.companyLevelPermissions & combined) != UserPermission.None)
      return true;

    var sites = Object.keys(ups.siteLevelPermissions);
    for (let index = 0; index < sites.length; index++) {
      const element = ups.siteLevelPermissions[sites[index]];
      if ((element & combined) != UserPermission.None)
        return true;
    }
    return false;
  }

  hasGodUserPermissionForCompany(permission: GodUserPermission): boolean {
    var userPermissions = this.userPermissions.value;
    if (!userPermissions || !userPermissions?.isGodUser)
      return false;
    else if (userPermissions?.godUserPermissions && (userPermissions?.godUserPermissions & permission) == permission)
      return true;

    return false;
  }

  isCompanyAdminUser(): boolean {
    var userPermissions = this.userPermissions.value;
    return userPermissions?.isCompanyAdmin || userPermissions?.isCompanyOwner || userPermissions?.isGodUser;
  }

  isGodUser(): boolean {
    var userPermissions = this.userPermissions.value;
    return userPermissions?.isGodUser;
  }

  isCompanyOwnerUser(): boolean {
    var userPermissions = this.userPermissions.value;
    return userPermissions?.isCompanyOwner || userPermissions?.isGodUser;
  }

  searchUser = (model: UserSearchModel): Observable<UserBasicInfoViewModel[]> =>
    this.http.post<UserBasicInfoViewModel[]>(`${this.apiUrl}/search`, model);

  public resendOtp(model: SendOtpModel): Observable<PreLoginStatusViewModel> {
    return this.http.post<PreLoginStatusViewModel>(`/api/obcauthentication/send-2fa-otp`, model);
  }

  changeCompany = (id: number): Observable<any> =>
    this.http.get<any>(`${this.apiUrl}/change-company/${id}`);

  userProfile = (): Observable<UserProfileViewmodel> =>
    this.http.get<UserProfileViewmodel>(`${this.apiUrl}/user-profile`);

  getAccessibleUserBasicProfile = (id: number): Observable<UserBasicInfoViewModel> =>
    this.http.get<UserBasicInfoViewModel>(`${this.apiUrl}/get-accessible-user-basic-profile/${id}`);

  editProfile = (model: RequestUserProfileUpdateViewmodel): Observable<any> =>
    this.http.post<any>(`${this.apiUrl}/edit-profile`, model);

  changePassword = (model: ChangePasswordViewmodel): Observable<ServerResult> =>
    this.http.post<any>(`${this.apiUrl}/change-password`, model);

  sendVerificationEmail = (): Observable<any> =>
    this.http.get<any>(`${this.apiUrl}/send-verification-email`);

  removeProfilePicture = (): Observable<any> =>
    this.http.get<any>(`${this.apiUrl}/remove-profile-picture`);

  getAnonymousUserProfileWithCheckIns = (): Observable<AnonymousUserProfileWithStatusViewmodel> =>
    this.http.get<AnonymousUserProfileWithStatusViewmodel>(`${this.apiUrl}/get-anonymous-user-status`);

  validateEditUserMobile = (model: UserEditMobileModel): Observable<UserEditMobileViewModel> =>
    this.http.post<any>(`${this.apiUrl}/get-user-by-mobile`, model);

  editUserMobile = (model: UserEditMobileModel): Observable<boolean> =>
    this.http.post<any>(`${this.apiUrl}/edit-user-mobile`, model);

  checkCompanyStatusValue(key: CompanyStatus, value: any) {
    if (this.companyStatuses.value) {
      const entry = this.companyStatuses.value.find(x => x.key == key)
      if (entry == null) return false;
      return entry.value === value;
    }
    return false;
  }

  getCompanyWorkflowDisplayMode(): PortalDisplayMode {
    let mode = this.getCompanyStatusValue(CompanyStatus.WorkflowEngineDisplayMode);
    return mode ?? DefaultWorkflowDisplayMode;
  }

  getCompanyStatusValue(key: any): any {
    if (this.companyStatuses.value) {
      const entry = this.companyStatuses.value.find(x => x.key == key)
      if (entry == null) return null;
      return entry.value;
    }
    return null;
  }

  get canAddSite(): boolean {
    return this.checkCompanyStatusValue(CompanyStatus.CanAddSite, true);
  }

  get isEnableSeatLocation(): boolean {
    return this.checkCompanyStatusValue(CompanyStatus.IsEnableSeatingLocation, true);
  }

  get canAddNewLocation(): boolean {
    this.currentCompany$.subscribe((res) => (this._currentCompany = res));
    return this._currentCompany?.endUserCanAddNewLocation ?? false;
  }

  hasAccessToManageSite(site: SiteDetailsViewModel) {
    return site.isActive === true &&
      (
        (site.isExternalSite == false && this.hasUserPermissionForCompany(UserPermission.ModifySite, site.id)) ||
        (site.isExternalSite == true && (site.externalSitePermissions & AggregatedExternalSitePermission.AccessToManageSiteItems) > 0));
  }

  totpGenerateQrCode = (): Observable<TotpModel> =>
    this.http.post<TotpModel>(`${this.apiUrl}/totp-generate-qrcode`, null);

  totpVerifyCode = (model: TotpVerifyViewModel): Observable<boolean> =>
    this.http.post<any>(`${this.apiUrl}/totp-verify-code`, model);

  getScheduledReports = (): Observable<ServerResultData<ScheduledReportLogViewModel[]>> =>
    this.http.get<any>(`${this.apiUrl}/reports-log`);

  hasAnyAccess(siteId: number = null): boolean {
    var ups = this.userPermissions.value;
    if (!ups)
      return false;

    let hasAnySiteLevelPermission = siteId ? Object.keys(ups.siteLevelPermissions).some(x => x == siteId.toString()) : false;
    let hasAnyCompanyAccess = ups.companyLevelPermissions > UserPermission.None;
    if (ups?.isCompanyAdmin || ups?.isGodUser || hasAnySiteLevelPermission || hasAnyCompanyAccess)
      return true;

    return false;
  }


}
