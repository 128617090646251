import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LocalStorageKey } from 'src/app/enums/local-storage-key.enum';
import { SiteActiveStatus } from 'src/app/enums/site-active-status.enum';
import { SiteTemplateStatus } from 'src/app/enums/site-template-status.enum';
import { UserPermission } from 'src/app/enums/user-permission.enum';
import { BaseSiteViewModel, SiteViewModel } from 'src/app/models/site-viewmodel';
import { SiteService } from 'src/app/services/site.service';

@Component({
  selector: 'app-site-selector',
  templateUrl: './site-selector.component.html',
  styleUrls: ['./site-selector.component.scss']
})
export class SiteSelectorComponent implements OnInit {
  @Input() maxItems = 10;
  _permission: UserPermission = UserPermission.None;
  _required = false;
  @Input() set required(value: boolean) {
    if (value) {
      this._required = true;
      this.formControl.setValidators(Validators.required);
    }
  };
  @Input() loadForExternalCompany: boolean = false;


  @Input() excludeList: number[] = [];
  @Input() notSelectedSiteLabel: string = null;

  @Input() set permission(value: UserPermission) {
    this._permission = value;
  };

  _activeStatus: SiteActiveStatus = SiteActiveStatus.Active;
  @Input() set activeStatus(value: SiteActiveStatus) {
    this._activeStatus = value;
  }
  _templateStatus = SiteTemplateStatus.Normal;
  @Input() set templateStatus(value: SiteTemplateStatus) {
    this._templateStatus = value;
  }

  // input & outputs {
  @Output() siteSelect = new EventEmitter<BaseSiteViewModel>();
  @Output() siteIdSelect = new EventEmitter<number>();
  public selectedSite = new BehaviorSubject<BaseSiteViewModel>(null);

  _siteId: number = null;
  @Input() set siteId(value: number) {
    if (!value) return;
    this._siteId = value;
    this.formControl.setValue(value);
  };


  sites: BaseSiteViewModel[];
  siteGroups: any[];

  constructor(protected siteService: SiteService) { }


  formControl = new FormControl(null, [Validators.required]);
  loading: boolean = false;
  sites$: Observable<BaseSiteViewModel[]>;
  term$ = new BehaviorSubject<string>(null)

  ngOnInit() {
    this.formControl.valueChanges.subscribe(res => {
      if (this.sites) {
        if (res == null || res == 0) {
          this.siteSelect.emit(null);
          this.siteIdSelect.emit(null);
          this.selectedSite.next(null);
        } else {
          var site = this.sites.find(x => x.id == res);
          if (site == null) {
          } else {
            this.siteSelect.emit(site);
            this.selectedSite.next(site);
            this.siteIdSelect.emit(site.id);
            this.saveDefaultSite()
          }
        }
      }
    });

    setTimeout(() => {
      this.loadSiteList();
    }, 250)
  }

  getSiteList(): Observable<SiteViewModel[]> {
    if (this.loadForExternalCompany) {
      return this.siteService.getAllSitesForExternalCompany(this._activeStatus, this._templateStatus);
    } else {
      if (this._permission)
        return this.siteService.getAllPermissionedSites(this._permission, this._activeStatus, this._templateStatus);
      else
        return this.siteService.getAllActiveSites()
    }
  }

  loadSiteList() {
    this.loading = true;
    this.getSiteList()
      .pipe(finalize(() => this.loading = false))
      .subscribe(res => {
        this.setReponse(res);
      });
  }

  private setReponse(res: BaseSiteViewModel[]) {
    let that = this;
    this.sites = res.filter(function (el) {
      return that.excludeList.filter(function (selected_el) {
        return selected_el == el.id;
      }).length == 0
    });
    if (this._siteId == null) // if site set do not change that
      this.loadDefaultSite();

    if (this.selectedSite.value == null) {
      var site = this.sites.find(x => x.id == this.formControl.value);
      if (site) {
        this.selectedSite.next(site);
      }
    }
    this.sites$ = of(this.getNthItems(this.sites, this.maxItems));
    this.term$.subscribe(term => {
      if (term) {
        var filteredSites = this.searchSites(term);
        if (term != this.reachToEndTerm)
          this.reachToEnd = false;
        this.sites$ = of(this.getNthItems(filteredSites, this.reachToEnd ? filteredSites.length : this.maxItems));
      } else {
        this.sites$ = of(this.getNthItems(this.sites, this.reachToEnd ? this.sites.length : this.maxItems))
      }
    });
  }

  getNthItems(array: BaseSiteViewModel[], n: number): BaseSiteViewModel[] {
    if (!array || array.length == 0 || n <= 0) return [];
    var max = Math.min(array.length, n);
    var arr = [];
    for (var i = 0; i < max; i++)
      arr.push(array[i]);
    if (this.selectedSite.value && !arr.find(x => x.id == this.selectedSite.value.id))
      arr.push(this.selectedSite.value);
    return arr;
  }

  loadDefaultSite() {
    if (!this._required) return;

    var selectedSiteId = localStorage
      ? parseInt(localStorage.getItem(LocalStorageKey.LastVisitedSiteId), 10)
      : null;

    if (this.sites?.length) {
      if (this.sites.find(x => x.id == selectedSiteId))
        this.formControl.setValue(selectedSiteId);
      else {
        let id = this.sites[0].id;
        this.formControl.setValue(id);
      }
    }
  }

  saveDefaultSite() {
    if (localStorage && this.selectedSite?.value) {
      localStorage.setItem(
        LocalStorageKey.LastVisitedSiteId,
        this.selectedSite.value.id.toString()
      );
    }
  }

  getSiteLabel(site: BaseSiteViewModel) {
    return `${site.name} ${site.siteReference ? `[${site.siteReference}]` : ''}`
  }

  searchSites(term: string) {
    return this.sites.filter((item) => {
      return this.isMatched(term, item);
    });
  }

  clearInput() {
    this.formControl.setValue(null);
  }
  isMatched(term: string, item: BaseSiteViewModel): boolean {
    if (!term) return true;
    term = term.toLowerCase();
    return (item.name && item.name.toLowerCase().indexOf(term) > -1) ||
      (item.siteReference && item.siteReference.toLowerCase().indexOf(term) > -1);
  }

  reset() {
    this.formControl.reset();
    this.sites = [];
    //this._permission = UserPermission.None;
  }

  reachToEnd = false;
  reachToEndTerm = "";
  onScrollToEnd() {
    this.reachToEnd = true;
    this.reachToEndTerm = this.term$.value;
    this.term$.next(this.term$.value)
  }
}
