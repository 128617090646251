
export enum UserPermission {
    None = 0,
    ManageAutomations = 1 << 0, // done I,II    1
    SendEmergencyAlarm = 1 << 1,  // done I,II   2
    ManualCheckIn = 1 << 2, // done I,II    4
    ManualCheckOut = 1 << 3, // done I, II   8
    ModifySite = 1 << 4, // done I, II  16
    ManageSiteQuestion = 1 << 5, // done I, II  32
    ManageSiteAnnouncement = 1 << 6, //done I, II   64
    CompanyHost = 1 << 7,
    ViewReports = 1 << 8, //done I,II  256
    ManageViewTaggedUser = 1 << 9, //done I 512
    ViewAttendanceAnswers = 1 << 10, //done I,II  1024
    AdjustSiteLocation = 1 << 11,  //done I,II    2048
    AddSiteByTemplate = 1 << 12, //done I,II   4096 I didn't found any method in web call "site/add-site-baseon-template"
    SiteHost = 1 << 13,
    ViewKioskMode = 1 << 15,
    SendPushNotification = 1 << 16,
    ApproveRejectSiteInduction = 1 << 17,
    AssetTrackerAdmin = 1 << 18,
    SupplierManagement = 1 << 19,

    ManualCheck = ManualCheckIn | ManualCheckOut,
    LiveAttendance = ManualCheckIn | ManualCheckOut | ViewReports,
}


export const CombinedUserPermissionList: UserPermission =
    UserPermission.None |
    UserPermission.ManageAutomations |
    UserPermission.SendEmergencyAlarm |
    UserPermission.ManualCheckIn |
    UserPermission.ManualCheckOut |
    UserPermission.ModifySite |
    UserPermission.ManageSiteQuestion |
    UserPermission.ManageSiteAnnouncement |
    UserPermission.CompanyHost |
    UserPermission.ViewReports |
    UserPermission.ManageViewTaggedUser |
    UserPermission.ViewAttendanceAnswers |
    UserPermission.AdjustSiteLocation |
    UserPermission.AddSiteByTemplate |
    UserPermission.SiteHost |
    UserPermission.ViewKioskMode |
    UserPermission.SendPushNotification |
    UserPermission.ApproveRejectSiteInduction |
    UserPermission.AssetTrackerAdmin |
    UserPermission.SupplierManagement;

export const UserPermissionList = [
    UserPermission.ManageAutomations,//1 << 0, // done I,II    1
    UserPermission.SendEmergencyAlarm,// 1 << 1,  // done I,II   2
    UserPermission.ManualCheckIn,//1 << 2, // done I,II    4
    UserPermission.ManualCheckOut,//1 << 3, // done I, II   8
    UserPermission.ModifySite,//1 << 4, // done I, II  16
    UserPermission.ManageSiteQuestion,//1 << 5, // done I, II  32
    UserPermission.ManageSiteAnnouncement,//1 << 6, //done I, II   64
    UserPermission.CompanyHost,//1 << 7,
    UserPermission.ViewReports,//1 << 8, //done I,II  256
    UserPermission.ManageViewTaggedUser,//1 << 9, //done I 512
    UserPermission.ViewAttendanceAnswers,//1 << 10, //done I,II  1024
    UserPermission.AdjustSiteLocation,//1 << 11,  //done I,II    2048
    UserPermission.AddSiteByTemplate,//1 << 12, //done I,II   4096 I didn't found any method in web call "site/add-site-baseon-template"
    UserPermission.SiteHost,//1 << 13,
    UserPermission.ViewKioskMode,//1 << 15,
    UserPermission.SendPushNotification,
    UserPermission.ApproveRejectSiteInduction,
    UserPermission.AssetTrackerAdmin,
    UserPermission.SupplierManagement,
];
