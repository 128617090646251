<div *ngIf="this.visitorTypes?.length" class="card" style="background-color: transparent; padding: 0.25rem; padding-left: 1rem;">
  <div class="row mb-2">
    <div class="col-md-12">
      <span>Do you want to limit visitors based on types?</span>
    </div>
    <div class="col-md-12">
      <obc-switch falseLabel="No" trueLabel="Yes" [value]="limited" (valueChanged)="onSwitchStateChange($event)" ></obc-switch>
    </div>
  </div>
  <div class="row" *ngIf="visitorTypes && limited">
    <div class="col-md-12">
      <div>
        <div class="btn-group1">
          <label (click)="emitChanges()" *ngFor="let checkedType of visitorTypes; let x = index"
            [class.btn-primary]="checkedType.checked" [class.btn-light]="!checkedType.checked"
            class="btn ml-1 mb-1" [(ngModel)]="checkedType.checked" btnCheckbox tabindex="0"
            role="button">{{checkedType.title}}</label>
        </div>
      </div>
    </div>
  </div>
</div>