import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AsyncResult } from 'src/app/models/helper-models';
import { SiteInductionFilledInfoViewModel } from 'src/app/models/induction/site-induction-filled-info-view-model';
import { InductionService } from 'src/app/services/induction-service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'obc-site-induction-detail',
  templateUrl: './site-induction-detail.component.html',
  styleUrls: ['./site-induction-detail.component.scss']
})
export class SiteInductionDetailComponent implements OnInit {
  @Output() public result = new EventEmitter<AsyncResult>();
  @Input() siteInductionId: number;
  needParentRefresh: boolean = false;
  errorMessage = '';
  inProgress: boolean = false;
  siteInductionFilledInfo: SiteInductionFilledInfoViewModel;
  constructor(private inductionService: InductionService,
    private modalService: ModalService) { }

  ngOnInit(): void {
    this.getSiteInductionInfo();
  }

  getSiteInductionInfo() {
    this.inProgress = true;
    this.inductionService.getSiteInductionFilledInfo(this.siteInductionId).subscribe(
      res => {
        if (res.success) {
          this.siteInductionFilledInfo = res.data;
        } else {
          this.modalService.error(res.message);
          this.inProgress = false;
        }
      }, err => {
        this.modalService.error("Request Failed");
        this.inProgress = false;
      }, () => {
        this.inProgress = false;
      }
    )
  }
  onApproveOrReject(result: AsyncResult) {
    this.needParentRefresh = true;
    this.result.emit({isFinished: result.isFinished, isSucceeded: result.isSucceeded, needParentRefresh: true})
    this.getSiteInductionInfo();
  }

}
