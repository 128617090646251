import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';
import { UserService } from 'src/app/services/user.service';
import { CompanyService } from 'src/app/services/company.service';
import {SignalRService} from "../../services/signal-r.service";

@Component({
  selector: 'obc-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['./secure.component.scss']
})
export class SecureComponent implements OnInit {
  header: string = "";


  constructor(public layoutService: LayoutService,
    public companyService: CompanyService,
              private signalRService: SignalRService,
    public userService: UserService) { }

  ngOnInit(): void {
    this.userService.initUserInfo();
    this.userService.info.asObservable().subscribe((res) => {
      if(res) {
        this.signalRService.connect();
      }
    })
    this.layoutService.header$.subscribe(res => setTimeout(() => { this.header = res }, 100));
  }
}
