import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaginationInfo } from 'src/app/models/pagination-info';
import { getPaginationKeyByUrl } from "../../helpers/general-functions";

@Component({
  selector: 'obc-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  paginationKey: string = getPaginationKeyByUrl();

  @Input() totalCount: number;
  _pageSize: number = 30;
  @Input() set pageSize(value: number) {
    if (this.itemsPerPageArray.indexOf(value) >= 0)
      this._pageSize = value;
    else
      this._pageSize = 30;
  }
  get pageSize() { return this._pageSize; }

  _pageNumber : number = 1;
  @Input() set pageNumber(value : number) {
    this._pageNumber = value + 1;
  }
  get pageNumber(): number {
    return this._pageNumber-1;
  }

  @Output() pageChanged: EventEmitter<PaginationInfo> = new EventEmitter();

  showBoundaryLinks: boolean = true;
  maxSize = 10;
  itemsPerPageArray = [10, 20, 30, 50, 100, 200];

  constructor() { }

  ngOnInit(): void {
    if(localStorage && localStorage.getItem(this.paginationKey)) {
      this._pageSize = parseInt(localStorage.getItem(this.paginationKey), 10);
    }
  }
  localPageChanged(event: any) {
    this._pageNumber = event.page;
    this.pageChanged.emit({ pageSize: event.itemsPerPage, pageNumber: event.page - 1 });
    this.storeInLocalStorage(event.itemsPerPage);
  }
  onItemPerPageChange() {
    this.pageChanged.emit({ pageSize: this.pageSize, pageNumber: 0 });
    this.storeInLocalStorage(this._pageSize);
  }

  storeInLocalStorage(value) {
    if (localStorage) {
      localStorage.setItem(
        this.paginationKey,
        value
      );
    }
  }
}
