import { ApproveEntityType } from './../../../enums/approve-entity-type';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { UserAutoCompleteFilterByFields } from 'src/app/enums/user-auto-complete-target-fields';
import { FilterDateRangeModel } from 'src/app/models/filter-models/filter-date-range-model';
import * as moment from 'moment';
import { DefaultDateRange } from 'src/app/enums/default-date-range.enum';
import { CacheService } from 'src/app/services/cache.service';
import { UserAutocompleteComponent } from '../../user-autocomplete/user-autocomplete.component';
import { SiteActiveStatus } from 'src/app/enums/site-active-status.enum';
import { UserPermission } from 'src/app/enums/user-permission.enum';
import { Gender } from 'src/app/enums/gender.enum';
import { FilteGenderModel } from 'src/app/models/filter-models/filte-gender-model';
import { DatePipe } from '@angular/common';
import { DatetimePipe } from 'src/app/pipes/datetime.pipe';
import { MobilePipe } from 'src/app/pipes/mobile.pipe';
import { DurationRange } from 'src/app/models/filter-models/duration-range';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';
import { InputDateTimePickerComponent } from '../../input-date-time-picker/input-date-time-picker.component';
import { SiteInductionStatus } from 'src/app/enums/site-induction-status';
import { InventoryService } from 'src/app/services/inventory.service';
import { VisitorType } from 'src/app/models/visitor-type';
import { ToolCategoryViewModel } from 'src/app/models/inventory/tool-category-model';
import { IndustryTypeViewModel } from 'src/app/models/industry-type/industry-type-view-model';
import { ToolStatusViewModel } from 'src/app/models/inventory/tool-status-model';
import { FormSelectorComponent } from 'src/app/components/form-manage/form-selector/form-selector.component';
import { FormType } from 'src/app/enums/form-type';
import { SiteSelectorComponent } from 'src/app/components/site-selector/site-selector.component';
import { EnumToArray } from 'src/app/helpers/enum-to-array';
import { FormTypePipe } from 'src/app/pipes/form-type.pipe';
import { SiteInductionStatusPipe } from 'src/app/pipes/site-induction-status.pipe';
import { PermitStatus } from 'src/app/enums/permit-status';
import { PermitStatusPipe } from 'src/app/pipes/permit-status.pipe';
import { UserDocumentType } from 'src/app/models/user-document-type';
import { DocumentExpiryStatus } from 'src/app/enums/document-expiry-status.enum';
import { SiteAssetQueryMode } from 'src/app/enums/site-asset-query-mode.enum';
import { AssetCategoryType } from 'src/app/enums/asset-category-type-enum';
import { PendingToApproveStatus } from 'src/app/enums/approve-status';
import { ReplaySubjectsService } from "../../../services/replay-subjects";
import { InventoryLocationViewModel } from "../../../models/inventory/inventory-location-model";
import { SiteSupplierDocumentVersionStatus } from "../../../enums/site-supplier-document-status";
import { SiteSupplierDocumentVersionStatusPipe } from 'src/app/pipes/site-supplier-document-version-status.pipe';
import { SiteBriefingStatus } from 'src/app/enums/site-briefing-status';
import { FormService } from 'src/app/services/form.service';
import { WorkflowType } from 'src/app/enums/workflow/workflow-type';
import { WorkflowService } from 'src/app/services/workflow.service';
import { DocumentKindService } from 'src/app/services/document-kind.service';
import { SupplierDocumentKindViewModel } from 'src/app/models/supplier-document/supplier-document-kind-viewmodel';
import { CompanyAuthService } from 'src/app/services/company-auth.service';
import { SiteSupplierService } from 'src/app/services/site-supplier.service';

@Component({
  selector: 'app-popover-filter-simple',
  templateUrl: './popover-filter-simple.component.html',
  providers: [MobilePipe, DatetimePipe, DatePipe, SiteSupplierDocumentVersionStatusPipe],
  styleUrls: ['./popover-filter-simple.component.scss']
})
export class PopoverFilterSimpleComponent implements OnInit, AfterViewInit {
  id: number;
  DatetimePickerMode = DatetimePickerMode;
  FilterType = FilterType;
  @Input() public items = [];
  @Input() type: FilterType;
  @Input() Ref: any;
  @Input() addButtonLabel: string = 'Add To Filter(s)';
  @Input() inventoryLocationSelectorCanAddNewLocation: boolean;
  @Input() formType: FormType;
  SiteActiveStatus = SiteActiveStatus;
  @Input() SiteViewPermissions: UserPermission = UserPermission.ViewReports;
  @Input() loadForExternalCompany: boolean = false;
  @Input() fromDate: Date | undefined = null;
  @Input() toDate: Date | undefined = null;
  @Input() siteId: number | undefined = null;

  @ViewChild("refUserAutocomplete") public refUserAutocomplete: any;
  @ViewChild("dt1") public dt1: InputDateTimePickerComponent;
  @ViewChild("dt2") public dt2: InputDateTimePickerComponent;
  @ViewChild("dt3") public dt3: InputDateTimePickerComponent;
  @ViewChild("dt4") public dt4: InputDateTimePickerComponent;

  @ViewChild("formSelector") public formSelector: FormSelectorComponent;
  @ViewChild('sl') siteSelector: SiteSelectorComponent
  @ViewChild('user') userSelector: UserAutocompleteComponent
  @Output() self = new EventEmitter<PopoverFilterSimpleComponent>();

  visitorTypes: VisitorType[];
  industryTypes: IndustryTypeViewModel[];
  inventoryCategories: ToolCategoryViewModel[];
  inventoryToolStatuses: ToolStatusViewModel[];
  userDocumentTypes: UserDocumentType[];
  supplierFormControl = new FormControl();
  assetCategoryFormControl = new FormControl();
  documentTypeFormControl = new FormControl();
  inductionFormControl = new FormControl();
  showDateTime: boolean = false;
  documentExpiryStatusEnum = DocumentExpiryStatus;
  approvalStatusEnum = PendingToApproveStatus;
  SiteSupplierDocumentVersionStatus = SiteSupplierDocumentVersionStatus;
  approveEntityTypeEnum = ApproveEntityType;
  siteAssetQueryModeEnum = SiteAssetQueryMode;
  assetCategoryTypeEnum = AssetCategoryType;
  SiteBriefingStatus = SiteBriefingStatus;
  WorkflowType = WorkflowType;
  hashlink: string = null;
  localCompanyId: number = null;
  documentKindList: SupplierDocumentKindViewModel[];

  defaultDateRanges: FilterDateRangeModel[] = [
    { label: 'Today', from: moment().startOf('day').toDate(), to: moment().toDate(), key: DefaultDateRange.Today },
    {
      label: 'Yesterday',
      from: moment().add(-1, 'day').startOf('day').toDate(),
      to: moment().startOf('day').toDate(),
      key: DefaultDateRange.Yesterday
    },
    {
      label: 'Last Week',
      from: moment().add(-1, 'week').startOf('day').toDate(),
      to: moment().toDate(),
      key: DefaultDateRange.LastWeek
    },
    {
      label: 'This Week',
      from: moment().startOf('week').toDate(),
      to: moment().toDate(),
      key: DefaultDateRange.ThisWeek
    },
    {
      label: 'Last Month',
      from: moment().add(-1, 'month').toDate(),
      to: moment().toDate(),
      key: DefaultDateRange.LastMonth
    },
    {
      label: 'This Month',
      from: moment().startOf('month').toDate(),
      to: moment().toDate(),
      key: DefaultDateRange.ThisMonth
    },
  ];

  defaultGenders: FilteGenderModel[] = [
    { label: "Male", key: Gender.male },
    { label: "Female", key: Gender.female },
    { label: "Other", key: Gender.other },
  ]

  defaultInductionStatuses: any[];

  defaultFormType: any[];
  permitStatusTypes: any[];

  filterUserBy = UserAutoCompleteFilterByFields;
  formControl = new FormControl();
  dateFormGroup = new FormGroup({
    from: new FormControl(null, []),
    to: new FormControl(null, []),
  })
  @Output() update = new EventEmitter();

  constructor(
    public cacheService: CacheService,
    private mobilePipe: MobilePipe,
    private datetimePipe: DatetimePipe,
    private datePipe: DatePipe,
    public inventoryService: InventoryService,
    private formTypePipe: FormTypePipe,
    private statusPipe: PermitStatusPipe,
    private siteInductionStatus: SiteInductionStatusPipe,
    private replaySubjectsService: ReplaySubjectsService,
    private formService: FormService,
    private workflowService: WorkflowService,
    private documentKindService: DocumentKindService) {
    this.id = Math.round(Math.random() * 1000);
  }

  ngAfterViewInit(): void {
    this.self.emit(this);
    if (this.fromDate) {
      this.dateFormGroup.controls['from'].setValue(this.fromDate);
    }
    if (this.toDate) {
      this.dateFormGroup.controls['to'].setValue(this.toDate);
    }
  }

  ngOnInit() {
    if (this.type == FilterType.VisitorType)
      this.cacheService.visitorTypes.subscribe(data => {
        this.visitorTypes = data;
      });
    else if (this.type == FilterType.IndustryType)
      this.cacheService.industryTypes.subscribe(data => {
        this.industryTypes = data;
      });
    else if (this.type == FilterType.InventoryCategory)
      this.inventoryService.inventoryCategories.subscribe(data => {
        this.inventoryCategories = data;
      });
    else if (this.type == FilterType.InventoryToolStatus)
      this.inventoryService.inventoryToolStatuses.subscribe(data => {
        this.inventoryToolStatuses = data;
      });
    else if (this.type == FilterType.DateTime) {
      this.defaultDateRanges = [];
      this.defaultDateRanges = [{ label: 'Now', from: moment().toDate(), to: moment().toDate(), key: DefaultDateRange.Today }];
    }
    else if (this.type == FilterType.Site) {
      this.siteId = this.items ? this.items[0] : this.siteId;
    }
    else if (this.type == FilterType.SingleText)
      this.formControl.setValue(this.items?.length > 0 ? this.items[0] : null);

    this.defaultFormType = this.formService.getActiveFormTypes().map(e => { return { label: this.formTypePipe.transform(e), key: e, type: FilterType.FormType }; });
    this.permitStatusTypes = EnumToArray(PermitStatus).map(e => { return { label: this.statusPipe.transform(+e.id), key: e.id, type: FilterType.PermitStatus }; });
    this.defaultInductionStatuses = EnumToArray(SiteInductionStatus).map(e => { return { label: this.siteInductionStatus.transform(e.id), key: e.id, type: FilterType.Induction }; });

    this.getDocumentKindList();
  }

  handleAddOrRemove(item, replace: boolean = false, byKey: boolean = false) {
    if (this.contain(item)) byKey ? this.onRemoveByKey(item) : this.onRemove(item)
    else this.onAdd(item, replace)
  }
  handleAddOrRemoveDateTime(item, replace: boolean = false, byKey: boolean = false) {
    if (this.contain(item)) byKey ? this.onRemoveByKey(item) : this.onRemove(item)
    else this.onAdd(item, replace)
    this.showDateTime = true;
  }
  handleAddOrRemoveDocType(item) {
    let indexOfItem = this.items.indexOf(item);
    if (indexOfItem == -1) {
      this.items.push(item);
      this.update.emit(this.items);
    }
    else {
      this.items.splice(indexOfItem, 1);
      this.update.emit(this.items);
    }
  }
  onAdd(item, replace: boolean = false) {
    if (this.type == FilterType.Induction) {
      item.key = +item.key;
    }
    this.showDateTime = false;
    if (item == undefined) return;
    if (this.type == FilterType.User && !item.id) return;
    if (this.type == FilterType.Site && this.items.find(x => x.id == item.id)) return;
    if (this.type == FilterType.Form && this.items.find(x => x.id == item.id)) return;
    if (this.type == FilterType.Text && this.items.find(x => x == item)) return;
    if (this.type == FilterType.SingleText && this.items.find(x => x == item)) return;
    if (this.type == FilterType.InventoryLocation && this.items.find(x => x.id == item.id)) return;
    if (this.type == FilterType.DocumentType && this.items.find(x => x.id == item.id)) return;
    if (this.type == FilterType.Induction && this.items.find(x => x.key == item.key)) return;
    if (this.type == FilterType.InductionId && this.items.find(x => x.id == item.id)) return;
    // if (this.type == FilterType.ApprovalStatus && this.items.find(x => x == item)) return;
    // if (this.type == FilterType.ApproveEntityType && this.items.find(x => x == item)) return;

    if (this.type == FilterType.DateRange || this.type == FilterType.DateTimeRange || this.type == FilterType.DateTime) {
      if (this.invalidDate(item.from, item.to))
        return;
    }


    if (replace)
      this.items = [item];
    else if (!replace)
      this.items.push(item);

    this.update.emit(this.items);

    if (this.type == FilterType.User) this.refUserAutocomplete.searchTerm = null;
    if (this.type == FilterType.Text) this.formControl.setValue(null);

    if (this.type == FilterType.Form) {
      this.formSelector.clearInput();
    }
    if (this.type == FilterType.Site) {
      this.siteSelector.clearInput();
    }
    if (this.type == FilterType.DateRange || this.type == FilterType.DateTimeRange || this.type == FilterType.DateTime) {
      this.dateFormGroup.controls['from'].setValue(null)
      this.dateFormGroup.controls['to'].setValue(null)
    }
    if (this.type == FilterType.User) {
      this.userSelector.reset();
    }
  }

  invalidDate(from, to, checkEmptiness: boolean = true,) {
    if (checkEmptiness)
      if ((!from && !to) || (from == 'Invalid Date' || to == 'Invalid Date'))
        return true;
    if (from && to) {
      return from > to;
    }
    return false;
  }

  onRemove(item, type?: FilterType) {
    var index = this.items.indexOf(item);
    this.items.splice(index, 1);
    if (type === FilterType.DateTimeRange) {
      this.fromDate = null;
      this.dateFormGroup.controls['from'].setValue(this.fromDate);
      this.toDate = null;
      this.dateFormGroup.controls['to'].setValue(this.fromDate);
    }

    if (type == FilterType.InductionId) {
      this.replaySubjectsService.inductionFilterItemsObs?.next(this.items)
    }

    this.update.emit(this.items);
  }

  onRemoveByKey(item) {
    var oitem = this.items.find((x) => x.key == item.key);
    var index = this.items.indexOf(oitem);
    this.items.splice(index, 1);
    this.update.emit(this.items);
  }

  getFilterDurationRange(from: number, to: number) {
    return { from: from, to: to } as DurationRange;
  }

  getFilterDateRange(from: Date, to?: Date, label?: string, key?: number) {
    return { from: from, to: to, label: label, key: key } as FilterDateRangeModel;
  }

  contain(item) {
    if (this.type == FilterType.VisitorType ||
      this.type == FilterType.IndustryType ||
      this.type == FilterType.User ||
      this.type == FilterType.Site ||
      this.type == FilterType.Form ||
      this.type == FilterType.InventoryCategory ||
      this.type == FilterType.InventoryToolStatus ||
      this.type == FilterType.InventoryLocation
    ) {
      return this.items.find(x => x.id == item.id);
    } else if (this.type == FilterType.DateTimeRange ||
      this.type == FilterType.DateRange ||
      this.type == FilterType.Gender ||
      this.type == FilterType.FormType ||
      this.type == FilterType.PermitStatus ||
      this.type == FilterType.DateTime) {
      let res = this.items?.find(x => x.key === item.key);
      return res;
    }
    else if (this.type == FilterType.Induction) {
      let res = this.items?.find(x => x.key === +item.key);
      return res;
    }
    return this.items?.indexOf(item) != -1;
  }

  getFilterText(item, type: FilterType) {
    switch (type) {
      case FilterType.Text: {
        return item;
      }
      case FilterType.SingleText: {
        return item;
      }
      case FilterType.Site: {
        return item.name;
      }
      case FilterType.Form: {
        return item.name;
      }
      case FilterType.VisitorType: {
        return item.title;
      }
      case FilterType.User: {
        return `${item.firstName} ${item.lastName} (${this.mobilePipe.transform(item.mobile)})`;
      }
      case FilterType.DateRange: {
        return `${item.from ? this.datePipe.transform(item.from) : "-"} - ${item.to ? this.datePipe.transform(item.to) : "Now"}`;
      }
      case FilterType.DateTimeRange: {
        if (item.label)
          return item.label;
        else
          return `${item.from ? this.datetimePipe.transform(item.from) : "-"} - ${item.to ? this.datetimePipe.transform(item.to) : "Now"}`;
      }
      case FilterType.DateTime: {
        return `${item.from ? this.datetimePipe.transform(item.from) : "-"}`;
      }
      case FilterType.Supplier: {
        return item.name;
      }
      case FilterType.AssetCategory: {
        return item.name;
      }
      case FilterType.Induction: {
        return item.label;
      }
      case FilterType.Gender: {
        return item.label;
      }
      case FilterType.IndustryType: {
        return item.type;
      }

      case FilterType.Duration: {

        if (!item.from && item.to)
          return `Lower than ${this.getHourMinutesString(item.to)}`;
        if (item.from && !item.to)
          return `Greater than ${this.getHourMinutesString(item.from)}`;
        if (item.to && item.from)
          return `Between ${this.getHourMinutesString(item.from)} to ${this.getHourMinutesString(item.to)}`;
        else
          return "All duration";
      }
      case FilterType.InventoryCategory: {
        return item.title;
      }
      case FilterType.InventoryToolStatus: {
        return item.title;
      }
      case FilterType.InventoryLocation: {
        return item.name;
      }
      case FilterType.FormType: {
        return item.label;
      }
      case this.FilterType.InductionId: {
        return `${item.title}`;
      }
      case FilterType.PermitStatus: {
        return item.label;
      }
    }
  }

  isRenderableAsSelectedItem(item, type: FilterType) {
    if (type == FilterType.DateTimeRange || type == FilterType.DateTime) {
      if (this.defaultDateRanges.some(x => x == item)) return false;
    } else if (type == FilterType.Gender ||
      type == FilterType.VisitorType ||
      type == FilterType.IndustryType ||
      type == FilterType.Induction ||
      type == FilterType.InventoryCategory ||
      type == FilterType.InventoryToolStatus ||
      type == FilterType.InventoryLocation ||
      type == FilterType.FormType) {
      return false;
    }
    return true;
  }

  getHourMinutesString(totalMinutes: number): String {
    let hour = Math.floor(totalMinutes / 60);
    let minute = (totalMinutes % 60).toString();
    minute = new Array(2 - minute.length + 1).join('0') + minute;
    return `${hour}:${minute}`;
  }

  selectedLocationToAdd: InventoryLocationViewModel;
  saveSelectedLocation(value) {
    this.selectedLocationToAdd = value;
  }

  enabledWorkflows(workflowType: WorkflowType) {
    return this.workflowService.getWorkflowListFull(workflowType);
  }

  getDocumentKindList() {
    return this.documentKindService.list()
      .subscribe(res => this.documentKindList = res);
  }
}
