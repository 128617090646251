import { ApproveEntityTypePipe } from '../../pipes/approve-entity-type.pipe';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { FilterList } from 'src/app/models/filter-list';
import { ApproveStatusPipe } from 'src/app/pipes/approve-status.pipe';
import { DatetimePipe } from 'src/app/pipes/datetime.pipe';
import { MobilePipe } from 'src/app/pipes/mobile.pipe';
import { DocumentExpiryStatusPipe } from "../../pipes/document-expiry-status.pipe";
import { AssetCategoryTypePipe } from 'src/app/pipes/asset-category-type-pipe';
import { SiteAssetQueryModePipe } from 'src/app/pipes/site-asset-query-mode.pipe';
import { SiteSupplierDocumentVersionStatusPipe } from 'src/app/pipes/site-supplier-document-version-status.pipe';
import { SiteBriefingStatusPipe } from "../../pipes/site-briefing-status.pipe";
import { Observable, of } from 'rxjs';
import { DocumentKindNamePipe } from 'src/app/pipes/document-kind.pipe';

@Component({
  selector: 'app-filter-list-renderer',
  templateUrl: './filter-list-renderer.component.html',
  styleUrls: ['./filter-list-renderer.component.scss'],
  providers: [MobilePipe, DatetimePipe, DatePipe, SiteSupplierDocumentVersionStatusPipe, SiteBriefingStatusPipe]
})
export class FilterListRendererComponent implements OnInit {
  @Input() filters: FilterList[];
  @Output() remove = new EventEmitter<{ item: any, items: any[] }>();

  constructor(
    private mobilePipe: MobilePipe,
    private datetimePipe: DatetimePipe,
    private siteSupplierDocumentVersionStatusPipe: SiteSupplierDocumentVersionStatusPipe,
    private datePipe: DatePipe,
    private siteBriefingStatusPipe: SiteBriefingStatusPipe,
    private documentKindNamePipe: DocumentKindNamePipe
  ) { }

  ngOnInit() { }

  getFilterData(filter: FilterList) {
    if (Array.isArray(filter.data))
      if (Array.isArray(filter.data))
        return filter.data;
      else
        return [filter.data];
  }

  getFilterText(item, type: FilterType, filter: any = null): Observable<string> {
    switch (type) {
      case FilterType.Text: {
        return of(item);
      }
      case FilterType.SingleText: {
        return of(item);
      }
      case FilterType.Site: {
        return of(item.name);
      }
      case FilterType.Form: {
        return of(item.name);
      }
      case FilterType.PermitForm: {
        return of(item.name);
      }
      case FilterType.VisitorType: {
        return of(item.title);
      }
      case FilterType.User: {
        return of(`${item.firstName} ${item.lastName} <br> (${this.mobilePipe.transform(item.mobile)})`);
      }
      case FilterType.DateRange: {
        return of(`${item.from ? this.datePipe.transform(item.from) : "-"} <i class="fas fa-arrow-right"></i> ${item.to ? this.datePipe.transform(item.to) : (filter?.nowDateLabel ? filter.nowDateLabel : "Now")}`);
      }
      case FilterType.DateTimeRange: {
        let date = `${item.from ? this.datetimePipe.transform(item.from) : "-"} - ${item.to ? this.datetimePipe.transform(item.to) : (filter?.nowDateLabel ? filter.nowDateLabel : "Now")}`;
        if (item.label)
          return of(`${item.label} <br> ${date}`);
        else
          return of(date);
      }
      case FilterType.DateTime: {
        let date = `${item.from ? this.datetimePipe.transform(item.from) : "-"}`;
        if (item.label)
          return of(`${item.label} <br> ${date}`);
        else
          return of(date);
      }
      case FilterType.Gender: {
        return of(item.label);
      }
      case FilterType.IndustryType: {
        return of(item.type);
      }
      case FilterType.Supplier: {
        return of(item.name);
      }
      case FilterType.Induction: {
        return of(item.label);
      }
      case FilterType.Duration: {

        if (!item.from && item.to)
          return of(`Lower than ${this.getHourMinutesString(item.to)}`);
        if (item.from && !item.to)
          return of(`Greater than ${this.getHourMinutesString(item.from)}`);
        if (item.to && item.from)
          return of(`Between ${this.getHourMinutesString(item.from)} to ${this.getHourMinutesString(item.to)}`);
        else
          return of("All duration");
      }
      case FilterType.InventoryCategory: {
        return of(item.title);
      }
      case FilterType.InventoryToolStatus: {
        return of(item.title);
      }
      case FilterType.InventoryLocation: {
        return of(item.name);
      }
      case FilterType.FormType: {
        return of(item.label);
      }
      case FilterType.InductionId: {
        return of(`${item.title}`);
      }
      case FilterType.PermitStatus: {
        return of(item.label);
      }
      case FilterType.DocumentExpiryStatus: {
        return of(new DocumentExpiryStatusPipe().transform(item));
      }
      case FilterType.AssetCategoryType: {
        return of(new AssetCategoryTypePipe().transform(item));
      }
      case FilterType.AssetStatus: {
        return of(new SiteAssetQueryModePipe().transform(item));
      }
      case FilterType.ApprovalStatus: {
        return of(new ApproveStatusPipe().transform(item));
      }
      case FilterType.ApproveEntityType: {
        return of(new ApproveEntityTypePipe().transform(item));
      }
      case FilterType.DocumentType: {
        return of(item.title);
      }
      // case FilterType.SiteSupplierDocumentWorkflow: {
      //   return this.siteSupplierDocumentKindTitlePipe.transform(item);
      // }
      case FilterType.SupplierDocumentKind: {
        return this.documentKindNamePipe.transform(item);
      }
      case FilterType.SiteSupplierDocumentVersionStatus: {
        return of(this.siteSupplierDocumentVersionStatusPipe?.transform(item));
      }
      case FilterType.SiteBriefingStatus: {
        return of(this.siteBriefingStatusPipe?.transform(item));
      }
      case FilterType.SiteBriefingForm: {
        return of(item.name);
      }
    }
  }

  onRemoveFromFilter(item: any, items: any[]) {
    this.remove.emit({ item: item, items: items })
  }

  getHourMinutesString(totalMinutes: number): String {
    let hour = Math.floor(totalMinutes / 60);
    let minute = (totalMinutes % 60).toString();
    minute = new Array(2 - minute.length + 1).join('0') + minute;
    return `${hour}:${minute}`;
  }
}
