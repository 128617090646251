import { Component, Input, OnInit } from '@angular/core';
import { EmergencyContactViewModel } from 'src/app/models/emergency-contact-view-model';

@Component({
  selector: 'obc-emergency-contact-viewer',
  templateUrl: './emergency-contact-viewer.component.html',
  styleUrls: ['./emergency-contact-viewer.component.scss']
})
export class EmergencyContactViewerComponent implements OnInit {

  @Input() data: string;
  dataObject: EmergencyContactViewModel;
  constructor() { }

  ngOnInit(): void {
    if(this.data != null) {
      try {
        this.dataObject = JSON.parse(this.data);
      } catch(_) {
        this.dataObject = EmergencyContactViewModel.newEmptyAnswer();
      }
    }
  }

}
