import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FormViewModel, FormViewModelRequest } from '../models/form/form-view-model';
import { ServerResultData } from '../models/server-result-data';
import { SiteAssetCheckInOutModel } from '../models/site-asset/site-asset-check-in-out-model';
import { SiteAssetCheckInViewModel } from '../models/site-asset/site-asset-checkin-view-model';
import { SiteAssetRegistrationModel } from '../models/site-asset/site-asset-registration-model';
import {
  CompanyAssetConfiguration, CompanyAssetConfigurationQueryViewModel,
  ManageSiteAssetResponse,
  NicknameModel,
  SiteAssetConfigurationQueryViewModel,
  SiteAssetOperatorModel,
} from '../models/site-asset/site-asset-view-model';
import {
  SiteSupplierSharedAssetsQueryModel
} from '../models/site-asset/site-registered-assets-query-model';
import { ErrorService } from './error.service';
import { AssetCheckInHistoryModel } from "../models/site-asset/asset-check-in-history-model";
import { FormDataDetailViewModel } from '../models/form/form-data-detail-view-model';
import { SearchAssetToRegisterModel } from '../models/inventory/search-tools-query-model';
import { GlobalViewModel } from '../models/global-viewmodel';
import { SiteAssetOperationModel } from "../models/site-asset/site-asset-operation-model";
import { UserSearchModel } from '../models/user-search-model';
import { UserBasicInfoViewModel } from '../models/user-basic-info-viewmodel';


@Injectable({
  providedIn: 'root'
})
export class SiteAssetService {

  apiUrl: string = "/api/SiteAsset";
  anonymousApiUrl: string = "/api/AnonymousSiteAsset";
  constructor(private http: HttpClient, private _errorService: ErrorService) {
  }

  getSiteAssetsConfiguration(model: SiteAssetConfigurationQueryViewModel): Observable<CompanyAssetConfiguration> {
    return this.http.post<CompanyAssetConfiguration>(`${this.apiUrl}/site-assets-configuration`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  getCompanyAssetsConfiguration(model: CompanyAssetConfigurationQueryViewModel): Observable<CompanyAssetConfiguration> {
    return this.http.post<CompanyAssetConfiguration>(`${this.apiUrl}/company-assets-configuration`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  registerSiteAsset(model: SiteAssetRegistrationModel, hashLink: string = ""): Observable<ServerResultData<number>> {
    let headers = new HttpHeaders({ "hash-link": hashLink ?? '' });
    return this.http.post<boolean>(`${hashLink?.length ? this.anonymousApiUrl : this.apiUrl}/register-site-asset`, model, { headers: headers })
      .pipe(catchError(this._errorService.handleError));
  }

  registerAssetOperator(model: SiteAssetOperatorModel, hashLink: string = null) {
    if (hashLink) {
      let headers = new HttpHeaders({ "hash-link": hashLink ?? '' });
      return this.http
        .post<boolean>(`${this.anonymousApiUrl}/register-asset-operator`, model, { headers: headers })
        .pipe(catchError(this._errorService.handleError));
    }
    else {
      return this.http.post<boolean>(`${this.apiUrl}/register-asset-operator`, model)
        .pipe(catchError(this._errorService.handleError));
    }
  }

  submitAssetOperation(model: SiteAssetOperationModel, siteAssetId: number) {
    return this.http.post<boolean>(`${this.apiUrl}/submit-asset-operation/${siteAssetId}`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  checkInAsset(siteAssetId: number, model: SiteAssetCheckInOutModel): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}/check-in-asset/${siteAssetId}`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  checkOutAsset(siteAssetId: number, model: SiteAssetCheckInOutModel): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}/check-out-asset/${siteAssetId}`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  getAssetCheckinHistory(model: AssetCheckInHistoryModel): Observable<SiteAssetCheckInViewModel[]> {
    return this.http.post<SiteAssetCheckInViewModel[]>(`${this.apiUrl}/get-asset-check-in-history`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  getAssetRegistrationForms(siteId: number, hashLink: string = null) {
    let headers = new HttpHeaders({ "hash-link": hashLink ?? '' });
    return this.http.get<ServerResultData<FormViewModel[]>>(`${this.apiUrl}/asset-registration-forms/${siteId}`, { headers: headers })
      .pipe(catchError(this._errorService.handleError));
  }

  getFormViewmodel(formId: number, hashLink: string = null, toolId: number, siteId: number = null) {
    let headers = new HttpHeaders({ "hash-link": hashLink ?? '' });
    return this.http.post<ServerResultData<FormViewModel[]>>(`${this.anonymousApiUrl}/form`, { formId: formId, siteId: siteId, toolId: toolId } as FormViewModelRequest, { headers: headers })
      .pipe(catchError(this._errorService.handleError));
  }

  getAnonymousFilledForm(formId: number, hashLink: string): Observable<ServerResultData<FormDataDetailViewModel>> {
    let headers = new HttpHeaders({ "hash-link": hashLink ?? '' });
    return this.http.get<ServerResultData<FormDataDetailViewModel>>(`${this.anonymousApiUrl}/get-filled-form/${formId}`, { headers: headers })
      .pipe(catchError(this._errorService.handleError));
  }
  searchToolsToRegister(model: SearchAssetToRegisterModel) {
    return this.http.post<GlobalViewModel[]>(`${this.apiUrl}/search-assets-to-register`, model)
      .pipe(catchError(this._errorService.handleError));

  }

  setNickname(model: NicknameModel): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}/set-nickname`, model);
  }

  searchUser(model: UserSearchModel): Observable<UserBasicInfoViewModel[]> {
    let headers = new HttpHeaders({ "hash-link": model.hashLink ?? '' });
    return this.http.post<UserBasicInfoViewModel[]>(`${this.anonymousApiUrl}/seacrh-company-users`, model, { headers: headers });
  }
}
