import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';
import { catchError, tap } from 'rxjs/operators';
import {
  CloneInductionQueryModel,
  InductionModel,
  InductionViewModel,
  SiteRequiredInductionViewModel
} from '../models/induction-viewmodel';
import { CompanySiteScope } from '../enums/company-site-scope';
import { ServerResultData } from '../models/server-result-data';
import { SiteInductionFullInfoViewModel } from '../models/induction/site-induction-full-info-view-model';
import { PagingViewModel } from '../models/site-list-paging-view-model';
import { InductionReportRequestModel } from '../models/induction/induction-report-request-model';
import { SiteInductionFilledInfoViewModel } from '../models/induction/site-induction-filled-info-view-model';
import { Orderable } from "../models/orderable-model";
import { convertObjectToUrlParameters } from '../helpers/extract-params-from-model';
import { InductionMode } from '../enums/site-induction-mode.enum';
import { ResetEntityStatusQueryModel } from '../models/approve/reset-entity-status-query-model';
import { SiteAttendanceInductionViewModel } from '../models/site-attendance-induction-view-model';


@Injectable({
  providedIn: 'root'
})
export class InductionService {

  private inductionApiRoot = '/api/Induction'
  cachedInductions: InductionViewModel[] = null;

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }

  getInductions = (scope?: CompanySiteScope, ...mode: InductionMode[]): Observable<InductionViewModel[]> =>
    this._http.get<InductionViewModel[]>(`${this.inductionApiRoot}/get-all?scope=${scope ? (+scope) : ''}${mode && mode.length>0? `&${mode.map(m=>`mode=${m}`).join('&')}`:''}`)
      .pipe(catchError(this._errorService.handleError));

  getCachedInductions(): Observable<InductionViewModel[]> {
    if (this.cachedInductions)
      return of(this.cachedInductions);
    else
      return this.getInductions().pipe(tap((res) => this.cachedInductions = res))
  }

  addInduction = (model: InductionModel): Observable<any> =>
    this._http.post<any>(`${this.inductionApiRoot}/add`, model)
      .pipe(catchError(this._errorService.handleError));

  previewInduction = (model: InductionModel): Observable<ServerResultData<SiteAttendanceInductionViewModel>> =>
      this._http.post<any>(`${this.inductionApiRoot}/preview`, model)
        .pipe(catchError(this._errorService.handleError));

  editInduction(inductionId: number, induction: InductionModel): Observable<ServerResultData<boolean>> {
    return this._http.post<ServerResultData<boolean>>(`${this.inductionApiRoot}/edit/${inductionId}`, induction)
      .pipe(catchError(this._errorService.handleError));
  }

  cloneInduction = (model: CloneInductionQueryModel): Observable<any> =>
    this._http.post<any>(`${this.inductionApiRoot}/clone`, model)
      .pipe(catchError(this._errorService.handleError));

  removeInduction(induction: InductionViewModel): Observable<ServerResultData<boolean>> {
    return this._http.post<ServerResultData<boolean>>(`${this.inductionApiRoot}/remove`, induction)
      .pipe(catchError(this._errorService.handleError));
  }

  changeItemsOrder = (id: any, items: Orderable[]) =>
    this._http.post<SiteRequiredInductionViewModel>(`/api/Induction/change-order/${id}`, items);

  getSiteInductionsReport(model: InductionReportRequestModel): Observable<ServerResultData<PagingViewModel<SiteInductionFullInfoViewModel>>> {
    return this._http.post<ServerResultData<PagingViewModel<SiteInductionFullInfoViewModel>>>(`/api/Induction/site-induction-report`, model)
      .pipe(catchError(this._errorService.handleError));
  }
  getSiteInductionFilledInfo(siteInductionId: number): Observable<ServerResultData<SiteInductionFilledInfoViewModel>> {
    return this._http.get<ServerResultData<SiteInductionFilledInfoViewModel>>(`/api/Induction/site-induction-filled-info/${siteInductionId}`)
      .pipe(catchError(this._errorService.handleError));
  }
  exportSiteInductionsReportAsCsv(model: InductionReportRequestModel) {
    const params = convertObjectToUrlParameters(model);
    return window.open(`/api/Induction/site-induction-report-csv/?` + params);
  }

  resetInductionStatus(model: ResetEntityStatusQueryModel): Observable<boolean> {
    return this._http.post<boolean>(`/api/Induction/induction-status-reset`, model);
  }

}
