import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, finalize } from 'rxjs';
import { GodUserPermission } from 'src/app/enums/god-user-permission.enum';
import { OptionalMandatoryState } from 'src/app/enums/site-document-state.enum';
import { SiteSupplierAccessMode } from 'src/app/enums/site-supplier-access-mode.enum';
import { SupplierDocumentReviewMode } from 'src/app/enums/supplier-document-review-mode.enum';
import { ApproveDeclineSiteSupplierDocumentVersion, ArchiveSiteSupplierDocumentTypeModel, DeleteSiteSupplierDocumentVersion } from 'src/app/models/supplier-document/site-supplier-document-type-models';
import { SupplierDocumentModel } from 'src/app/models/supplier-document/site-supplier-document-type-view-model.model';
import { SupplierDocumentTypeViewModel } from 'src/app/models/supplier-document/supplier-document-models';
import { ModalService } from 'src/app/services/modal.service';
import { SiteSupplierService } from 'src/app/services/site-supplier.service';
import { SupplierDocumentService } from 'src/app/services/supplier-document.service';
import { UserService } from 'src/app/services/user.service';
import { ISiteSupplierDocumentTypeVersion } from "../../../models/supplier-document/site-supplier-document-type";
import { SupplierDocumentWorkflowConstant } from 'src/app/enums/workflow/workflow-activities-enum';
import { ShowSupplierDocumentReviewButton } from 'src/app/enums/show-supplier-document-review-button';
import { SiteSupplierDocumentListenerService } from '../site-supplier-document-listener.service';
import { SiteSupplierDocumentListenerModel } from '../site-supplier-document-listener-model';
import { SupplierDocumentTypeOperation } from 'src/app/enums/supplier-document-type-operation-kind.enum';
import { ObcWorkflowStatus } from 'src/app/enums/workflow/workflow-status.enum';
import { SiteSupplierDocumentStatus } from 'src/app/enums/site-supplier-document-status';
import { ClientWorkflowDefinitionViewModel } from 'src/app/models/workflow/workflow-definition-view-model';
import { WorkflowService } from 'src/app/services/workflow.service';
import { WorkflowType } from 'src/app/enums/workflow/workflow-type';
import { PortalDisplayMode } from 'src/app/enums/portal-display-mode.enum';
import { Router } from '@angular/router';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { FilterList } from 'src/app/models/filter-list';
import { PagingRequest } from 'src/app/models/paging-request-model';
import { SiteSupplierDocumentVersionCommentViewModel } from 'src/app/models/supplier-document-version-comment-model';
import { SupplierDocumentDataModel, SupplierDocumentVersionViewModel, SupplierDocumentVersionsQueryModel } from 'src/app/models/supplier-document-versions-query-model';
import { SiteSupplierInfo } from 'src/app/models/supplier-document/site-supplier-info';
import { VariableValueDefinition } from 'src/app/models/workflow/variable-difinition';
import { DefaultWorkflowDisplayMode } from 'src/app/enums/constants';
import { ApprovalModeEnum } from "../../../enums/approval-mode-enum";
import { FormService } from 'src/app/services/form.service';
import { SignOffMode } from 'src/app/enums/signoff-mode.enum';

@Component({
  selector: 'app-view-site-supplier-document-version',
  templateUrl: './view-site-supplier-document-version.component.html',
  styleUrls: ['./view-site-supplier-document-version.component.scss']
})
export class ViewSiteSupplierDocumentVersionComponent implements OnInit {

  FilterType = FilterType;
  filterDocumentTitles = [];
  filterDocumentKinds: number[] = [];
  filterExpireDateRanges = [];
  filters = [
    { data: this.filterDocumentTitles, name: 'Title', type: FilterType.SingleText, },
    { data: this.filterDocumentKinds, name: 'Document Kind', type: FilterType.SupplierDocumentKind },
    { data: this.filterExpireDateRanges, name: 'Expiration Date', type: FilterType.DateRange, nowDateLabel: '-', }
  ] as FilterList[];

  SiteSupplierDocumentStatus = SiteSupplierDocumentStatus;
  selectedFormDataId: number;
  inProgress = false;
  prefix = null;
  SupplierDocumentReviewModeEnum = SupplierDocumentReviewMode;
  WorkflowStatus = ObcWorkflowStatus;
  SupplierDocumentWorkflowConstant = SupplierDocumentWorkflowConstant;
  ShowSupplierDocumentReviewButton = ShowSupplierDocumentReviewButton;
  showDocumentTypeStatus: boolean = false;
  enableDocumentTypeStatus: boolean = true;
  selectedDocumentKindId: number;
  selectedWorkflowTitle: string = null;
  selectedFilesToSendToModal: any;
  portalDisplayMode: PortalDisplayMode = DefaultWorkflowDisplayMode;
  selectedInstanceId: string;
  siteSupplierInfo: SiteSupplierInfo;
  allowedSupplierDocumentWorkflows: ClientWorkflowDefinitionViewModel[] = [];


  siteSupplierDocumentTypes = new BehaviorSubject<SupplierDocumentVersionViewModel[]>([]);
  selectedDocumentTypes: SupplierDocumentTypeViewModel[];
  supplierDocumentDataModel: SupplierDocumentDataModel;
  selectedVariableList: VariableValueDefinition[];

  selectedSupplierDocumentVersionViewModel: SupplierDocumentVersionViewModel = null;

  ApprovalModeEnum = ApprovalModeEnum;
  dirtyCheckSubscribe;
  selectedFormDirtyState: boolean = false;

  @Input() isArchiveTab: boolean = false;
  @Input() accessMode: SiteSupplierAccessMode;
  @Input() selectedSiteSupplierId: number = null;
  @Input() selectedSiteId: number;
  @Input() selectedSuplierId: number;
  @Input() mainCompanyId?: number = null;

  _siteSupplierHashlink = new BehaviorSubject<string>(null);
  @Input() set siteSupplierHashlink(value: string) {
    if (value != this._siteSupplierHashlink.value && value != null) {
      this._siteSupplierHashlink.next(value);
    }
  }

  get isSupplierUser() {
    return this._siteSupplierHashlink.value?.length > 0 || this.accessMode == SiteSupplierAccessMode.SupplierHost;
  }

  hasDeleteSupplierDocumentsPermission: boolean = false;
  constructor(private siteSupplierService: SiteSupplierService,
    private userService: UserService,
    private modalService: ModalService,
    private bsModalService: BsModalService,
    private supplierDocumentService: SupplierDocumentService,
    private siteSupplierDocumentListenerService: SiteSupplierDocumentListenerService,
    private workflowService: WorkflowService,
    private formService: FormService,
    private router: Router) { }

  getPath(): string {
    return window.location.pathname + window.location.hash;
  }

  ngOnInit(): void {
    this.portalDisplayMode = this.userService.getCompanyWorkflowDisplayMode();

    this.hasDeleteSupplierDocumentsPermission = this.userService.hasGodUserPermissionForCompany(GodUserPermission.DeleteSupplierDocuments);

    setTimeout(() => {
      this.updateSiteSupplier();
    }, 200);

    this.siteSupplierDocumentListenerService.dataChanged
      .subscribe(res => {
        if (res) {
          if (res.operation == SupplierDocumentTypeOperation.ArchiveUnarchive) {
            this.updateSiteSupplier();
          }
        }
      });

    if (this._siteSupplierHashlink.value != null) {
      this.siteSupplierService.getSupplierDocumentTypeInfoByHashLink(this._siteSupplierHashlink.value)
        .subscribe(res => {
          if (res) {
            this.selectedSiteId = res.siteId;
            this.selectedSuplierId = res.supplierId;
            this.selectedSiteSupplierId = res.siteSupplierId;
          }
        })
    }

    if (!this.isArchiveTab) {
      if (!this.mainCompanyId)
        this.mainCompanyId = this.userService.currentCompanyId;

      let hash = this.selectedSiteSupplierId == null && this._siteSupplierHashlink.value != null ? this._siteSupplierHashlink.value : null;

      if (!this.mainCompanyId && !hash) {
        this.modalService.error("Bad Request; hash or id not found!");
        return;
      }

      this.workflowService.getWorkflowListFull(WorkflowType.SupplierDocument).subscribe(res => {
        if (res) {
          this.allowedSupplierDocumentWorkflows = [];
          res.map(p => {
            this.allowedSupplierDocumentWorkflows.push({
              isLegacyMode: p.isLegacyMode,
              title: p.title,
              variableList: p.variableList,
              workflowDocumentKindId: p.workflowSubtype,
              workflowSubtype: p.workflowSubtype,
              workflowType: p.workflowType,
            });
          })
        }
      });
    }
  }

  onUpdateFilter(currentItems: any[], newItems: any[]) {
    currentItems.splice(0, currentItems.length, ...newItems);
    this.updateSiteSupplier();
  }

  filterHasAnyData(): boolean {
    for (let index = 0; index < this.filters.length; index++) {
      const element = this.filters[index];
      if (element?.data?.length > 0) return true;
    }
    return false;
  }

  oldFilterData: any[] = [];
  onRemoveFilter($event) {
    let item = $event.item;
    let items = $event.items;
    let index = items.indexOf(item);

    let oldf = this.oldFilterData.find(x => x.item == item);
    if (oldf) {
      let oldfIndex = this.oldFilterData.indexOf(oldf);
      this.oldFilterData.splice(oldfIndex, 1);
    }

    if (index > -1) {
      items.splice(index, 1);
      this.updateSiteSupplier();
    }
  }

  workflowEngineModal: BsModalRef<unknown>;
  openWorkflowEngine(instanceId: string, modalTemplate: any) {
    this.selectedInstanceId = instanceId;

    if (this.portalDisplayMode == PortalDisplayMode.PopUp) {
      this.formService.isFormDirty.next(false);
      this.workflowEngineModal = this.bsModalService.show(modalTemplate, {
        class: "modal-lg modal-full-width",
        ignoreBackdropClick: true,
        keyboard: false,
      });
      this.dirtyCheckSubscribe = this.formService.isFormDirty.asObservable().subscribe(e => {
        this.selectedFormDirtyState = e;
      });
    }
    else if (this.portalDisplayMode == PortalDisplayMode.Page) {
      this.router.navigate([`/workflow-engine/${this.selectedInstanceId}`], { queryParams: { returnPath: this.getPath() } });
    }
  }


  closeModal() {
    try {
      if (!this.selectedFormDirtyState) {
        this.dirtyCheckSubscribe?.unsubscribe();
        this.workflowEngineModal.hide();
        this.updateSiteSupplier();
      } else {
        this.modalService.confirm('Exit and lose progress?', '', 'Yes, exit', 'No, go back and continue')
          .subscribe(res => {
            if (res == true) {
              this.dirtyCheckSubscribe.next(false);
              this.dirtyCheckSubscribe?.unsubscribe();
              this.workflowEngineModal.hide();
              this.updateSiteSupplier();
            }
          });
      }
    } catch {
    }
  }

  updateSiteSupplier() {
    this.inProgress = true;

    let queryModel = <SupplierDocumentVersionsQueryModel>{
      siteId: this.selectedSiteId,
      documentTitles: this.filterDocumentTitles?.length ? this.filterDocumentTitles : [],
      expireDateRanges: this.filterExpireDateRanges?.length ? this.filterExpireDateRanges[0] : null,
      searchTerm: '',
      siteAttendanceId: null,
      isArchived: this.isArchiveTab,
      supplierId: this.selectedSuplierId,
      siteSupplierId: this.selectedSiteSupplierId,
      documentKindIdList: this.filterDocumentKinds,
    };


    let sub =
      this.isSupplierUser ?
        this.siteSupplierService
          .getSiteSupplierDocumentTypesByHashlinkModel(<PagingRequest<SupplierDocumentVersionsQueryModel>>{ query: queryModel },
            this._siteSupplierHashlink.value) :
        this.selectedSiteSupplierId != null ?
          this.siteSupplierService
            .getSiteSupplierDocumentTypesByModel(<PagingRequest<SupplierDocumentVersionsQueryModel>>{ query: queryModel }) :
          null;

    if (sub == null) {
      this.modalService.error("Bad Request; hash or id not found!");
      return;
    }

    sub.pipe(finalize(() => this.inProgress = false))
      .subscribe(res => {
        this.supplierDocumentDataModel = res;

        setTimeout(() => {
          this.getDocumentType();
        }, 500);

      }, err => {
        this.modalService.error(err);
      })
  }

  getDocumentType() {

    this.hasSupplierReviewForm = this.supplierDocumentDataModel.companyReviewForms?.length > 0;
    this.supplierDocumentReviewMode = this.supplierDocumentDataModel.supplierDocumentReviewMode;
    this.selectedDocumentTypes = this.supplierDocumentDataModel.data.filter(x => {
      return x.supplierDocumentTypeId;
    }).map(x => {
      return { id: x.supplierDocumentTypeId, name: x.supplierDocumentTypeName } as SupplierDocumentTypeViewModel
    });

    this.siteSupplierDocumentTypes.next(this.supplierDocumentDataModel.data);
  }

  onSiteSupplierLoad() {
    this.updateSiteSupplier();
  }

  uploadFileModalRef = null;
  siteSupplierDocumentType = null;
  onUpdateDocuments(template: any, docVersion?: SupplierDocumentVersionViewModel) {
    if (docVersion)
      this.selectedDocumentKindId = docVersion.documentKindId;

    this.selectedVariableList = docVersion.workflowStartVariables;

    this.siteSupplierDocumentType = docVersion;
    this.uploadFileModalRef = this.bsModalService.show(template, {
      class: "modal-lg",
      ignoreBackdropClick: true,
    })
  }

  onUpdateRequestDocuments(template: any, siteSupplierDocumentType?: SupplierDocumentVersionViewModel) {
    this.siteSupplierDocumentType = siteSupplierDocumentType;
    this.uploadFileModalRef = this.bsModalService.show(template, {
      class: "modal-lg",
      ignoreBackdropClick: true,
    })
  }

  onArchiveDocuments() {
    let selectedItems = this.supplierDocumentDataModel.data.filter(d => d.selected);
    if (selectedItems.length <= 0) {
      this.modalService.warn('Please select one or more files', "Archive File");
      return;
    }

    this.bsModalService

    this.modalService.confirm('Are you sure you want to archive this file? Archiving the file will remove it from the induction and remove any pending approvals.', 'Archive Supplier Document File', 'Archive', 'Cancel', 'btn-danger', 'btn-default')
      .subscribe(confirm => {
        if (confirm == true) {
          this.inProgress = true;


          let model = {
            siteSupplierDocumentTypeIds: selectedItems.map((item) => item.siteSupplierDocumentTypeId),
          } as ArchiveSiteSupplierDocumentTypeModel;

          (this._siteSupplierHashlink.value && this._siteSupplierHashlink.value?.length ?
            this.siteSupplierService.archiveSiteSupplierDocumentTypes(model, this._siteSupplierHashlink.value)
            :
            this.supplierDocumentService.archiveSiteSupplierDocumentTypes(model))
            .subscribe(_ => {
              this.inProgress = false;

              this.updateSiteSupplier();
              this.siteSupplierDocumentListenerService.onChangeData(
                <SiteSupplierDocumentListenerModel>{
                  documentKindId: null,
                  operation: SupplierDocumentTypeOperation.ArchiveUnarchive
                });

            }, err => {
              this.inProgress = false;
              this.modalService.error(err, err.body?.length ? err.body : "Unable to archive supplier document types!");
            });
        }
      });
  }

  onUnarchiveDocuments() {
    let selectedItems = this.supplierDocumentDataModel.data.filter(d => d.selected);
    if (selectedItems.length <= 0) {
      this.modalService.warn('Please select one or more files', "Unarchive File");
      return;
    }

    this.modalService.confirm('Are you sure you want to unarchive this file(s)?', 'Archive Supplier Document File', 'Unarchive', 'Cancel', 'btn-danger', 'btn-default')
      .subscribe(confirm => {
        if (confirm == true) {
          this.inProgress = true;
          let model = {
            siteSupplierDocumentTypeIds: selectedItems.map((item) => item.siteSupplierDocumentTypeId),
          } as ArchiveSiteSupplierDocumentTypeModel;

          (this._siteSupplierHashlink.value != null && this._siteSupplierHashlink.value?.length ?
            this.siteSupplierService.unarchiveSiteSupplierDocumentTypes(model, this._siteSupplierHashlink.value)
            :
            this.supplierDocumentService.unarchiveSiteSupplierDocumentTypes(model))
            .subscribe(_ => {
              this.inProgress = false;

              this.updateSiteSupplier();

              this.siteSupplierDocumentListenerService.onChangeData(
                <SiteSupplierDocumentListenerModel>{
                  documentKindId: null,
                  operation: SupplierDocumentTypeOperation.ArchiveUnarchive
                });

            }, err => {
              this.inProgress = false;
              this.modalService.error(err, err.body?.length ? err.body : "Unable to unarchive supplier document types!");
            });
        }
      });
  }

  supplierDocCommentAcknowledgementText: string;
  hasPermissionToAddSupplierDocumentComment: boolean;
  siteSupplierDocumentTypeId: number;
  onViewHistory(template: any, siteSupplierDocumentType: SupplierDocumentVersionViewModel) {
    this.siteSupplierDocumentTypeId = siteSupplierDocumentType.siteSupplierDocumentTypeId;
    this.bsModalService.show(template, {
      class: "modal-lg",
    })
  }

  supplierDocumentVersionComments: SiteSupplierDocumentVersionCommentViewModel[];
  siteSupplierDocumentVersionId: number;
  onViewComments(template: any, siteSupplierDocumentType: SupplierDocumentVersionViewModel) {

    this.siteSupplierService.GetSupplierDocumentVersionComment(siteSupplierDocumentType.siteSupplierDocumentVersionId)
      .subscribe(resComment => {
        if (resComment) {
          this.supplierDocCommentAcknowledgementText = resComment.supplierDocCommentAcknowledgementText;
          this.hasPermissionToAddSupplierDocumentComment = resComment.hasPermissionToAddSupplierDocumentComment;
          this.siteSupplierDocumentVersionId = siteSupplierDocumentType.siteSupplierDocumentVersionId;

          this.supplierDocumentVersionComments = resComment.supplierDocumentVersionComments;

          this.bsModalService.show(template, {
            class: "modal-lg",
          })
        }
      });
  }

  supplierDocumentFormControl = new FormControl();
  onSelectSupplierDocumentType($event) {
    console.log($event);
  }

  onViewFilledForm(template: any, formDataId: number) {
    this.selectedFormDataId = formDataId;
    this.uploadFileModalRef = this.bsModalService.show(template, {
      class: "modal-lg",
      ignoreBackdropClick: false,
    })
  }

  hasSupplierReviewForm: boolean = false;
  supplierDocumentReviewMode: SupplierDocumentReviewMode;

  selectedSiteSupplierDocument: ISiteSupplierDocumentTypeVersion;
  bsModalRef: BsModalRef;
  onOpenformReview(template: any, item: SupplierDocumentVersionViewModel) {
    this.selectedSiteSupplierDocument = {
      files: item.supplierDocumentFiles,
      displayVersion: item.displayVersion,
      lastSupplierVersionId: item.siteSupplierDocumentVersionId,
      supplierDocumentTypeName: item.supplierDocumentTypeName,
      documentKindId: item.documentKindId,
      expirationDate: item.expirationDate
    } as ISiteSupplierDocumentTypeVersion;
    this.formService.isFormDirty.next(false);
    this.bsModalRef = this.bsModalService.show(template, {
      class: "modal-full-screen",
      ignoreBackdropClick: true,
      keyboard: false,
    });
    this.dirtyCheckSubscribe = this.formService.isFormDirty.asObservable().subscribe(e => {
      this.selectedFormDirtyState = e;
    });
  }

  onApproveDeclineSiteSupplierVersion(isApprove: boolean, documentType: SupplierDocumentVersionViewModel) {
    if (!documentType)
      return;
    this.modalService.confirm('Are you sure you want to ' + (isApprove ? 'approve' : 'decline') + ' this version?')
      .subscribe(confirm => {
        if (confirm) {
          this.supplierDocumentService.approveDeclineSiteSupplierVersion({
            isApproved: isApprove,
            siteSupplierDocumentVersionId: documentType.siteSupplierDocumentVersionId,
            expirationDate: documentType.expirationDate
          } as ApproveDeclineSiteSupplierDocumentVersion)
            .subscribe(_ => {

              this.onSiteSupplierLoad();

            }, err => {
              this.modalService.error(err, "Unable to change status");
            });
        }
      });
  }

  onOpenDeleteForm(item: SupplierDocumentVersionViewModel) {
    this.modalService.confirm('Are you sure you want to delete this version?', 'Delete Supplier Document File', 'Delete', 'Cancel', 'btn-danger', 'btn-default')
      .subscribe(res => {
        if (res == true) {
          this.inProgress = true;
          let request = {
            siteSupplierDocumentVersionId: item.siteSupplierDocumentVersionId,
          } as DeleteSiteSupplierDocumentVersion;

          (this._siteSupplierHashlink.value != null && this._siteSupplierHashlink.value?.length ?
            this.siteSupplierService.deleteSiteSupplierDocumentVersion(request, this._siteSupplierHashlink.value)
            :
            this.supplierDocumentService.deleteSiteSupplierDocumentVersion(request))
            .subscribe(_ => {
              this.inProgress = false;

              this.onSiteSupplierLoad();

            }, err => {
              this.inProgress = false;
              this.modalService.error(err, err.body?.length ? err.body : "Unable to delete supplier document version!");
            });
        }
      });
  }

  onDeleteAllFiles(item: SupplierDocumentVersionViewModel) {
    this.modalService.confirm(`Are you sure you want to ${this.getDeleteAllButtonTitle(item.documentKindSignOffMode, true)}?`, 'Delete Supplier Document File', 'Ok', 'Cancel', 'btn-danger', 'btn-default')
      .subscribe(res => {
        if (res == true) {
          this.inProgress = true;

          this.supplierDocumentService.deleteAllSupplierDocuments(item.siteSupplierDocumentTypeId)
            .subscribe(_ => {
              this.inProgress = false;

              this.onSiteSupplierLoad();

            }, err => {
              this.inProgress = false;
              this.modalService.error(err, err.body?.length ? err.body : "Unable to delete supplier documents!");
            });
        }
      });
  }

  refresh() {
    if (!this.selectedFormDirtyState) {
      this.dirtyCheckSubscribe?.unsubscribe();
      this.onSiteSupplierLoad();
      this.bsModalRef?.hide();
    } else {
      this.modalService.confirm('Exit and lose progress?', '', 'Yes, exit', 'No, go back and continue')
        .subscribe(res => {
          if (res == true) {
            this.dirtyCheckSubscribe.next(false);
            this.dirtyCheckSubscribe?.unsubscribe();
            this.onSiteSupplierLoad();
            this.bsModalRef?.hide();
          }
        });
    }
  }

  bsModalRefRenameDOT: any = null;
  renameNoneStandardSupplierDocumentTypeFormControl = new FormControl(null, [Validators.required]);
  selectedSiteSupplierDocumentTypeForRename: SupplierDocumentVersionViewModel = null;
  OptionalMandatoryState = OptionalMandatoryState;
  selectedSiteSupplierDocumentTypeMode: OptionalMandatoryState;

  showSignOffConfiguration(documentKindId: number) {
    return this.supplierDocumentDataModel.supplierDocumentDataConfiguration.find(p => p.documentKindId == documentKindId)?.enableSetSiteSupplierDocumentTypeMode ?? false;
  }

  isSignOffEnabled(documentKindId: number) {
    return this.supplierDocumentDataModel.supplierDocumentDataConfiguration.find(p => p.documentKindId == documentKindId)?.documentKindSignOffMode == SignOffMode.Optional;
  }

  setSignOffVisibility(documentKindId: number) {
    this.showDocumentTypeStatus = this.showSignOffConfiguration(documentKindId);
    this.enableDocumentTypeStatus = this.isSignOffEnabled(documentKindId);
  }

  onEditNoneStandardSupplierDocumentType(docType: SupplierDocumentVersionViewModel, template: any) {
    this.setSignOffVisibility(docType.documentKindId);

    this.renameNoneStandardSupplierDocumentTypeFormControl.setValue(docType.supplierDocumentTypeName);
    this.selectedSiteSupplierDocumentTypeForRename = docType;
    this.selectedSiteSupplierDocumentTypeMode = this.getSignOffModeValue(docType);
    this.bsModalRefRenameDOT = this.bsModalService.show(template, {
      class: "modal-lg",
      ignoreBackdropClick: false,
    })
  }

  getSignOffModeValue(docType: SupplierDocumentVersionViewModel) {
    return this.supplierDocumentDataModel.supplierDocumentDataConfiguration.find(p => p.documentKindId == docType.documentKindId)?.documentKindSignOffMode == SignOffMode.Mandatory ?
      OptionalMandatoryState.Mandatory :
      docType.signOffMode;
  }

  documentTypeStatusChanged(value) {
    this.selectedSiteSupplierDocumentTypeMode = value ? OptionalMandatoryState.Mandatory : OptionalMandatoryState.Optional;
  }

  updateNoneStandardSupplierDocumentType() {
    const model = {
      title: this.selectedSiteSupplierDocumentTypeForRename.isStandardSupplierDocumentType ? '' : this.renameNoneStandardSupplierDocumentTypeFormControl.value,
      siteSupplierId: this.selectedSiteSupplierId,
      siteSupplierDocumentTypeId: this.selectedSiteSupplierDocumentTypeForRename.siteSupplierDocumentTypeId,
      mode: this.selectedSiteSupplierDocumentTypeMode,
    };

    this.supplierDocumentService.renameSiteSupplierDocumentType(model)
      .subscribe(_ => {
        this.bsModalRefRenameDOT.hide();

        this.onSiteSupplierLoad();

      }, err => {
        this.modalService.error(err);
      })
  }

  onRequestNewDocumentType() {
    let typeId = this.supplierDocumentFormControl.value;
    if (!typeId) {
      this.modalService.error("Please select document type");
      return;
    }

    this.supplierDocumentService
      .requestNewSiteSupplierDocumentType(
        this.selectedSiteSupplierId,
        {
          mode: this.selectedSiteSupplierDocumentTypeMode,
          supplierDocumentTypeId: typeId,
          documentKindId: this.selectedDocumentKindId,
        } as SupplierDocumentModel)
      .subscribe(_ => {
        this.onSiteSupplierLoad();

        this.uploadFileModalRef.hide();
      }, err => { this.modalService.error(err); });
  }

  hasAnySelectedToArchive(): boolean {
    return this.supplierDocumentDataModel.data.some(x => x.selected);
  }

  getDeleteAllButtonTitle(documentKindSignOffMode: SignOffMode, isMessage: boolean = false) {
    return (documentKindSignOffMode != SignOffMode.Disable ?
      (isMessage ? 'delete all Document/Versions/Sign Offs' : 'Delete All Document/Versions/Sign Offs') :
      (isMessage ? 'delete all Document/Versions' : 'Delete All Document/Versions'));

  }

  filesDragedOrSelected(files, uploadMultipleDocumentTemplate, documentKindId: number, workflowTitle: string, variableList: VariableValueDefinition[]) {
    this.selectedWorkflowTitle = workflowTitle;
    this.selectedDocumentKindId = documentKindId;
    this.selectedFilesToSendToModal = files;
    this.selectedVariableList = variableList;
    this.uploadFileModalRef = this.bsModalService.show(uploadMultipleDocumentTemplate, { class: "modal-lg", ignoreBackdropClick: true, })
  }

  addDocumentClicked(documentKindId: number, uploadMultipleDocumentTemplate, workflowTitle: string, variableList: VariableValueDefinition[]) {
    this.selectedWorkflowTitle = workflowTitle;
    this.selectedFilesToSendToModal = undefined;
    this.selectedDocumentKindId = documentKindId;
    this.selectedVariableList = variableList;
    this.onUpdateDocuments(uploadMultipleDocumentTemplate);
  }

  requestDocumentClicked(documentKindId: number, requestDocumentTemplate, workflowTitle: string) {
    this.selectedWorkflowTitle = workflowTitle;
    this.selectedDocumentKindId = documentKindId;
    this.onUpdateRequestDocuments(requestDocumentTemplate);
  }

  onDownloadFile(template: any, siteSupplierDocumentType: SupplierDocumentVersionViewModel) {
    this.selectedSupplierDocumentVersionViewModel = siteSupplierDocumentType;
    this.uploadFileModalRef = this.bsModalService.show(template, { class: "modal-lg" })
  }
}
