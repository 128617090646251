<div class="row">
  <div class="col-md-12">
    <div class="card">
      <obc-loader [show]="inProgress"></obc-loader>
      <header class="card-header">Induction Report</header>
      <div class="card-body">
        <form [formGroup]="searchQueryForm" class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>
                <b> Include : </b>
              </label>
              <div class="pl-0 form-check">
                <label class="ml-3">
                  <input formControlName="includeExpiredInductions" type="checkbox" />
                  Include Expired Inductions?
                </label>
              </div>
            </div>
          </div>
        </form>
        <app-base-grid [columns]="gridColumns" [configs]="gridConfig" #grid>

          <ng-template obcGridTemplateName="inductionTitle" let-item="data">
            <span *ngIf="isShowTemplateColumns('inductionTitle','inductionTitle')">
              {{item.inductionTitle}}
            </span>

            <i *ngIf="isShowTemplateColumns('inductionTitle','isExpired') && item.isExpired" class="m-1" style="font-size: small;color: red;"> Expired </i>
          </ng-template>

          <ng-template obcGridTemplateName="currentStatus" let-item="data">
            <span *ngIf="isShowTemplateColumns('status','status')">
              {{item.status | siteInductionStatus}}
              <button class="btn btn-sm btn-danger ml-2 m-1"
                *ngIf="hasResetPermission(item.siteId) && item.status != SiteInductionStatus.PendingToApprove"
                (click)="resetInductionStatus(item)">
                Reset Induction To Pending
              </button>
            </span>
          </ng-template>
          <ng-template obcGridTemplateName="viewDetails" let-viewDetails let-item="data">
            <a target="_blank" href="{{'/api/Induction/export-pdf/'+item.siteInductionId}}"
              class="btn btn-sm btn-success ml-2 m-1">PDF</a>
            <button class="btn btn-sm btn-info ml-2 m-1" (click)="openModal(inductionInfo,item.siteInductionId)">
              View Induction
            </button>
          </ng-template>
          <ng-template #inductionInfo>
            <obc-site-induction-detail [siteInductionId]="selectedSiteInductionId" (result)="closeModal($event)">
            </obc-site-induction-detail>
          </ng-template>
        </app-base-grid>
      </div>
    </div>
  </div>
</div>

<ng-template #questionAnswer>
  <obc-question-answer [questionAnswerInput]="questionAnswerInputModel" (result)="closeModal($event)">
  </obc-question-answer>
</ng-template>
