<div class="row">
  <div class="col-12">
    <div class="card">
      <obc-loader [show]="inProgress"></obc-loader>
      <div>
        <header class="card-header">Search Activities</header>
        <obc-descriptor *ngIf="reportType == siteActivityReportType.SiteActivity" [closePanelName]="'siteActivity'"
          [text]="'This allows you to view who was on site between a date and time frame. You can view all users or filter a particular user.'">
        </obc-descriptor>
        <obc-descriptor *ngIf="reportType == siteActivityReportType.ContactTracing" [closePanelName]="'contactTracing'"
          [text]="'This feature allows you to view who was in contact with a specific user'">
        </obc-descriptor>


        <div class="card-body">
          <form [formGroup]="searchQueryForm">
            <div class="row">
              <div class="col-md-6 col-12" *ngIf="reportType == siteActivityReportType.ContactTracing">
                <label>Select User</label>
                <obc-user-autocomplete [filterBy]="filterUserBy.All" (selectedUser)="onSelectedUser($event)"
                  [required]="true"></obc-user-autocomplete>
              </div>
            </div>
          </form>
          <app-base-grid [columns]="gridColumns" [configs]="gridConfig" #grid>
            <ng-template obcGridTemplateName="checkInDateTime" let-checkInDateTime let-item="data">
              <div>
                <span *ngIf="isShowTemplateColumns('checkInDate','checkInDate')">
                  {{ item.checkInDate | datetime }}
                </span>
                <div class="d-flex flex-row">
                  <i class="fas fa-history text-clock"
                    *ngIf="isShowTemplateColumns('checkInDate','isOfflineCheckIn') && item.isOfflineCheckIn"
                    title="Data received at {{item.offlineCheckInRegisterDate | datetime}}"></i>

                  <i class="fas fa-exclamation-triangle text-danger" tooltip="Disputed"
                    *ngIf="isShowTemplateColumns('checkInDate','isDisputedCheckInDate') && item.isDisputedCheckInDate"></i>

                  <i *ngIf="isShowTemplateColumns('checkInDate','siteAttendanceApprovalStatus') &&
                            item.siteAttendanceApprovalStatus != SiteAttendanceApprovalStatus.NotRequiredApproval"
                    class="fas"
                    [ngClass]="{'fa-history text-warning': item.siteAttendanceApprovalStatus == SiteAttendanceApprovalStatus.Pending,
                    'fa-times-circle text-danger': item.siteAttendanceApprovalStatus == SiteAttendanceApprovalStatus.Rejected,
                    'fa-check-circle text-success': item.siteAttendanceApprovalStatus == SiteAttendanceApprovalStatus.Approved}"
                    title="{{ getApprovalStatusFriendlyLabel(item)}}"></i>
                </div>
              </div>
              <div *ngIf="isShowTemplateColumns('checkInDate','checkInForm') && item.checkInForm">
                <small>({{item.checkInForm}})</small>
              </div>
              <div *ngIf="isShowTemplateColumns('checkInDate','checkInDistanceTitle') && item.checkInDistanceTitle">
                <strong>{{item.checkInDistanceTitle}}</strong>
              </div>
            </ng-template>
            <ng-template obcGridTemplateName="checkOutDateTime" let-checkOutDateTime let-item="data">
              <div *ngIf="item.checkOutDate">
                <div>
                  <span *ngIf="isShowTemplateColumns('checkOutDate','checkOutDate')">
                    {{ item.checkOutDate | datetime }}
                  </span>
                  <div class="d-flex flex-row">
                    <i class="fas fa-history text-clock"
                      *ngIf="isShowTemplateColumns('checkOutDate','isOfflineCheckOut') && item.isOfflineCheckOut"
                      title="Data received at {{item.offlineCheckOutRegisterDate | datetime}}"></i>

                    <i class="fas fa-exclamation-triangle text-danger" tooltip="Disputed"
                      *ngIf="isShowTemplateColumns('checkOutDate','isDisputedCheckOutDate') && item.isDisputedCheckOutDate"></i>
                  </div>
                </div>
                <div *ngIf="isShowTemplateColumns('checkOutDate','checkOutForm') && item.checkOutForm">
                  <small>({{item.checkOutForm}})</small>
                </div>
                <div
                  *ngIf="isShowTemplateColumns('checkOutDate','checkOutDistanceTitle') && item.checkOutDistanceTitle">
                  <strong>{{item.checkOutDistanceTitle}}</strong>
                </div>
              </div>
            </ng-template>

            <ng-template obcGridTemplateName="viewDetails" let-viewDetails let-data="data">
              <button *ngIf="reportType == siteActivityReportType.SiteActivity" class="btn btn-info" (click)="
              openModal(
              questionAnswer,
              data.siteAttendanceId,
              true,
              data.checkOutDate != null)">
                View Details
              </button>
              <button *ngIf="reportType == siteActivityReportType.ContactTracing" class="btn btn-info"
                (click)="openContactTracingModal(contactTracing, data)">
                View Contacts
                <span>({{ data.contactCount }})</span>
              </button>

              <div *ngIf="data.scannedItems">
                <ng-container *ngFor="let scanItem of data.scannedItems">
                  <i class="fas fa-qrcode text-info ml-1" aria-hidden="true"
                    tooltip="{{ scanItem.name }} ({{scanItem.scanDateTime | datetime}})"></i>
                </ng-container>
              </div>
            </ng-template>
            <ng-template #questionAnswer>
              <obc-question-answer *ngIf="reportType == siteActivityReportType.SiteActivity"
                [questionAnswerInput]="questionAnswerInputModel" (result)="closeModal($event)">
              </obc-question-answer>
            </ng-template>
            <ng-template #contactTracing>
              <obc-user-activity *ngIf="selectedSiteActivityReport" [siteAttendance]="selectedSiteActivityReport"
                (result)="closeModal($event)">
              </obc-user-activity>
            </ng-template>

            <ng-template obcGridTemplateName="announcementsTemplate" let-data="data">
              <div *ngIf="isShowTemplateColumns('announcementVisits','announcementVisits') && data.announcementVisits">
                <ng-container *ngFor="let visit of data.announcementVisits">
                  <div class="card-header mb-2 d-flex flex-column">
                    <div class="d-flex align-items-center">
                      <i class="{{getAnnouncementIconCSSClass(visit)}} mr-1" aria-hidden="true"
                        tooltip="{{ visit.title }} ({{visit.formTitle | formTitle}} {{ visit.visitDate | datetime }})"></i>
                      <strong>
                        {{ visit.title }}
                      </strong>
                    </div>
                    <span>({{visit.formTitle | formTitle}} {{ visit.visitDate | datetime }})</span>
                  </div>
                </ng-container>
              </div>
            </ng-template>

            <ng-template obcGridTemplateName="permits" let-item="data">
              <div *ngFor="let permitForm of item.permitForms">
                <div class="p-3">
                  <div class="row px-1">
                    <div class="col-md-12">
                      Name:
                      <strong class="text-dark">{{permitForm.name}}</strong>
                    </div>
                  </div>
                  <div class="row px-1">
                    <div class="col-md-12">
                      <i class="fas fa-calendar text-success pr-1"></i>Start Date:
                      <span class="text-primary">{{permitForm.startDate | date}}</span>
                    </div>
                  </div>
                  <div class="row px-1">
                    <div class="col-md-12">
                      <i class="fas fa-calendar text-success pr-1"></i>End Date:
                      <span class="text-primary">{{permitForm.endDate | date}}</span>
                    </div>
                  </div>
                  <div class="row px-1">
                    <div class="col-md-12">
                      <i class="fas fa-save text-warning pr-1"></i>For:
                      <span class="text-primary">{{permitForm.registerFor | formRegisterFor}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>

            <ng-template obcGridTemplateName="business" let-item="data">
              <div *ngIf="isShowTemplateColumns('businessName','businessName')">
                {{ item.businessName }}
              </div>

              <div *ngIf="isShowTemplateColumns('businessName','businessAbn')">
                <small>{{ item.businessAbn ? "(Abn: " + item.businessAbn + ")" : "" }}</small>
              </div>

              <div *ngIf="isShowTemplateColumns('businessName','businessAcn')">
                <small>{{ item.businessAcn ? "(Acn: " + item.businessAcn + ")" : "" }}</small>
              </div>
            </ng-template>

            <ng-template obcGridTemplateName="QA" let-item="data">
              <div *ngIf="isShowTemplateColumns('includeQuestionAnswers','siteQuestionAnswers')" class="smaller-font">
                <div>
                  {{ item.optInQuestionMessage ? item.optInQuestionMessage : "Opt-in to communication" }}:
                  <i tooltip="No" *ngIf="item.allowComminiucateWithUser === false" class="fas fa-times text-danger"></i>
                  <i tooltip="Yes" *ngIf="item.allowComminiucateWithUser === true"
                    class="fas fa-check-circle text-success"></i>
                  <i tooltip="Not Collected" *ngIf="item.allowComminiucateWithUser == null"
                    class="fas fa-minus text-default"></i>
                </div>
                <ng-container *ngFor="let isCheckin of [true, false]">
                  <div *ngIf="getQAs(item, isCheckin)?.length">
                    <strong style="text-decoration: underline">{{ isCheckin ? "CheckIn" : "CheckOut" }}</strong>

                    <ul class="simple-post-list">
                      <ng-container 
                        *ngFor="let item of getQAs(item, isCheckin)">
                        <li class="p-0 d-flex flex-row justify-content-between"
                          *ngIf="item.questionType != _questionType.EmergencyContact || emergencyContactAnswerIsNotEmpty(item.answer)">
                          <div class="">
                            <i class="fas fa-question-circle mr-1" aria-hidden="true"></i>
                            {{ item.questionText }}
                            <div class="post-meta ml-4 text-primary" *ngIf="item.isHiddenAsSensitive">
                              <span class="text-danger">
                                {{ item.answer }}
                              </span>
                            </div>
                            <div class="post-meta ml-4 text-primary" [ngSwitch]="item.questionType"
                              *ngIf="!item.isHiddenAsSensitive">
                              <span class="text-danger" *ngSwitchCase="_questionType.File">
                                <obc-file-viewer [urls]="item.fileUrls" [btnTitle]="'View'"
                                  [btnClass]="'btn btn-sm btn-info'" [btnStyle]="'font-size: 11px'"> </obc-file-viewer>
                              </span>
                              <span class="text-danger" *ngSwitchCase="_questionType.SignaturePad">
                                <obc-file-viewer [urls]="item.fileUrls" [btnTitle]="'View'"
                                  [btnClass]="'btn btn-sm btn-info'" [btnStyle]="'font-size: 11px'"> </obc-file-viewer>
                              </span>
                              <span class="text-danger" *ngSwitchCase="_questionType.Date">
                                {{ isQuestionTimeIncluded(item.questionParameters) == true ? (item.answer | datetime) :
                                (item.answer | date)}}
                              </span>
                              <span class="text-info" *ngSwitchCase="_questionType.EmergencyContact">
                                <obc-emergency-contact-viewer
                                  [data]="item.answer"
                                ></obc-emergency-contact-viewer>
                              </span>
                              <span class="text-danger" *ngSwitchDefault>
                                {{ item.answer }}
                              </span>
                            </div>
                          </div>
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                </ng-container>
              </div>
            </ng-template>

          </app-base-grid>
        </div>

      </div>
    </div>
  </div>
</div>
