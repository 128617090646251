<div class="card">
  <div class="card-header">Change Mobile</div>
  <div class="card-body">
    <obc-loader [show]="mobileInProccess"></obc-loader>
    <form [formGroup]="mobileFormGroup">
      <div class="form-group row">
        <div class="col-md-4">
          <label>Current Mobile</label>
          <input type="text" class="form-control" formControlName="currentMobile">
        </div>
        <div class="col-md-4">
          <label>New Mobile</label>
          <input type="text" class="form-control" formControlName="newMobile">
        </div>
        <div class="col-md-4">
          <button type="submit" class="btn btn-primary" style="margin-top:30px ;" [disabled]="!mobileFormGroup.valid"
            (click)="validateEditMobile()">Change Mobile</button>
        </div>
      </div>
    </form>
  </div>
</div>
